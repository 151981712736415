import CategoryTreeNode from 'models/CategoryTreeNode';
import React, { ChangeEvent } from 'react';
import { CategoryRow, CategoryItem, CategoryCheck } from './CategoryList.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import {
  InputWrapper,
  PopupInput,
  PopupInputGroup,
} from './CustomProductMarkup.style';
import { CombinedCategory } from 'contexts/categories';
import { normalizeProductMarkup } from 'helper/deliveryServices';

type CategoryListProps = {
  node: CategoryTreeNode<CombinedCategory>;
  n?: number;
  showing?: boolean;
  expandedCategories: number[];
  onToggleCategoryExpand: (c: CategoryTreeNode) => void;
  disableExpand?: boolean;
  allItemsLabel?: React.ReactNode;
  onCategoryMarkupPercentageChange: (
    e: ChangeEvent<HTMLInputElement>,
    categoryIds: number[]
  ) => void;
};

export const Category: React.FC<CategoryListProps> = ({
  node,
  n,
  showing,
  expandedCategories,
  onToggleCategoryExpand,
  onCategoryMarkupPercentageChange,
  disableExpand = false,
  allItemsLabel,
}) => {
  const isExpanded = expandedCategories.includes(node.data.id);
  const hasChildren = !disableExpand && node.children.length > 0;

  const allChildrenCategoryIds = node
    .getAllDescendants()
    .map((descendant) => descendant.data.id);

  const getInputValue = (markup: number) => {
    return normalizeProductMarkup(markup);
  };

  if (node.data.id === 1 && !allItemsLabel) return <></>;

  return (
    <CategoryRow key={node.data.id} $nodeNumber={n}>
      {/* @ts-ignore */}

      <CategoryItem
        className={`item ${showing ? 'showing' : ''} ${
          hasChildren ? 'hasChildren' : ''
        } noselect`}
      >
        {node.data.id === 1 ? allItemsLabel : node.data.name}
        <PopupInputGroup>
          <InputWrapper>
            <PopupInput
              value={getInputValue(node.data.markup)}
              onChange={(e) => {
                onCategoryMarkupPercentageChange(e, allChildrenCategoryIds);
              }}
              type="number"
            />
            <span>%</span>
          </InputWrapper>
          {hasChildren && (
            <FontAwesomeIcon
              icon={isExpanded ? faChevronDown : faChevronRight}
              width={16}
              height={16}
              onClick={(e) => onToggleCategoryExpand(node)}
            />
          )}
        </PopupInputGroup>
      </CategoryItem>
    </CategoryRow>
  );
};

export const CategoryList: React.FC<CategoryListProps> = ({
  node,
  n = 0,
  showing = true,
  expandedCategories,
  onToggleCategoryExpand,
  onCategoryMarkupPercentageChange,
  allItemsLabel = 'All items',
}) => {
  return (
    <>
      {n === 0 && node.data.id !== 1 && (
        <Category
          disableExpand={true}
          node={node}
          n={n}
          showing={showing}
          expandedCategories={expandedCategories}
          onToggleCategoryExpand={onToggleCategoryExpand}
          allItemsLabel={allItemsLabel}
          onCategoryMarkupPercentageChange={onCategoryMarkupPercentageChange}
        />
      )}
      {node.children.map((child) => (
        <>
          <Category
            key={child.data.id}
            node={child}
            n={n}
            showing={showing}
            expandedCategories={expandedCategories}
            onToggleCategoryExpand={onToggleCategoryExpand}
            allItemsLabel={allItemsLabel}
            onCategoryMarkupPercentageChange={onCategoryMarkupPercentageChange}
          />
          <CategoryList
            key={child.data.id * 100}
            node={child}
            n={n + 1}
            showing={expandedCategories.includes(child.data.id)}
            expandedCategories={expandedCategories}
            onToggleCategoryExpand={onToggleCategoryExpand}
            allItemsLabel={allItemsLabel}
            onCategoryMarkupPercentageChange={onCategoryMarkupPercentageChange}
          />
        </>
      ))}
    </>
  );
};

export default CategoryList;
