import Log from 'helper/monitoring'
import Package from 'package.json'

export const fetchUserProperties = (callback: (data: any) => void) => {
  fetch('https://ipv4.jsonip.com')
    .then(res => res.json())
    .then(data => {
      callback({
        '$ip': data?.ip || '',
        'platform': 'Web',
        'version': Package.version,
        'last_session': new Date().toISOString(),
      });
    })
    .catch(error => {
      Log.error("Failed to fetch IP address", "analytics", error);
    });
}