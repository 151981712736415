import Log from 'helper/monitoring';
import { WholesaleProduct } from 'models/Wholesale';
import { WholesaleCartProps } from './useWholesaleCart';
import {
  AddItemWholesaleCartReducerAction,
  ClearItemFromCartWholesaleCartReducerAction,
  RemoveItemWholesaleCartReducerAction,
  WholesaleCartReducerAction,
} from './wholesaleCart.reducer.entities';

export const cartItemsTotalPrice = (
  items: WholesaleProduct[],
  coupon = null
) => {
  return items.reduce((price, product) => {
    const promotions = Object.values(product.multibuyPromotions);
    if (promotions.length === 0) {
      return price + product.price * product.quantity;
    }
    const appliablePromotions = promotions.filter(
      (promotion: any) => promotion.requiredPackages <= product.quantity
    );
    const potentialPrices = appliablePromotions.map(
      (promotion: any) => promotion.price * product.quantity
    );
    return price + Math.min(...potentialPrices);
  }, 0);
};

// cartItems, cartItemToAdd
const addItemToCart = (
  state: WholesaleCartProps,
  action: AddItemWholesaleCartReducerAction
) => {
  const existingCartItemIndex = state.items.findIndex(
    (item) => item.uuid === action.payload.uuid
  );

  if (existingCartItemIndex > -1) {
    const newState = [...state.items];
    newState[existingCartItemIndex].quantity += action.payload.quantity;
    return newState;
  }

  return [...state.items, { ...action.payload, allowSubstitutes: true }];
};

// cartItems, cartItemToRemove
const removeItemFromCart = (
  state: WholesaleCartProps,
  action: RemoveItemWholesaleCartReducerAction
) => {
  return state.items.reduce((acc, item) => {
    if (item.uuid === action.payload.uuid) {
      const newQuantity = item.quantity - action.payload.quantity;

      return newQuantity > 0
        ? [...acc, { ...item, quantity: newQuantity }]
        : [...acc];
    }
    return [...acc, item];
  }, [] as WholesaleProduct[]);
};

const clearItemFromCart = (
  state: WholesaleCartProps,
  action: ClearItemFromCartWholesaleCartReducerAction
) => {
  return state.items.filter((item) => item.uuid !== action.payload.uuid);
};

const setSubstitute = (
  state: WholesaleCartProps,
  action: any
): WholesaleProduct[] => {
  Log.debug('wholesaleCart.reducer setSubstitute', 'wholesale', {
    state,
    action,
  });
  return state.items.map((item) => {
    if (item.uuid === action.payload.uuid) {
      return { ...item, allowSubstitutes: action.payload.preference };
    }
    return item;
  });
};

export const reducer = (
  state: WholesaleCartProps,
  action: WholesaleCartReducerAction
) => {
  switch (action.type) {
    case 'REHYDRATE':
      return { ...state, ...action.payload };
    case 'SET_PROVIDER':
      return { ...state, provider: action.payload };
    case 'ADD_ITEM':
      return { ...state, items: addItemToCart(state, action) };
    case 'REMOVE_ITEM':
      return { ...state, items: removeItemFromCart(state, action) };
    case 'CLEAR_ITEM_FROM_CART':
      return { ...state, items: clearItemFromCart(state, action) };
    case 'CLEAR_CART':
      return {
        ...state,
        provider: '',
        items: [],
      };
    case 'SET_SUBSTITUTE_PREFERENCE':
      return { ...state, items: setSubstitute(state, action) };
    default:
      const _exhaustive: never = action;
      throw new Error(`Unknown action: ${(action as any).type}`);
  }
};
