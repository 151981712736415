import { ApolloError, useQuery } from '@apollo/react-hooks';
import { GET_RECOMMENDED_PRICE_FOR_PRODUCT } from 'devoapi/products';
import Log from 'helper/monitoring';
import { roundTo } from 'helper/product';
import { calculateMedian } from 'helper/products/price';
import React, { useEffect, useState } from 'react';

type RecommendedPriceProps = {
  skip?: boolean;
  shopId: string;
  productUpc: number;
  children: ({recommended, recommendedLoading, recommendedError} : { recommended: number, recommendedLoading: boolean, recommendedError: ApolloError }) => any;
}

const RecommendedPriceProvider: React.FC<RecommendedPriceProps> = ({
  skip = false,
  shopId,
  productUpc,
  children,
}) => {

  const { data: recommendedData, loading: recommendedLoading, error: recommendedError } = useQuery(GET_RECOMMENDED_PRICE_FOR_PRODUCT, {
    skip: skip || !shopId || !productUpc,
    variables: {
      shopId: shopId,
      productUpc: productUpc,
    }
  });

  const [recommended, setRecommended] = useState(null);

  useEffect(() => {
    if (!recommendedData?.products) return;
    const availability = ['AVAILABLE', 'NOT_AVAILABLE'];
    const filtered = recommendedData.products?.filter(x => availability.includes(x.availabilityText));
    const results = filtered.map(x => x.shopPrice).sort();
    const median = roundTo(calculateMedian(results), 2);
    if (median > 0) setRecommended(median);
    Log.debug(`Fetched other shop's prices for product`, 'recommended', { upc: productUpc, availability, all: recommendedData.products?.length, filtered: filtered.length, results: results?.join(", "), median });
  }, [recommendedData]);

  return children({ recommended, recommendedLoading, recommendedError });
};

export default RecommendedPriceProvider;
