import { extendExternalProducts } from './DeliveryServicesDashboard.data';
import {
  DeliveryServiceCard,
  NoInternetPlaque,
  ServiceLogoWrapper,
  ServicePrice,
  ServiceProductMarkup,
  ServiceProfitWrapper,
  Wrapper,
} from './DeliveryServicesDashboard.style';
import {
  GetPlatformProductListingPayload,
  GetPlatformProductListingResponse,
} from 'models/DeliveryServices';
import { useQuery } from '@apollo/client';
import { GET_PLATFORM_PRODUCT_LISTING } from 'devoapi/delivery_services';
import useShopContext from 'contexts/shop/shop.context';
import Loader from 'components/Loader/Loader';
import { platformTypeThumbnailsMap } from 'containers/ManageDeliveryServices/ManageDeliveryServices.data';
import { ProductMargin, ShopProduct } from 'models/Product';
import { normalizeProductMarkup } from 'helper/deliveryServices';
import { PlatformType } from 'models/Order';
import { formatProfit, formatProfitMargin } from 'helper/product';

export interface DeliveryServicesDashboardProps {
  devoProduct: ShopProduct;
  platformMargins?: Record<PlatformType, ProductMargin>;
}

const DeliveryServicesDashboard: React.FC<DeliveryServicesDashboardProps> = ({
  devoProduct,
  platformMargins,
}) => {
  const { shopState } = useShopContext();

  const {
    data: externalData,
    loading: externalLoading,
    error: externalError,
  } = useQuery<
    GetPlatformProductListingResponse,
    GetPlatformProductListingPayload
  >(GET_PLATFORM_PRODUCT_LISTING, {
    skip: !shopState?.uuid,
    variables: {
      productUpc: devoProduct.product.upc,
      shopUuid: shopState?.uuid,
    },
  });

  return (
    <Wrapper>
      {externalLoading ? (
        <Loader style={{ margin: '0 auto' }} />
      ) : externalData ? (
        extendExternalProducts(externalData.response, devoProduct)
          .sort((s1, s2) => -Number(s1.integrated) + Number(s2.integrated))
          .map((service) => (
            <DeliveryServiceCard key={service.platform}>
              {service.synced === false && (
                <NoInternetPlaque>No Internet</NoInternetPlaque>
              )}
              {service.integrated && (
                <ServiceLogoWrapper>
                  <img
                    src={platformTypeThumbnailsMap[service.platform]}
                    width={64}
                    height={64}
                    alt={`${service.platform} logo`}
                  />
                </ServiceLogoWrapper>
              )}
              {service.integrated ? (
                <>
                  <ServiceProductMarkup>
                    {normalizeProductMarkup(service.markup)}
                    <span>% markup</span>
                  </ServiceProductMarkup>
                  <ServicePrice style={{ margin: 0 }}>
                    <span>£</span>
                    {service.price.toFixed(2)}
                    {platformMargins && platformMargins[service.platform] && (
                      <ServiceProfitWrapper
                        className={
                          platformMargins[service.platform].profit >= 0
                            ? 'positive'
                            : 'negative'
                        }
                      >
                        {formatProfit(platformMargins[service.platform].profit)}
                        {': '}
                        {formatProfitMargin(
                          platformMargins[service.platform].profitMargin
                        )}
                      </ServiceProfitWrapper>
                    )}
                  </ServicePrice>
                </>
              ) : (
                <></>
                // <Button>
                //   <span style={{ marginRight: '8px' }}>Sign Up</span>
                //   <FontAwesomeIcon icon={faArrowRight} />
                // </Button>
              )}
            </DeliveryServiceCard>
          ))
      ) : (
        <p
          style={{
            margin: '0 auto',
            width: 'fit-content',
            fontSize: '32px',
            fontWeight: 600,
          }}
        >
          No data fetched
        </p>
      )}
    </Wrapper>
  );
};

export default DeliveryServicesDashboard;
