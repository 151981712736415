import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ChevronSmall: React.FC = () => (
  <FontAwesomeIcon icon={faChevronRight} size="sm" style={{
    marginLeft: 8
  }} />
)

export default ChevronSmall