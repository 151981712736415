import gql from 'graphql-tag';
import {
  PRODUCTS_GQL_PROPERTIES,
  PRODUCT_GQL_PROPERTIES,
  PRODUCT_UPDATE_RESPONSE_GQL_PROPERTIES,
} from 'models/Product';

export const GET_PRODUCTS = gql`
  query getPaginatedShopProducts($shopId: String, $offset: Int = 0, $limit: Int = 25, $filterQuery: string = "") {
    products(shopId: $shopId, offset: $offset, limit: $limit, filterQuery: $filterQuery)
    @rest(type: "Product[]", 
          method: "GET", 
          path: "/shopProduct?shopUuid={args.shopId}&offset={args.offset}&limit={args.limit}{args.filterQuery}",
          endpoint: "ss") {
      ${PRODUCTS_GQL_PROPERTIES}
    }
  }
`;

export const GET_MISSING_PRODUCTS = gql`
  query getPaginatedShopProductsMissingFromShop($shopId: String, $offset: Int = 0, $limit: Int = 25, $filterQuery: string = "") {
    products(shopId: $shopId, offset: $offset, limit: $limit, filterQuery: $filterQuery)
    @rest(type: "Product[]", 
          method: "GET", 
          path: "/shopProduct/missing?shopUuid={args.shopId}&offset={args.offset}&limit={args.limit}{args.filterQuery}",
          endpoint: "ss") {
      ${PRODUCTS_GQL_PROPERTIES}
    }
  }
`;

export const GET_RECOMMENDED_PRICE_FOR_PRODUCT = gql`
  query getRecommendedPriceForProduct($shopId: String, $productUpc: string) {
    products(shopId: $shopId, productUpc: $productUpc)
      @rest(
        type: "Product[]"
        method: "GET"
        path: "/shopProduct/{args.productUpc}"
        endpoint: "ws"
      ) {
      shopUuid
      upc
      shopPrice
      popularityScore
      availabilityText
      limit
    }
  }
`;

export const PATCH_PRODUCT = gql`
  mutation updateShopProduct($shopId: String, $productUpc: Int, $input: any) {
    product(shopId: $shopId, productUpc: $productUpc, input: $input)
    @rest(type: "ProductUpdateResponse",
          method: "PATCH", 
          path: "/shop/{args.shopId}/product/{args.productUpc}",
          endpoint: "ss") {
      ${PRODUCT_UPDATE_RESPONSE_GQL_PROPERTIES}
    }
  }
`;

export const ADD_PRODUCT_TO_INVENTORY = gql`
  mutation saveShopProduct($input: any) {
    product(input: $input)
    @rest(type: "ProductUpdateResponse",
      method: "POST",
      path: "/shop/product",
      endpoint: "ss") {
        ${PRODUCT_UPDATE_RESPONSE_GQL_PROPERTIES}
      }
  }
`;

export const GET_PRODUCT = gql`
  query getShopProduct($shopId: String, $productUpc: Int) {
    product(shopId: $shopId, productUpc: $productUpc)
    @rest(type: "ShopProduct",
          method: "GET",
          path: "/shop/{args.shopId}/product/{args.productUpc}",
          endpoint: "ss") {
      ${PRODUCT_GQL_PROPERTIES}
    }
  }
`;

export const GET_BULK_PRODUCTS = gql`
  query getShopProductsBulk($shopId: String, $products: String) {
    products(shopId: $shopId, products: $products)
    @rest(type: "ShopProduct",
          method: "GET",
          path: "/shop/{args.shopId}/products?products={args.products}",
          endpoint: "ss") {
      ${PRODUCT_GQL_PROPERTIES}
    }
  }
`;

export const GET_PRODUCTS_BY_CATEGORY = gql`
  query getProductsByCategory($shopId: String, $categoryId: Int, $limit: Int = 50, $includeSubcats: boolean = false) {
    products(shopId: $shopId, categoryId: $categoryId, limit: $limit, includeSubcats: $includeSubcats)
    @rest(type: "ShopProduct[]",
          method: "GET",
          path: "/shopProduct?shopUuid={args.shopId}&categoryId={args.categoryId}&limit={args.limit}&includeSubcats={args.includeSubcats}",
          endpoint: "ss") {
        ${PRODUCTS_GQL_PROPERTIES}
    }
  }
`;

export const GET_PRODUCTS_BY_ELASTIC_SEARCH_AND_CATEGORY = gql`
  query getProductsByElasticSearch($shopId: String, $keyword: String, $categoryId: Int) {
    products(shopId: $shopId, keyword: $keyword, categoryId: $categoryId)
    @rest(type: "ShopProduct[]",
          method: "GET",
          path: "/shopProduct/elastic?shopUuid={args.shopId}&availabilityList=AVAILABLE&categoryId={args.categoryId}&keyword={args.keyword}&limit=50",
          endpoint: "ss") {
        ${PRODUCTS_GQL_PROPERTIES}
    }
  }
`;

export const GET_PRODUCTS_BY_ELASTIC_SEARCH = gql`
  query getProductsByElasticSearch($shopId: String, $keyword: String) {
    products(shopId: $shopId, keyword: $keyword, categoryId: $categoryId)
    @rest(type: "ShopProduct[]",
          method: "GET",
          path: "/shopProduct/elastic?shopUuid={args.shopId}&availabilityList=AVAILABLE&keyword={args.keyword}&limit=50",
          endpoint: "ss") {
        ${PRODUCTS_GQL_PROPERTIES}
    }
  }
`;

export const GET_CUSTOMER_RECENTLY_ORDERED = gql`
  query getCustomerRecentlyOrdered($shopId: String, $userId: String) {
    products(shopId: $shopId, userId: $userId)
    @rest(type: "ShopProduct[]",
          method: "GET",
          path: "/shopProduct/recent?shopUuid={args.shopId}&userUuid={args.userId}",
          endpoint: "ss") {
      ${PRODUCT_GQL_PROPERTIES}
    }
  }
`;

export const GET_SHOP_PRODUCT_MARGIN = gql`
  query getShopProductMargin(
    $uuid: string
    $costPerProduct: number
    $costPerPack: number
    $itemsPerPack: number
    $shopPrice: number
  ) {
    response(
      uuid: $uuid
      costPerProduct: $costPerProduct
      costPerPack: $costPerPack
      itemsPerPack: $itemsPerPack
      shopPrice: $shopPrice
    ) @rest(method: "GET", path: "/shopProduct/margin?{args}", endpoint: "ss") {
      uuid
      shopPrice
      productCost
      margin
      platformMargins
    }
  }
`;

export const SAVE_SHOP_PRODUCT_MARGIN = gql`
  mutation saveShopProductMargin($input: any) {
    response(input: $input)
      @rest(method: "PUT", path: "/shopProduct/margin", endpoint: "ss") {
      uuid
      shopPrice
      productCost
      margin
      platformMargins
    }
  }
`;

export const GET_PRODUCTS_PERIODIC_AVAILABILITY = gql`
  mutation getProductsPeriodicAvailability($shopUuid: string, $input: any) {
    response(shopUuid: $shopUuid, input: $input)
      @rest(
        method: "POST"
        path: "/shopProduct/periodicAvailability?shopUuid={args.shopUuid}"
        endpoint: "ss"
      ) {
      results
      total
    }
  }
`;

export const PUT_PRODUCTS_PERIODIC_AVAILABILITY = gql`
  mutation putProductsPeriodicAvailability($input: any) {
    response(input: $input)
    @rest(
      method: "PUT"
      path: "/shopProduct/periodicAvailability"
      endpoint: "ss"
    ) {
      results
    }
  }
`;
