import {
  formatDateToDDMMMYYYYHHMM,
  formatDayDateMonthStr,
  formatDayOfWeekString,
  formatLongDate,
  formatShortDate,
  formatShortDateTimeString,
  formatTimeString,
  formatTinyDate,
} from './dates';

export type LocalDateFormatterDecorator = (
  formatter: DateFormatter
) => ToLocalDateStringFormatter;

type UTCDateString = string;
type LocalDateString = string;

export type DateFormatter = (date: Date) => UTCDateString;
export type ToLocalDateStringFormatter = (utcDate: Date) => LocalDateString;

export const toLocalDate = (utcDate: Date): Date =>
  new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);

export const toLocalDateFormatter: LocalDateFormatterDecorator =
  (formatter) => (utcDate) =>
    formatter(toLocalDate(utcDate));

export const formatShortDateLocalTimeString = toLocalDateFormatter(
  formatShortDateTimeString
);

export const formatDateToLocalDDMMMYYYYHHMM = toLocalDateFormatter(
  formatDateToDDMMMYYYYHHMM
);

export const formatDayOfWeekLocalString = toLocalDateFormatter(
  formatDayOfWeekString
);

export const formatLocalTinyDate = toLocalDateFormatter(formatTinyDate);

export const formatLocalShortDate = toLocalDateFormatter(formatShortDate);

export const formatLocalLongDate = toLocalDateFormatter(formatLongDate);

export const formatLocalTimeString = toLocalDateFormatter(formatTimeString);

export const formatLocalDayDateMonthStr = toLocalDateFormatter(
  formatDayDateMonthStr
);
