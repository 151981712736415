import React, { useReducer } from 'react';
import { UndoContext } from './undo.context';
import { ChangeAction } from 'models/VersionHistory';
import Log from 'helper/monitoring';

type UndoProps = {
  inProgress: boolean;
  changes: ChangeAction[];
  pending: ChangeAction[];
}

const INITIAL_STATE: UndoProps = {
  inProgress: false,
  changes: [],
  pending: [],
};

type ActionType = {
  type: 'ADD_CHANGE' | 'UNDO' | 'REMOVE_PENDING' | 'UPDATED';
  payload?: ChangeAction;
};

function reducer(state: UndoProps, action: ActionType): any {
  switch (action.type) {
    case 'ADD_CHANGE':
      const change = action.payload as ChangeAction;
      const _state = {
        ...state,
        changes: [...state.changes, {
          ...change,
          time: new Date(new Date().getTime() - 2500).getTime(),
        }],
      };
      Log.debug('Added change...', 'undo', _state);
      return _state;

    case 'UNDO':
      Log.debug('Triggered undo...', 'undo');
      var newState = JSON.parse(JSON.stringify(state));
      newState.inProgress = true;
      if (newState.changes.length > 0) {
        const change = newState.changes.pop();
        newState.pending = [change, ...newState.pending];
      }
      return newState;

    case 'REMOVE_PENDING':
      const selected = action.payload as ChangeAction;
      Log.debug('Removing pending...', 'undo', {selected,state});

      if (!selected) return state;
      return {
        ...state,
        pending: state.pending.filter(x => x.time !== selected.time)
      };

    case 'UPDATED':
      Log.debug('Undo inProgress = false', 'undo');
      return {
        ...state,
        inProgress: false,
      }

    default:
      return state;
  }
};

export const UndoProvider: React.FunctionComponent<any> = ({ children }) => {
  const [undoState, undoDispatch] = useReducer(reducer, INITIAL_STATE);
  return (
    <UndoContext.Provider value={{ undoState, undoDispatch }}>
      {children}
    </UndoContext.Provider>
  );
};
