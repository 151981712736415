import wholesale from 'pages/wholesale';
import styled from 'styled-components';

export type RowProps = {
  afterScan?: boolean;
  wholesale?: boolean;
};

export const Row = styled.div<RowProps>`
  display: flex;
  padding: ${(props) => (!props.afterScan ? `20px 10px` : '20px 24px')};
  border-bottom: var(--separator-color) solid 1px;
  background-color: var(--background-color);
  margin: ${(props) => (!props.afterScan ? `0 -10px` : '0')};
  .check {
    margin: auto 5px;
  }
`;

export const Image = styled.div<RowProps>`
  flex-grow: 0;
  flex-shrink: 0;
  ${wholesale ? 'margin-right: 24px;' : ''}
  margin-right: ${(props) => (props.wholesale ? '24px' : '0')};
  img {
    height: 50px;
    width: 50px;
    object-fit: contain;
    margin: 5px 10px;
  }
`;

export const Category = styled.div<RowProps>`
  font-size: 0.875em;
  margin-bottom: ${(props) => (props.wholesale ? '0' : '10px')};
  display: ${(props) => (props.wholesale ? 'flex' : 'block')};
  align-items: ${(props) => (props.wholesale ? 'center' : 'flex-start')};

  a:not([href]) {
    color: var(--devo-red);
  }

  p {
    color: var(--secondary-label-color);
  }
`;

export const Name = styled.div`
  flex-grow: 2;
  width: 50%;

  & > p {
    font-weight: 600;
    font-size: 1em;
    margin-bottom: ${wholesale ? '10px' : 0};
  }
`;

export const TagWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  ${wholesale ? 'margin-top: 10px' : ''}
`;

export const TagCard = styled.a`
  margin-right: 20px;
`;

export const TagContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const TagLabel = styled.p`
  margin: 0;
  font-size: 1em;
  font-weight: 600;

  span {
    font-size: 0.75em;
  }
`;

export const TagSubtitle = styled.span`
  margin: 0;
  font-size: 0.75em;
  color: #757575;

  &.sales button {
    font-size: 0.90em;
    color: #757575;
    font-weight: 400;
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Tag = {
  Wrapper: TagWrapper,
  Card: TagCard,
  Content: TagContent,
  Label: TagLabel,
  Subtitle: TagSubtitle,
  Spacer: styled.div`
    flex-grow: 2;
  `,
};
