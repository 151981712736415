
export const Colors = `
  --devo-red: rgb(255, 89, 88);
  --devo-dark-red: rgb(157, 46, 60);
  --devo-orange: rgb(255, 142, 48);
  --devo-dark-orange: rgb(218, 70, 4);
  --devo-yellow: rgb(255, 202, 95);
  --devo-dark-yellow: rgb(255, 142, 48);

  --devo-blue: rgb(0, 123, 255);
  --devo-dark-blue: #1f47cd;
  --devo-green: rgb(0, 194, 3);
  --devo-dark-green: #006f00;
  --devo-yellow-2: #ffc107;
  --devo-yellow-2-text: #212529;
  --devo-purple: #BD10E0;

  --devo-removed-gray: #666666;

  --separator-color: #DEE2E6;
  --border-color: #818181;

  --background-color: rgb(255, 255, 255);
  --secondary-background-color: rgb(240, 242, 247);
  --tertiary-background-color: rgb(255, 255, 255);
  --selected-background-color: rgba(238, 80, 79, 0.05);

  --primary-label-color: rgb(0, 0, 0);
  --secondary-label-color: rgba(60, 60, 67, 0.60);
  --tertiary-label-color: rgba(60, 60, 67, 0.30);
  --quaternary-label-color: rgba(60, 60, 67, 0.18);

  --white: #fff;
  --gray: rgb(142, 142, 147);
  --gray-2: rgb(174, 174, 178);
  --gray-3: rgb(199, 199, 204);
  --gray-4: rgb(209, 209, 214);
  --gray-5: rgb(229, 229, 234);
  --gray-6: rgb(242, 242, 247);

  --blur-filter: saturate(180%) blur(20px);
  --blur-color: rgba(255,255,255,0.72);
`;

export const DarkModeColors = `
  --separator-color: rgba(56, 56, 58);

  --background-color: rgb(28, 28, 30);
  --secondary-background-color: rgb(44, 44, 46);
  --tertiary-background-color: rgb(58, 58, 60);

  --primary-label-color: rgb(255, 255, 255);
  --secondary-label-color: rgba(235, 235, 245, 0.60);
`;

export const Spacings = `
  --devo-border-radius: 6px;
`;

export const Other = `
  --devo-border: 1px var(--border-color) solid;
  --devo-shadow: 0 2px 4px 0 rgb(0 0 0 / 0.1);
  --devo-heavy-shadow: 0 2px 4px 0 rgb(0 0 0 / 0.2);
`;