import useAuth from 'contexts/auth/auth.context'
import Log from 'helper/monitoring'
import { useState } from 'react'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

const QUERY = gql`
  query getUserSecurityGroups($userUuid: string) {
    groups(userUuid: $userUuid)
    @rest(type: "Groups", method: "GET", path: "/security/group/user/{args.userUuid}", endpoint: "as")
  }
`;

interface UseGetUserSecurityGroupsCallback {
  all: string[];
  loading: boolean;
  error: any;
}

const useGetUserSecurityGroups = (userId: string = null): UseGetUserSecurityGroupsCallback => {

  const { authState } = useAuth();
  let currentUserUuid = authState?.user?.uuid;

  const [all, setAll] = useState<string[]>([]);
  const [error, setError] = useState(null);

  let userUuid = userId || currentUserUuid || null;

  const { loading } = useQuery(QUERY, {
    skip: !userUuid,
    variables: { userUuid },
    fetchPolicy: "network-only",
    onCompleted: res => {
      let _groups = res?.groups as string[] || [];
      setError(null);
      setAll(_groups);
    },
    onError: err => {
      Log.debug("useGetUserSecurityGroups onError", "pos", err);
      setError(err);
      setAll([]);
    }
  });

  return {
    all,
    loading,
    error
  }
}

export default useGetUserSecurityGroups