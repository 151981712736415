import PosProduct from 'models/PosProduct'
import { Product } from 'models/Product'
import { useRouter } from 'next/router'
import usePermissions from 'endpoints/permissions/usePermissions'
import React from 'react';
import styled from 'styled-components';
import Dropdown from 'react-bootstrap/Dropdown';
import { faEllipsisH, faHistory, faPoll, faTag, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CATEGORY_REMOTE_REPORT_ROUTE, PRODUCT_REMOTE_REPORT_ROUTE } from '../../constants/navigation'
import { createBulkPromotionsReportUrl } from '../../helper/barcodes'

const Wrapper = styled.td`
  width: 28px;
  
  &.menu {
    text-align: center;
  }

  .options {
    a {
      margin: 0;
      display: block;
      background-color: var(--background-color);
      border-radius: 4px;
      height: 28px;
      width: 28px;
      border: 1px var(--border-color) solid;
      box-shadow: var(--devo-shadow);
    }

    a svg {
      align-self: center;
      color: var(--primary-label-color);
    }
  }

  div.dropdown-menu.show {
    border-radius: 20px;
    overflow: hidden;
  }

  a.dropdown-item {
    padding: 0.5rem 1rem;
    text-align: left;
  }

  a.dropdown-item.destructive {
    color: var(--devo-red);
  }
`;

// @ts-ignore
const Toggle = React.forwardRef(({ children, onClick }, ref) => (
  // @ts-ignore
  <div className="options"><a href="" className="options" ref={ref} onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    >
      {children}
    </a>
  </div>
));

type OptionsMenuProps = {
  product?: Product;
  posProduct?: PosProduct;
  upc?: number;
  categoryId?: number;
  categoryName?: string;
  promotions?: boolean;
  productReport?: boolean;
  categoryReport?: boolean;
  onVersionHistoryClick?: (e: any) => void;
  onDeleteClick?: (e: any) => void;
};

const OptionsMenu: React.FC<OptionsMenuProps> = ({
  product,
  posProduct,
  upc: _upc,
  categoryId: _categoryId,
  categoryName: _categoryName,
  promotions = true,
  productReport = true,
  categoryReport = true,
  onVersionHistoryClick,
  onDeleteClick
}) => {

  const router = useRouter();
  const { permissions } = usePermissions();

  let upc = _upc || product?.upc || posProduct?.upc;
  let categoryId = _categoryId || product?.category.id || posProduct?.categoryId;
  let categoryName = _categoryName || product?.category.name;

  const onPromotionsClick = () => router.push(createBulkPromotionsReportUrl([upc]));

  const onProductReportClick = () => {
    if (upc != null) router.push(PRODUCT_REMOTE_REPORT_ROUTE,
      PRODUCT_REMOTE_REPORT_ROUTE.replace("[upc]", upc.toString()));
  }

  const onCategoryReportClick = () => {
    if (categoryId != null) router.push(CATEGORY_REMOTE_REPORT_ROUTE,
      CATEGORY_REMOTE_REPORT_ROUTE.replace("[id]", categoryId.toString()));
  }

  return (
    <Wrapper className="menu">
      <Dropdown align="end">
        <Dropdown.Toggle as={Toggle}>
          <FontAwesomeIcon icon={faEllipsisH} size="sm" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {upc > 0 && promotions && permissions.read_shop_promotion &&
            <Dropdown.Item key={0} id="promotions" onClick={onPromotionsClick}>
              {/* <FontAwesomeIcon icon={faTag} color={"var(--devo-orange)"} /> */}
              &nbsp;
              Promotions
            </Dropdown.Item>
          }
          {upc != null && productReport && permissions.read_shop_report_product &&
            <Dropdown.Item key={1} id="product-report" onClick={onProductReportClick}>
              {/* <FontAwesomeIcon icon={faPoll} color={"var(--devo-green)"} /> */}
              &nbsp;
              Product Report
            </Dropdown.Item>
          }
          {categoryId != null && categoryReport && permissions.read_shop_report_sales &&
            <Dropdown.Item key={2} id="category-report" onClick={onCategoryReportClick}>
              {/* <FontAwesomeIcon icon={faPoll} color={"var(--devo-blue)"} /> */}
              &nbsp;
              {categoryName || "Category"} Report
            </Dropdown.Item>
          }

          {!!onVersionHistoryClick &&
            <Dropdown.Item key={3} id="version-history" onClick={onVersionHistoryClick}>
              {/* <FontAwesomeIcon icon={faHistory} color={"var(--secondary-label-color)"} /> */}
              &nbsp;
              Version History
            </Dropdown.Item>
          }

          {!!onDeleteClick && permissions.write_shop_product && <>
            <Dropdown.Divider />
            <Dropdown.Item key={4} id="delete" className="destructive" onClick={onDeleteClick}>
              {/* <FontAwesomeIcon icon={faTrashAlt} color={"var(--devo-red)"} /> */}
              &nbsp;
              Delete
            </Dropdown.Item>
          </>}
        </Dropdown.Menu>
      </Dropdown>
    </Wrapper>
  );
};

export default OptionsMenu;