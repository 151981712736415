import React from 'react';
import styled from 'styled-components';
import Dropdown from 'react-bootstrap/Dropdown';
import { faEdit, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VERY_TINY_BREAKPOINT_PX } from 'components/DeliveryServicesTable/DeliveryServicesTable.style';

const Wrapper = styled.td`
  &.menu {
    text-align: center;
  }

  & button {
    padding: 2px 8px;
    color: var(--primary-label-color);
    background-color: var(--background-color);
    border: var(--devo-border);
    border-radius: var(--devo-border-radius);
    transition: all 0.2s;

    &:hover,
    &:focus {
      background-color: var(--secondary-background-color);
      box-shadow: var(--devo-shadow);
      border-color: var(--primary-label-color);
    }
  }

  .options {
    a {
      display: block;
      background-color: var(--background-color);
      border-radius: 4px;
      height: 24px;
      width: 24px;
      border: 1px var(--border-color) solid;
      box-shadow: var(--devo-shadow);

      @media screen and (min-width: ${VERY_TINY_BREAKPOINT_PX}px) {
        height: 28px;
        width: 28px;
      }
    }

    a svg {
      align-self: center;
      color: var(--primary-label-color);
    }
  }

  a.dropdown-item.destructive {
    color: var(--devo-red);
  }
`;

// @ts-ignore
const Toggle = React.forwardRef(({ children, onClick }, ref) => (
  <div className="options">
    <a
      href=""
      className="options"
      // @ts-ignore
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  </div>
));

type OptionsMenuProps = {
  onCustomClick: (e: any) => void;
};

const OptionsMenu: React.FC<OptionsMenuProps> = ({ onCustomClick }) => {
  return (
    <Wrapper className="menu">
      <Dropdown align="end">
        <Dropdown.Toggle as={Toggle}>
          <FontAwesomeIcon icon={faEllipsisH} size="sm" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item key={1} id="edit" onClick={onCustomClick}>
            <FontAwesomeIcon icon={faEdit} />
            &nbsp; Custom Categories
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Wrapper>
  );
};

export default OptionsMenu;
