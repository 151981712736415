import styled from 'styled-components';

export const StatusChangeMenu = styled.div`
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StatusChangeMenuHeader = styled.div`
  display: flex;
  justify-content: end;
`;

export const StatusChangeMenuItem = styled.div`
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
`;
