import CompareLabel from 'components/Misc/CompareLabel';
import React, { useEffect, useMemo, useState } from 'react';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PRODUCT_REMOTE_REPORT_ROUTE } from 'constants/navigation';
import usePermissions from 'endpoints/permissions/usePermissions';
import {
  Row,
  Category as CategoryItem,
  Name,
  Price,
  Image,
  Size,
  StatusTd,
  StockTd,
} from './ProductRow.style';
import {
  Row as MobileRow,
  Image as MobileImage,
  Category as MobileCategory,
  Name as MobileName,
  Tag as MobileTag,
} from './Mobile.style';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Status from './Status';
import PriceMarkPopover from 'components/PriceMarkPopover/PriceMarkPopover';
import Check from 'components/Check/Check';
import { Caption, Label } from 'styles/Global';

import { ShopProduct, AvailabilityEnum } from 'models/Product';

import { formatSize, formatProductUpc } from 'helper/products';
import usePrevious from 'helper/usePrevious';
import {
  formatPrice,
  formatPriceNumber,
  formatVatRate,
  quantityMeasureDescription,
  shopToDevoPrice,
} from 'helper/product';
import { Category } from 'models/Category';
import { useMutation } from '@apollo/react-hooks';
import { PATCH_PRODUCT } from 'devoapi/products';
import useShopContext from 'contexts/shop/shop.context';
import Loader from 'components/Loader/Loader';
import Log from 'helper/monitoring';
import Mixpanel from 'helper/Mixpanel';

import PriceChangePopup from 'components/PriceChangePopup/PriceChangePopup';
import OptionsMenu from './OptionsMenu';
import VersionHistoryPopup from 'components/VersionHistoryPopup/VersionHistoryPopup';
import useUndoContext from 'contexts/undo/undo.context';
import { ChangeAction, Version } from 'models/VersionHistory';
import PlaceholderImage from 'image/product-placeholder.png';
import { ViewingOptionsProps } from './ViewingOptions';
import { TimeFrameProps } from 'helper/datetime/useTimeFrame';
import { RemoteReportBaseModel } from 'models/RemoteReports/BaseRemoteReports';

export type ProductRowMode = 'LIVE' | 'ADDED' | 'ADD' | 'PREVIEW';
export type ProductRowProps = {
  mobile: boolean;
  item: ShopProduct;
  index: number;
  mode: ProductRowMode;
  showOptions: boolean;
  inSelection: boolean;
  isSelected: boolean;
  toggleSelected: () => void;
  setStatus: (val: AvailabilityEnum, addUndo: boolean) => void;
  categoryShortcutEnabled: boolean;
  sales?: number;
  previousSales?: number;
  stock?: number;
  openStock?: () => void;
  openSales?: (
    report: RemoteReportBaseModel,
    previousReport: RemoteReportBaseModel
  ) => void;
  onClickCategory: (category: Category) => void;
  onClickPrice: () => void;
  noCheckBox?: boolean;
  afterScan?: boolean;
  timeFrameProps: TimeFrameProps;
  report?: RemoteReportBaseModel;
  previousReport?: RemoteReportBaseModel;
  reportLoading?: boolean;
} & ViewingOptionsProps;

const ProductRow: React.FC<ProductRowProps> = ({
  mobile,
  item,
  index,
  mode,
  showOptions = false,
  inSelection,
  isSelected,
  toggleSelected,
  setStatus,
  categoryShortcutEnabled,
  sales,
  previousSales,
  stock,
  openStock,
  openSales,
  onClickCategory,
  onClickPrice,
  priceView = 'SHOP',
  setPriceView,
  showVat = true,
  setShowVat,
  noCheckBox,
  afterScan = false,
  report,
  previousReport,
  reportLoading,
}) => {
  const { shopState } = useShopContext();
  const { isPosEnabled, permissions } = usePermissions();
  let editProductsEnabled = permissions.write_shop_product;

  const [shopPrice, setShopPrice] = useState(item.shopPrice);
  const [devoPrice, setDevoPrice] = useState(item.devoPrice);

  const isPriceMarked = item.product.priceMarked;
  const markup = item.shopCategory.devoMarkup;

  useEffect(() => updatePrices(), [item.shopPrice, item.devoPrice]);

  const updatePrices = () => {
    setShopPrice(item.shopPrice);
    setDevoPrice(item.devoPrice);
  };

  // --------------------------------------------
  // Modals
  // --------------------------------------------

  const [priceItem, setPriceItem] = useState<ShopProduct>(null);
  const [versionHistoryModalOpen, setVersionHistoryModalOpen] = useState(false);

  // --------------------------------------------
  // Undo
  // --------------------------------------------

  const [undoInProgress, setUndoInProgress] = useState(false);
  const { undoState, undoDispatch } = useUndoContext();

  useEffect(() => {
    const action: ChangeAction = undoState?.pending?.filter(
      (x) => x.upc === item.product.upc
    )[0];
    if (!action) return;

    Log.debug('Reversing change action...', 'undo', action);
    undoDispatch({ type: 'REMOVE_PENDING', payload: action });
    setUndoInProgress(true);

    handleRevertToChange({
      change: action.change,
      time: action.time,
      from: null,
      to: null,
    });
  }, [undoState?.pending]);

  // --------------------------------------------
  // API
  // --------------------------------------------

  const [_updateStatus, { loading: statusLoading }] = useMutation(
    PATCH_PRODUCT,
    {
      onCompleted: (res) => {
        Log.debug('PATCH_PRODUCT status onCompleted', 'products', res);

        if (undoInProgress && undoState.inProgress) {
          undoDispatch({ type: 'UPDATED' });
        }

        setVersionHistoryModalOpen(false);
      },
    }
  );

  const [priceError, setPriceError] = useState(null);
  const [_updatePrice, { loading: priceLoading }] = useMutation(PATCH_PRODUCT, {
    onCompleted: (res) => {
      Log.debug('PATCH_PRODUCT price onCompleted', 'products', res);
      Mixpanel.track('product_row_change_price', {
        from_shop_price: item.shopPrice,
        from_devo_price: item.devoPrice,
        to_shop_price: shopPrice,
        to_devo_price: devoPrice,
      });

      if (undoInProgress && undoState.inProgress) {
        undoDispatch({ type: 'UPDATED' });
      } else {
        undoDispatch({
          type: 'ADD_CHANGE',
          payload: {
            upc: item.product.upc,
            change: { type: 'shopPrice', from: item.shopPrice, to: shopPrice },
          },
        });
      }

      item.shopPrice = shopPrice;
      item.devoPrice = devoPrice;
      setVersionHistoryModalOpen(false);
      updatePrices();
    },
    onError: (err) => setPriceError(err),
  });

  const updateProduct = (f: any, input: any) => {
    setPriceError(null);
    try {
      f({
        variables: {
          shopId: shopState.uuid,
          productUpc: item.product.upc,
          input,
        },
      });
    } catch (error) {
      Log.warning('Failed to update product', 'products', error);
    }
  };

  const updateStatus = (availabilityText: AvailabilityEnum) =>
    updateProduct(_updateStatus, { availabilityText });
  const updatePrice = (shopPrice: number) =>
    updateProduct(_updatePrice, { shopPrice });

  // --------------------------------------------
  // Helpers
  // --------------------------------------------

  const onRowClick = (e: any) => {
    if (mode === 'PREVIEW') return;
    if (rowClickable === true) toggleSelected();
  };

  const onMobilePriceClick = (item: ShopProduct) => {
    setPriceItem(item);
  };

  const doNothing = () => {};

  // --------------------------------------------
  // Status
  // --------------------------------------------

  const onStatusSelect = (value: AvailabilityEnum) => {
    Mixpanel.track('product_row_select_availability', { availability: value });
    setStatus(value, true);
    if (mode === 'ADDED' || mode === 'PREVIEW') updateStatus(value);
  };

  const previousAvailability = usePrevious(item.availability);
  useEffect(() => {
    if (mode === 'PREVIEW') return;
    if (!previousAvailability || previousAvailability === item.availability)
      return;
    updateStatus(item.availability);
  }, [item.availability]);

  // --------------------------------------------
  // Actions
  // --------------------------------------------

  const handleVersionHistoryClick = (e) => setVersionHistoryModalOpen(true);

  const handleDeleteClick = (e) => onStatusSelect('REMOVED');

  const handleRevertToChange = (version: Version) => {
    Log.info('Reverting to change', 'version-history', version.change);
    const val = version.change.from;
    switch (version.change.type) {
      case 'shopPrice':
        setShopPrice(val);
        setDevoPrice(shopToDevoPrice(val, markup));
        updatePrice(val);
        break;
      case 'availabilityText':
        setStatus(val, false);
        if (mode === 'ADDED' || mode === 'PREVIEW') updateStatus(val);
        break;
      default:
        return;
    }
  };

  const priceChangePopupKey = useMemo(
    () => priceItem?.product.upc?.toString().concat('-product-row') ?? null,
    [priceItem]
  );

  // --------------------------------------------
  // Render
  // --------------------------------------------

  let editPriceEnabled = editProductsEnabled;

  const rowClickable = inSelection;
  const clickableClassName = rowClickable ? 'clickable' : '';
  const categoryName =
    item.product.category.id === 1
      ? 'All Products'
      : item.product.category.name;

  if (mobile) {
    return (
      <MobileRow className="product" key={index} afterScan={afterScan}>
        {editProductsEnabled && (
          <PriceChangePopup
            key={priceChangePopupKey}
            isOpen={!!priceItem}
            closeModal={() => setPriceItem(null)}
            selectedItems={[priceItem]}
            onPriceChange={(newProduct) => setShopPrice(newProduct.shopPrice)}
            usePosModel
          />
        )}

        {!noCheckBox && (
          <Check
            style={{ margin: '20px 0 auto 0' }}
            onClick={() => toggleSelected()}
            isSelected={isSelected}
            className="check"
          />
        )}

        <MobileImage>
          <img
            src={item.product.thumbnail || PlaceholderImage}
            alt={item.product.name}
          />
        </MobileImage>
        <MobileName>
          <p>
            {item.product.name}
            &nbsp;
            {isPriceMarked && <Badge bg="primary">PM</Badge>}
          </p>

          <MobileCategory>
            {/* {categoryShortcutEnabled === null && */}
            {/* <p>{categoryName}</p> */}
            {/*  } */}
            {/* {categoryShortcutEnabled && */}
            <a onClick={() => onClickCategory(item.product.category)}>
              {categoryName}
            </a>
            {/* } */}
          </MobileCategory>

          <MobileTag.Wrapper>
            <MobileTag.Card style={{ marginRight: 12, flexGrow: 1 }}>
              <MobileTag.Content>
                <MobileTag.Label>
                  {item.product.quantityUnits.toString()}
                  <span>
                    {quantityMeasureDescription(item.product.quantityMeasure)}
                  </span>
                </MobileTag.Label>
                <MobileTag.Subtitle>size</MobileTag.Subtitle>
              </MobileTag.Content>
            </MobileTag.Card>
            <MobileTag.Card
              style={{ marginRight: 12, flexGrow: 1 }}
              onClick={(e) => onMobilePriceClick(item)}
            >
              <MobileTag.Content>
                <MobileTag.Label>
                  <span>£</span>
                  {formatPriceNumber(shopPrice, '0', true)}
                </MobileTag.Label>
                <MobileTag.Subtitle>shop</MobileTag.Subtitle>
              </MobileTag.Content>
            </MobileTag.Card>
            <MobileTag.Card
              style={{ marginRight: 12, flexGrow: 1 }}
              onClick={(e) => openStock && openStock()}
            >
              <MobileTag.Content>
                <MobileTag.Label>{stock}</MobileTag.Label>
                <MobileTag.Subtitle>stock</MobileTag.Subtitle>
              </MobileTag.Content>
            </MobileTag.Card>
            <MobileTag.Card style={{ marginRight: 0 }}>
              <MobileTag.Content>
                {statusLoading ? (
                  <Loader style={{ margin: '0 auto' }} />
                ) : (
                  <Status
                    value={item.availability}
                    onSelect={onStatusSelect}
                    postScan
                  />
                )}
              </MobileTag.Content>
            </MobileTag.Card>
          </MobileTag.Wrapper>
        </MobileName>
      </MobileRow>
    );
  }

  const selectedBackground = isSelected || mode === 'ADDED' ? ' selected' : '';
  const rowClassName = `product${selectedBackground}`;
  const showAdvancedOptions = mode == 'LIVE';

  const priceToShow = () => {
    switch (priceView) {
      case 'SHOP':
        return item.shopPrice;
      case 'ONLINE':
        return item.devoPrice;
    }
  };

  return (
    <Row key={index} className={rowClassName}>
      <VersionHistoryPopup
        item={item}
        isOpen={versionHistoryModalOpen}
        closeModal={() => setVersionHistoryModalOpen(false)}
        onSelectRevert={handleRevertToChange}
      />

      {mode === 'LIVE' && (
        <td>
          <Check
            onClick={() => toggleSelected()}
            isSelected={isSelected}
            className="check"
          />
        </td>
      )}

      <CategoryItem onClick={onRowClick} className={clickableClassName}>
        {categoryShortcutEnabled ? (
          <a onClick={() => onClickCategory(item.product.category)}>
            {categoryName}
          </a>
        ) : (
          <span>{categoryName}</span>
        )}
      </CategoryItem>

      <Size onClick={onRowClick} className={clickableClassName}>
        {formatSize(item)}
      </Size>

      <Image onClick={onRowClick} className={clickableClassName}>
        <img
          src={item.product.thumbnail || PlaceholderImage}
          alt={item.product.name}
        />
      </Image>

      <Name onClick={onRowClick} className={clickableClassName}>
        <Label>
          {item.product.name}{' '}
          {isPriceMarked && (
            <span style={{ marginLeft: 4 }}>
              <PriceMarkOverlay gap={false} />
            </span>
          )}
        </Label>

        <span className="barcode">{formatProductUpc(item)}</span>
      </Name>

      {mode === 'LIVE' && isPosEnabled && permissions.read_stock && (
        <StockTd>
          <a className="text" onClick={openStock || function () {}}>
            {stock === null ? 0 : stock}
          </a>
        </StockTd>
      )}

      {mode === 'LIVE' && sales !== null && (
        <StockTd>
          <a
            className={'text'}
            href={
              openSales
                ? null
                : `${PRODUCT_REMOTE_REPORT_ROUTE.replace(
                    '[upc]',
                    item.product.upc.toString()
                  )}`
            }
            onClick={() => {
              if (typeof openSales === 'function') {
                openSales(report, previousReport);
              }
            }}
            target={openSales ? null : '_blank'}
          >
            {reportLoading ? 0 : sales}
            <br />
            {sales !== null && (
              <CompareLabel
                compact
                emojis
                {...{
                  value: sales,
                  compareValue: previousSales,
                }}
              />
            )}
          </a>
        </StockTd>
      )}

      <Price className={!editPriceEnabled ? 'pm' : ''}>
        <button onClick={editPriceEnabled ? onClickPrice : doNothing}>
          <div className={'content'}>
            <Label>{formatPrice(priceToShow())}</Label>
            {showVat && <Caption>{formatVatRate(item.vatRate)} VAT</Caption>}
          </div>
          {editPriceEnabled && (
            <FontAwesomeIcon icon={faEdit} className={'edit-icon'} />
          )}
        </button>
      </Price>

      <StatusTd>
        {statusLoading ? (
          <Loader />
        ) : (
          <Status value={item.availability} onSelect={onStatusSelect} />
        )}
      </StatusTd>

      {showOptions && (
        <OptionsMenu
          product={item.product}
          onVersionHistoryClick={
            showAdvancedOptions ? handleVersionHistoryClick : null
          }
          onDeleteClick={showAdvancedOptions ? handleDeleteClick : null}
        />
      )}
    </Row>
  );
};

export const PriceMarkOverlay = ({
  enabled = true,
  gap = true,
}: {
  enabled?: boolean;
  gap?: boolean;
}) => {
  if (enabled) {
    return (
      <>
        {gap && <>&nbsp;</>}
        <OverlayTrigger overlay={PriceMarkPopover}>
          <Badge className="noselect" bg="primary" style={{ color: 'white' }}>
            PM
          </Badge>
        </OverlayTrigger>
      </>
    );
  } else {
    return <></>;
  }
};

export default ProductRow;
