import { ApolloQueryResult, useQuery } from '@apollo/client';
import { GET_STOCK_SUMMARY } from 'devoapi/stock';
import usePermissions from 'endpoints/permissions/usePermissions';
import useShopContext from 'contexts/shop/shop.context';
import { addYearsToDate } from 'helper/datetime/dates';
import Log from 'helper/monitoring';
import { roundTo } from 'helper/product';
import { StockSummary, StockSummaryProduct } from 'models/Stock';
import React, { useState } from 'react';

type UseBulkStockProviderProps = {
  upcs: number[];
  queryLimit?: number;
  fromDate?: Date;
  toDate?: Date;
};

type UseBulkStockProviderCallbackProps = {
  stockSummary: StockSummary;
  getStockBalance: (upc: number) => number;
  fetched: boolean;
  refetch: (
    variables?: Partial<{
      shopUuid: any;
      productUpcs: string | any[];
      from: number;
      to: number;
    }>
  ) => Promise<ApolloQueryResult<any>>;
  updateStockProduct: (product: StockSummaryProduct) => void;
};

const defaultQueryLimit = 100;

const useBulkStockProvider = ({
  upcs,
  fromDate: _fromDate,
  toDate: _toDate,
  queryLimit,
}: UseBulkStockProviderProps): UseBulkStockProviderCallbackProps => {
  const [fromDate] = useState(addYearsToDate(new Date(), -1));
  const [toDate] = useState(new Date());

  const { isPosEnabled } = usePermissions();
  const { shopState } = useShopContext();

  const stockUpcs =
    upcs
      ?.map((x) => x.toString())
      .slice(0, queryLimit || defaultQueryLimit)
      .join(',') || [];
  const [stockFetched, setStockFetched] = useState(false);
  const [stockSummary, setStockSummary] = useState<StockSummary>(null);

  const { refetch } = useQuery(GET_STOCK_SUMMARY, {
    skip: !shopState || stockUpcs.length == 0 || !isPosEnabled,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      shopUuid: shopState?.uuid,
      productUpcs: stockUpcs,
      from: roundTo(fromDate.getTime() / 1000),
      to: roundTo(toDate.getTime() / 1000),
    },
    onCompleted: (res) => {
      Log.debug('GET_STOCK_SUMMARY onCompleted', 'stock', res);
      setStockFetched(!!res && 'stockSummary' in res);
      setStockSummary(res?.stockSummary);
    },
  });

  const getStockBalance = (upc: number): number => {
    return stockSummary?.stockProduct[upc.toString()]?.balance || 0;
  };

  const updateStockProduct = (product: StockSummaryProduct) => {
    setStockSummary({
      ...stockSummary,
      stockProduct: {
        ...stockSummary.stockProduct,
        [product.productUpc]: product,
      },
    });
  };

  return {
    stockSummary,
    getStockBalance,
    fetched: stockFetched,
    refetch,
    updateStockProduct,
  };
};

export default useBulkStockProvider;
