import { useState } from 'react';
import {
  ApolloQueryResult,
  FetchResult,
  useMutation,
  useQuery,
} from '@apollo/client';
import useShopContext from 'contexts/shop/shop.context';
import { GET_POS_PRODUCT, UPDATE_POS_PRODUCT } from 'devoapi/pos_products';
import PosProduct from 'models/PosProduct';
import { AvailabilityEnum } from 'models/Product';

type UsePosProductProviderProps = {
  upc: number;
  skip?: boolean;
  onCompleted?: (res: PosProduct) => void;
  onError?: (err: any) => void;
};

type UsePosProductProviderCallback = {
  product: PosProduct;
  loading: boolean;
  error: any;
  refetch: (
    variables?: Partial<GetPosProductPayload>
  ) => Promise<ApolloQueryResult<GetPosProductResponse>>;
  updating: boolean;
  updateError: any;
  updatePosProduct: (
    posProduct: PosProduct
  ) => Promise<FetchResult<UpdatePosProductResponse>>;
  updatePosProductPrice: (
    newPrice: number
  ) => Promise<FetchResult<UpdatePosProductResponse>>;
  updatePosProductStatus: (
    newStatus: AvailabilityEnum
  ) => Promise<FetchResult<UpdatePosProductResponse>>;
};

type GetPosProductResponse = {
  product: PosProduct;
};

type GetPosProductPayload = {
  shopId: string | number;
  upc: number;
};

export type UpdatePosProductResponse = {
  product: PosProduct;
};

export type UpdatePosProductPayload = {
  input: Partial<Omit<PosProduct, 'priceMarked'> & { isPriceMarked: boolean }>;
};

const usePosProductProvider = (
  props: UsePosProductProviderProps
): UsePosProductProviderCallback => {
  const { upc, skip = false, onCompleted = null, onError = null } = props;
  const { shopState } = useShopContext();

  const [product, setProduct] = useState<PosProduct>(null);

  const { loading, error, refetch } = useQuery<
    GetPosProductResponse,
    GetPosProductPayload
  >(GET_POS_PRODUCT, {
    skip: !shopState || upc == null || skip,
    fetchPolicy: 'no-cache',
    variables: {
      shopId: shopState?.uuid,
      upc,
    },
    onCompleted: (res) => {
      let rawProduct = res?.product as PosProduct & { __typename: string };

      if (!rawProduct) {
        return onError(null);
      }

      setProduct({
        uuid: rawProduct.uuid,
        upc: rawProduct.upc,
        shopUuid: rawProduct.shopUuid,
        name: rawProduct.name,
        units: rawProduct.units,
        quantityUnits: rawProduct.quantityUnits,
        quantityMeasure: rawProduct.quantityMeasure,
        categoryId: rawProduct.categoryId,
        vatCode: rawProduct.vatCode,
        thumbnailUrl: rawProduct.thumbnailUrl,
        imageUrl: rawProduct.imageUrl,
        rsp: rawProduct.rsp,
        shopPrice: rawProduct.shopPrice,
        createdAt: rawProduct.createdAt,
        isLive: rawProduct.isLive,
        priceMarked: rawProduct.priceMarked,
        availability: rawProduct.availability,
        quantityMeasurePricing: rawProduct.quantityMeasurePricing,
        quantityUnitsPricing: rawProduct.quantityUnitsPricing,
      });
      if (onCompleted != null) onCompleted(rawProduct);
    },
    onError: (err) => {
      if (onError != null) onError(err);
    },
  });

  const [_updatePosProduct, { loading: updating, error: updateError }] =
    useMutation<UpdatePosProductResponse, UpdatePosProductPayload>(
      UPDATE_POS_PRODUCT
    );

  const normalizePosProduct = (posProduct: PosProduct) =>
    Object.entries(posProduct).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key === 'priceMarked' ? 'isPriceMarked' : key]: value,
      }),
      {}
    ) as Omit<PosProduct, 'priceMarked'> & {
      isPriceMarked: boolean;
    };

  const updatePosProduct = (posProduct: PosProduct) =>
    _updatePosProduct({
      variables: {
        input: normalizePosProduct(posProduct),
      },
    });

  const updatePosProductPrice = (newPrice: number) =>
    _updatePosProduct({
      variables: {
        input: normalizePosProduct({ ...product, shopPrice: newPrice }),
      },
    });

  const updatePosProductStatus = (newStatus: AvailabilityEnum) =>
    _updatePosProduct({
      variables: {
        input: normalizePosProduct({ ...product, availability: newStatus }),
      },
    });

  return {
    product,
    loading,
    error,
    refetch,
    updating,
    updateError,
    updatePosProduct,
    updatePosProductPrice,
    updatePosProductStatus,
  };
};

export default usePosProductProvider;
