import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatPrice, roundTo } from 'helper/product'
import React from 'react'
import styled from 'styled-components'
import { Caption } from 'styles/Global'

type CompareLabelProps = {
  value: number;
  compareValue: number;
  formatAsPrice?: boolean;
  compact?: boolean;
  emojis?: boolean;
}

const Style = styled(Caption)`
  margin: 8px 0;
  text-align: center;
  font-weight: 600; 
  &.increase { color: var(--devo-green); }
  &.decrease { color: var(--devo-red); }
`;

const CompareLabel: React.FC<CompareLabelProps> = ({
  value,
  compareValue,
  formatAsPrice = false,
  compact = false,
  emojis = false,
}) => {

  let valueChange = Math.abs(((value - compareValue) / value) * 100);

  let unitPrefix = ""; let unitSuffix = "%";
  let shouldFormatCompareAsPrice = false;
  if (value == 0 || compareValue == 0) {
    valueChange = Math.max(value, compareValue) - Math.min(value, compareValue);
    unitSuffix = compact ? "" : " items";
    if (formatAsPrice) {
      unitSuffix = "";
      shouldFormatCompareAsPrice = true
    }
  }

  let compareClassName = "";
  let compareIcon = null;
  let compareEmoji = null;

  if (value > compareValue) {
    compareClassName = "increase";
    compareIcon = faArrowUp;
    if (valueChange > 100) {
      if (!formatAsPrice) unitPrefix = "+";
      compareEmoji = "🔥";
    }
  } else if (value < compareValue) {
    compareClassName = "decrease";
    compareIcon = faArrowDown;
  } else if (value == compareValue && value > 0) {
    if (!formatAsPrice) unitPrefix = "+";
    unitSuffix = "";
  }

  if (!emojis) compareEmoji = null;

  if (valueChange == 0 && compact) return <></>;

  return (
    <Style className={"compare " + compareClassName}>
      {compareIcon && !compareEmoji && <><FontAwesomeIcon icon={compareIcon} />&nbsp;</>}
      {compareEmoji && <span>{compareEmoji}&nbsp;&nbsp;</span>}
      {unitPrefix}
      {shouldFormatCompareAsPrice ? formatPrice(valueChange) : roundTo(valueChange).toLocaleString()}
      {unitSuffix}
    </Style>
  )
}

export default CompareLabel