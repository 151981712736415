import React, { useEffect } from 'react';
import { StockSummaryProduct } from 'models/Stock';
import MobileDataItem, {
  MobileDataItemBlockProps,
} from 'components/MobileDataItem/MobileDataItem';
import useProductsContext from 'contexts/products';
import { Product } from '../Orders/OrderPopup.style';
import Loader from 'components/Loader/Loader';
import { formatVatCode } from 'helper/product';

type PopupPosProductProps = {
  upc: number;
  stockSummary?: StockSummaryProduct;
};

const PopupPosProduct: React.FC<PopupPosProductProps> = ({
  upc,
  stockSummary,
}) => {
  const { getProductByUpc, requestProducts } = useProductsContext();
  const { product, loading, error } = getProductByUpc(upc);

  useEffect(() => {
    requestProducts([upc]);
  }, [upc]);

  if (!product)
    return (
      <Product.Wrapper>
        <Loader />
      </Product.Wrapper>
    );

  let blocks: MobileDataItemBlockProps[] = [
    {
      label: 'Size',
      size: { units: product.quantityUnits, measure: product.quantityMeasure },
    },
    { label: 'Shop Price', price: product.shopPrice },
    { label: 'VAT', value: formatVatCode(product.vatCode) },
  ];
  if (!!stockSummary) {
    blocks.push({
      label: 'Stock',
      value: stockSummary.balance.toLocaleString(),
    });
  }

  if (product) {
    return (
      <Product.Wrapper className={`popup-product bordered noselect`}>
        <MobileDataItem
          title={product.name}
          showPriceMark={product.priceMarked}
          subtitleUpc={upc}
          image={product.thumbnailUrl}
          showPlaceholder
          indentBlocks
          scrollBlocks
          blocks={blocks}
        />
      </Product.Wrapper>
    );
  }

  return <></>;
};

export default PopupPosProduct;
