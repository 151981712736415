import Popup, { DevoPopupProps } from 'components/Popup/Popup';
import { AvailabilityEnum } from 'models/Product';
import {
  StatusChangeMenu,
  StatusChangeMenuHeader,
  StatusChangeMenuItem,
} from './StatusChangePopup.style';
import { availabilityColor, availabilityDescription } from 'helper/product';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faClose } from '@fortawesome/free-solid-svg-icons';

type StatusChangePopupProps =  {
  handleStatusChange: (availability: AvailabilityEnum) => void;
};


const statuses: AvailabilityEnum[] = ['AVAILABLE', 'NOT_AVAILABLE'];

const StatusChangePopup: React.FC<StatusChangePopupProps & DevoPopupProps> = ({
  isOpen,
  closeModal,
  handleStatusChange,
}) => {
  return (
    <Popup isOpen={isOpen} closeModal={closeModal}>
      <StatusChangeMenu>
        <StatusChangeMenuHeader>
          <FontAwesomeIcon icon={faClose} size="xl" onClick={closeModal} />
        </StatusChangeMenuHeader>
        {statuses.map((option) => (
          <StatusChangeMenuItem
            onClick={() => {
              handleStatusChange(option);
              closeModal();
            }}
          >
            <FontAwesomeIcon
              icon={faCircle}
              color={availabilityColor(option)}
            />
            &nbsp;&nbsp;
            {availabilityDescription(option)}
          </StatusChangeMenuItem>
        ))}
      </StatusChangeMenu>
    </Popup>
  );
};

export default StatusChangePopup;
