import styled from 'styled-components'

type ListContainerProps = { asModule: boolean; }
export const ListContainer = styled.div<ListContainerProps>`
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
  
  & > *:not(:last-child) {
    border-bottom: 1px var(--separator-color) solid;
  }
  
  ${props => props.asModule ? `
    margin: 20px 0;
    background-color: var(--background-color);
    border-radius: var(--devo-border-radius);
  ` : ""}
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 20px;
  padding: 20px;
  width: 100%;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 8px;
`;

export const ImageContainer = styled.div`
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  img {
    width: 44px;
    height: 44px;
    object-fit: contain;
  }
`;

export const IconContainer = styled.div`
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentContainer = styled.div`
  flex-grow: 2;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  
  .content {
    flex-grow: 2;
    display: flex;
    flex-flow: column nowrap;
    p { font-size: 17px; font-weight: 600; }
  }
`;

type BlocksContainerProps = { indentBlocks: boolean; scroll: boolean }
export const BlocksContainer = styled.div<BlocksContainerProps>`
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
  ${props => props.indentBlocks === true ? `
    padding-left: 52px;
  ` : ""}
  ${props => props.scroll === true ? `
    flex-wrap: nowrap;
    overflow: scroll;
    white-space: nowrap;
    padding-right: 32px;
  ` : ""}
`;

export const Block = styled.div`
  p { font-size: 14px; font-weight: 600; }
  span { font-size: 14px; }
  .badge {
    p, span {
      color: inherit;
    }
  }
`;