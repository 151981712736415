import React, { useState, useEffect, useRef } from 'react';
import usePermissionsContext from 'contexts/permissions';
import { fetchUserProperties } from 'helper/analytics/analytics';
import { checkEnvironment } from 'helper/environment';
import useGetUserSecurityGroups from 'endpoints/permissions/useGetUserSecurityGroups';
import { useQuery } from '@apollo/react-hooks';
import { useRouter } from 'next/router';
import useDepotShopContext from 'contexts/depot-shop/depot-shop.context';
import {
  LayoutWrapper,
  SidebarWrapper,
  ContentWrapper,
  MobileSidebarWrapper,
  MobileContentWrapper,
} from './AppLayout.style';
import Modal from 'react-bootstrap/Modal';
import { withApollo } from 'helper/apollo';
import MobileNavBar from 'containers/NavBar/MobileNavBar';
import { DeviceType } from 'helper/useDeviceType';
import useAuth from 'contexts/auth/auth.context';
import Sticky from 'react-stickynode';
import Sidebar from 'components/Navigation/Sidebar';
import { useWindowSizeDeviceType } from 'helper/useWindowSize';
import { GET_DEPOT_SHOP, GET_SHOP } from 'devoapi/shop';
import { ShopResponse, Shop } from 'models/Shop';
import useShopContext from 'contexts/shop/shop.context';
import { getRefreshExpiryTime } from 'helper/user';
import {
  INVOICE_PDF_ROUTE,
  LOGIN_ROUTE,
  PREVIEW_LABELS_ROUTE,
} from 'constants/navigation';
import { identifyToMixpanel, setMixpanelUserProperty } from 'helper/Mixpanel';
import usePrevious from 'helper/usePrevious';
import Log from 'helper/monitoring';
import { toHHMMSS } from 'helper/datetime/dates';
import { UndoProvider } from 'contexts/undo/undo.provider';

type LayoutProps = {
  children?: any;
  deviceType: DeviceType;
};

const Layout: React.FunctionComponent<LayoutProps> = ({
  children,
  deviceType,
}) => {
  const { pathname } = useRouter();
  const { mobile, tablet, desktop } = useWindowSizeDeviceType();
  const [showDrawer, setShowDrawer] = useState(deviceType.desktop || desktop);

  const navBarRef = useRef(null);

  const { authState } = useAuth();
  const { shopDispatch } = useShopContext();
  const { depotShopDispatch } = useDepotShopContext();
  const { user } = authState;

  // --------------------------------------------
  // Analytics
  // --------------------------------------------

  const prevPathname = usePrevious(pathname);
  useEffect(() => {
    if (!process.browser) return;
    if (prevPathname !== pathname) newPageLoaded();
  }, [pathname]);

  const newPageLoaded = () => {
    if (mobile || tablet) setShowDrawer(false);
    window.scrollTo(0, 0);

    // Identify needs to be called every time you make a people.set call — to either create or update a people profile within Mixpanel.
    // https://community.mixpanel.com/sending-data-to-mixpanel-11/how-to-implement-people-set-in-js-api-585
    identifyToMixpanel();
  };

  useEffect(() => {
    const expiry = getRefreshExpiryTime();
    if (expiry)
      Log.debug(`Logged in, expiring in ${toHHMMSS(expiry)}`, 'session');

    fetchUserProperties((data) => {
      setMixpanelUserProperty(data);
    });

    return () => {
      setMixpanelUserProperty({ last_session: new Date().toISOString() });
      identifyToMixpanel();
    };
  }, []);

  // --------------------------------------------
  // Shop
  // --------------------------------------------

  useQuery<ShopResponse>(GET_SHOP, {
    skip: !user?.shopUuid,
    variables: {
      id: user?.shopUuid,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (res) => {
      shopDispatch({ type: 'SET_SHOP', payload: res?.shop });
      setMixpanelUserProperty({
        shop_id: res?.shop?.uuid,
        shop_name: res?.shop?.name,
      });
    },
  });

  useQuery(GET_DEPOT_SHOP, {
    skip: !user?.shopUuid || !checkEnvironment('stable'),
    variables: { id: user?.shopUuid },
    notifyOnNetworkStatusChange: true,
    onCompleted: (res) =>
      depotShopDispatch({ type: 'SET_DEPOT_SHOP', payload: res?.depotShop }),
    onError: (err) =>
      depotShopDispatch({ type: 'SET_DEPOT_SHOP', payload: null }),
  });

  // User security groups
  const { all: groups, loading } = useGetUserSecurityGroups(user?.uuid);
  const { setGroups, setFetchingGroups } = usePermissionsContext();
  useEffect(() => setGroups(groups), [groups]);
  useEffect(() => setFetchingGroups(loading), [loading]);

  // --------------------------------------------
  // Render
  // --------------------------------------------

  const getLayoutClassName = (pathname: string): string => {
    if (pathname.includes('/pos/report/')) return 'fullscreen';

    switch (pathname) {
      case LOGIN_ROUTE:
        return 'login';
      case INVOICE_PDF_ROUTE:
        return 'fullscreen';
      case PREVIEW_LABELS_ROUTE:
        return 'fullscreen';
      default:
        return '';
    }
  };

  const layoutClassName =
    getLayoutClassName(pathname) + ' ' + (showDrawer ? '' : 'hideDrawer');

  if (layoutClassName.includes('fullscreen')) {
    return <>{children}</>;
  }

  const currentUserExists = user && !layoutClassName.includes('login');
  const navBarHeight = currentUserExists
    ? navBarRef.current?.offsetHeight || 0
    : 0;

  if (mobile || tablet) {
    return (
      <LayoutWrapper id="mobile-layout" className="mobile">
        <Sticky className="mobile-navbar">
          {currentUserExists && (
            <UndoProvider>
              <MobileNavBar
                reference={navBarRef}
                onMenuClick={() => setShowDrawer(!showDrawer)}
              />
            </UndoProvider>
          )}
        </Sticky>

        <Modal
          show={showDrawer && currentUserExists}
          onHide={() => setShowDrawer(false)}
          dialogClassName="devo-dialog"
        >
          <MobileSidebarWrapper>
            {currentUserExists && (
              <Sidebar mobile {...{ showDrawer, setShowDrawer }} />
            )}
          </MobileSidebarWrapper>
        </Modal>

        <MobileContentWrapper
          id="mobile-content"
          navBarHeight={navBarHeight}
          className={layoutClassName}
        >
          {children}
        </MobileContentWrapper>
      </LayoutWrapper>
    );
  } else {
    return (
      <LayoutWrapper id="layout">
        <SidebarWrapper
          id="sidebar"
          className={showDrawer ? '' : 'hidden'}
          animate={currentUserExists}
        >
          {currentUserExists && <Sidebar {...{ showDrawer, setShowDrawer }} />}
        </SidebarWrapper>

        <ContentWrapper id="content" className={layoutClassName}>
          {children}
        </ContentWrapper>
      </LayoutWrapper>
    );
  }
};

export default withApollo(Layout);
