import { QuantityMeasureEnum, ShopProduct } from 'models/Product';
import { fillArray } from './array';
import { quantityMeasureDescription } from './product';
import PosProduct from 'models/PosProduct';

export const formatUpc = (str: string): string => {
  if (!str) return '';
  if (str == '0') return str;
  const filler = fillArray('0', 12 - str.length).join('');
  return filler + str;
};

export const formatProductUpc = (item: ShopProduct): string => {
  const partial = item?.product.upc.toString();
  return formatUpc(partial);
};

export const formatSize = (item: ShopProduct): string => {
  if (!item?.product || item?.product?.quantityUnits === 0) {
    return '';
  }

  let quantity = quantityMeasureDescription(item?.product?.quantityMeasure);
  return `${item?.product?.quantityUnits?.toString() || 0}${quantity}`;
};

export const formatPosProductSize = (
  item: PosProduct,
  itemsPerPack: number | null = null
): string => {
  if (!item || item?.quantityUnits === 0) {
    return '';
  }

  let quantity = quantityMeasureDescription(
    item?.quantityMeasure as QuantityMeasureEnum
  );
  return `${item?.quantityUnits?.toString() || 0}${quantity}`;
};

export const formatSizeSeparately = (
  item: ShopProduct | PosProduct
): string[] => {
  if (!item) {
    return ['', ''];
  }

  if (!('isLive' in item)) {
    if (!item?.product || item?.product?.quantityUnits === 0) {
      return ['', ''];
    }

    let quantity = quantityMeasureDescription(item?.product?.quantityMeasure);
    return [
      (item?.product?.quantityUnits?.toString() || 0).toString(),
      quantity.toString(),
    ];
  }
  if (!item || item?.quantityUnits === 0) {
    return ['', ''];
  }

  let quantity = quantityMeasureDescription(
    item?.quantityMeasure as QuantityMeasureEnum
  );
  return [
    (item?.quantityUnits?.toString() || 0).toString(),
    quantity.toString(),
  ];
};

export const formatSizeComponents = (
  quantityUnits: number,
  quantityMeasure: string,
  fallback: string = ''
): string => {
  if (!quantityUnits || !quantityMeasure || quantityUnits === 0) {
    return fallback;
  }

  // @ts-ignore
  let quantity = quantityMeasureDescription(quantityMeasure.toUpperCase());
  if (quantity) {
    return `${quantityUnits.toString() || 0}${quantity}`;
  } else {
    return fallback;
  }
};
