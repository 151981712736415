import { NullableQuantityMeasureEnum, QuantityMeasureEnum, quantityMeasures } from 'models/Product';
import { CSSProperties, useState } from 'react';
import { Form, FormSelect } from 'react-bootstrap';

export type QuantityMeasureSelectProps =
  | {
      nullable: true;
      value?: NullableQuantityMeasureEnum;
      onChange?: (measure: NullableQuantityMeasureEnum) => void;
      disabled?: boolean;
      className?: string;
      style?: CSSProperties;
      allowedMeasures?: readonly QuantityMeasureEnum[];
    }
  | {
      nullable?: false;
      value?: QuantityMeasureEnum;
      onChange?: (measure: QuantityMeasureEnum) => void;
      disabled?: boolean;
      className?: string;
      style?: CSSProperties;
      allowedMeasures?: readonly QuantityMeasureEnum[];
    };

const measuresDisplayNameMap = {
  MILLIGRAMS: 'mg',
  GRAMS: 'g',
  KILOGRAMS: 'kg',
  CENTILITRES: 'cl',
  MILLILITRES: 'ml',
  LITRES: 'l',
} as const satisfies Record<QuantityMeasureEnum, string>;

const QuantityMeasureSelect: React.FC<QuantityMeasureSelectProps> = ({
  nullable,
  value,
  disabled = false,
  onChange,
  className,
  style,
  allowedMeasures = quantityMeasures,
}) => {
  const [internalValue, setInternalValue] = useState<NullableQuantityMeasureEnum>(
    value ?? 'KILOGRAMS'
  );

  const handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    const newValue = event.target.value as NullableQuantityMeasureEnum;
    setInternalValue(newValue);
    if (onChange) {
      onChange(newValue as QuantityMeasureEnum);
    }
  };

  const valueToUse = value ?? internalValue;

  return (
    <Form.Control
      as="select"
      type="number"
      id="quantityMeasure"
      value={valueToUse}
      onChange={handleChange}
      disabled={disabled}
      className={className}
      style={style}
    >
      {nullable && (
        <option key="" value="">
          Select...
        </option>
      )}
      {allowedMeasures.map((measure) => (
        <option key={measure} value={measure}>
          {measuresDisplayNameMap[measure]}
        </option>
      ))}
    </Form.Control>
  );
};

export default QuantityMeasureSelect;
