import React, { useEffect, useState } from 'react';
import NumberField from 'components/Field/NumberField';
import PercentField from 'components/Field/PercentField';
import PriceField from 'components/Field/PriceField';
import PopupProduct from '../Previews/PopupProduct';
import {
  Container,
  FieldContainer,
  FieldWrapper,
  Error,
  ShopPriceHeader,
  WeightBlock,
  InputsWrapper,
  WeightCheckWrapper,
  WeightLabel,
} from './PriceChangePopup.style';
import {
  devoToShopPrice,
  formatPrice,
  isPriceChangeAcceptable,
  roundTo,
  shopToDevoPrice,
} from 'helper/product';
import {
  GetShopProductMarginPayload,
  GetShopProductMarginResponse,
  NullableQuantityMeasureEnum,
  ProductUpdatePayload,
  ProductUpdateResponse,
  QuantityMeasureEnum,
  ShopProduct,
  UpdateShopProductResponse,
} from 'models/Product';
import Log from 'helper/monitoring';
import { ApolloQueryResult, useMutation, useQuery } from '@apollo/react-hooks';
import {
  GET_RECOMMENDED_PRICE_FOR_PRODUCT,
  PATCH_PRODUCT,
} from 'devoapi/products';
import useShopContext from 'contexts/shop/shop.context';
import Mixpanel from 'helper/Mixpanel';
import dynamic from 'next/dynamic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faExclamationTriangle,
  faWandMagicSparkles,
} from '@fortawesome/free-solid-svg-icons';
import parseError from 'helper/error';
import { calculateMedian } from 'helper/products/price';
import useUndoContext from 'contexts/undo/undo.context';
import DeliveryServicesDashboard from 'components/DeliveryServicesDashboard/DeliveryServicesDashboard';
import useShopProductMargin from 'hooks/useShopProductMargin';
import usePosProducts from 'hooks/usePosProducts';
import usePosProductProvider, {
  UpdatePosProductPayload,
  UpdatePosProductResponse,
} from 'endpoints/usePosProductProvider';
import { SetState } from 'helper/types/SetState';
import Check from 'components/Check/Check';
import QuantityMeasureSelect from 'components/QuantityMeasureSelect/QuantityMeasureSelect';
import { Props } from 'helper/types/Props';
import { UPDATE_POS_PRODUCT } from 'devoapi/pos_products';
const Popup = dynamic(() => import('components/Popup/Popup'));
const YesNo = dynamic(() => import('components/Popup/YesNo'));

type PriceChangeProps = {
  isOpen: boolean;
  closeModal: (shouldRefetch: boolean) => void;
  selectedItems: ShopProduct[];
  onPriceChange?: (newProduct: ProductUpdateResponse) => void;
  /**
   * @deprecated
   * Use PosPriceChangePopup component instead if you need all the features of the POS product.
   * Using this component is less optimized, may be unstable and slow
   */
  usePosModel?: boolean;
};

type PriceChangePopupProps = {
  recommended: number;
  shopPrice: number;
  setShopPrice: (val: number) => void;
  devoPrice: number;
  setDevoPrice: (val: number) => void;
  priceError: any;
  setPriceError: (val: any) => void;
  priceChangeOk: boolean;
  pricePerPack: number;
  setPricePerPack: (price: number) => void;
  itemsPerPack: number;
  setItemsPerPack: (itemsPerPack: number) => void;
  costPerItem: number;
  setCostPerItem: (costPerIten: number) => void;
  marginData: GetShopProductMarginResponse | null;
  quantityMeasurePricing: QuantityMeasureEnum | null;
  quantityUnitsPricing: number | null;
  setQuantityMeasurePricing: SetState<QuantityMeasureEnum>;
  setQuantityUnitsPricing: SetState<number>;
  refetchMargin: (
    variables?: Partial<GetShopProductMarginPayload>
  ) => Promise<ApolloQueryResult<GetShopProductMarginResponse>>;
  isSellPerMeasureProduct: boolean;
  toggleMeasureProduct: () => void;
};

//TECH-1153:
const disablePriceChangeOkChecks = true;

const PriceChange: React.FC<PriceChangeProps & PriceChangePopupProps> = ({
  isOpen,
  recommended,
  shopPrice,
  setShopPrice,
  devoPrice,
  setDevoPrice,
  selectedItems,
  priceError,
  setPriceError,
  priceChangeOk,
  pricePerPack,
  setPricePerPack,
  itemsPerPack,
  setItemsPerPack,
  costPerItem,
  marginData,
  refetchMargin,
  quantityMeasurePricing,
  quantityUnitsPricing,
  setQuantityMeasurePricing,
  setQuantityUnitsPricing,
  isSellPerMeasureProduct,
  toggleMeasureProduct,
}) => {
  const [profitOpen, setProfitOpen] = useState(false);
  const profitPerItem = shopPrice - costPerItem;

  const profitEnabled = costPerItem > 0;
  const profitPercentage =
    marginData?.response?.margin?.profitMargin ??
    (profitEnabled ? (shopPrice - costPerItem) / costPerItem : null);

  const markup = selectedItems[0]?.shopCategory?.devoMarkup || 0.15;
  const singleItem = selectedItems.length === 1 ? selectedItems[0] : null;

  useEffect(() => {
    if (singleItem == null) return;
    setShopPrice(singleItem.shopPrice);
    setDevoPrice(singleItem.devoPrice);
  }, [selectedItems]);

  // --------------------------------------------
  // Actions
  // --------------------------------------------

  const [handleRecommendedPrice, setHandleRecommendedPrice] = useState(false);

  const handleQuantityMeasurePricingChange = (
    measurePricing: NullableQuantityMeasureEnum
  ) => {
    if (!measurePricing) {
      return;
    }

    setQuantityMeasurePricing(measurePricing);
  };

  const handleQuantityUnitsPricingChange: Props<
    typeof PriceField
  >['onValueChange'] = (unitsPricing) => {
    setQuantityUnitsPricing(unitsPricing);
  };

  const onRecommendedClick = (e: any) => {
    setHandleRecommendedPrice(true);
    handleShopPriceChange(recommended);
  };

  const handleShopPriceChange = (newShopPrice: number) => {
    let newDevoPrice = shopToDevoPrice(shopPrice, markup);
    setShopPrice(newShopPrice);
    if (singleItem) {
      refetchMargin({
        uuid: singleItem.uuid,
        costPerProduct: costPerItem,
        shopPrice: newShopPrice,
      });
    }

    // setDevoPrice(newDevoPrice);
  };

  const handlePricePerPackChange = (newPrice: number) => {
    setPricePerPack(newPrice);
    if (singleItem) {
      refetchMargin({
        uuid: singleItem.uuid,
        costPerPack: newPrice,
        itemsPerPack: itemsPerPack,
        shopPrice: shopPrice,
      });
    }
  };

  const handleItemsPerPackChange = (newItems: number) => {
    setItemsPerPack(newItems);
    if (singleItem) {
      refetchMargin({
        uuid: singleItem.uuid,
        costPerPack: pricePerPack,
        itemsPerPack: newItems,
        shopPrice: shopPrice,
      });
    }
  };

  const handleProfitPercentChange = (newProfit: number) => {
    let newShopPrice = costPerItem + newProfit * costPerItem;
    handleShopPriceChange(newShopPrice);
  };

  // --------------------------------------------
  // Render
  // --------------------------------------------

  return (
    <Container>
      {!!singleItem ? (
        <PopupProduct product={singleItem} />
      ) : (
        <p>
          You are changing the price of <b>{selectedItems.length} items.</b>
        </p>
      )}

      {!profitOpen && (
        <>
          <ShopPriceHeader>Shop Price</ShopPriceHeader>
          <FieldContainer>
            <FieldWrapper>
              {/* <div style={{ flexGrow: 2 }} /> */}
              <PriceField
                value={shopPrice}
                onValueChange={setShopPrice}
                handleRecommendedPrice={handleRecommendedPrice}
                setHandleRecommendedPrice={setHandleRecommendedPrice}
              />
              {!profitOpen && recommended && shopPrice > 0 && (
                <a className="recommended price" onClick={onRecommendedClick}>
                  <span className={'action'}>
                    <FontAwesomeIcon icon={faWandMagicSparkles} />
                    &nbsp; Set recommended shop price {formatPrice(recommended)}
                  </span>
                  <br />
                  <span className={'average-price'}>
                    An average of other store's price for this product.
                  </span>
                </a>
              )}
            </FieldWrapper>
            <FieldWrapper>
              <FieldWrapper>
                {!isSellPerMeasureProduct && (
                  <>
                    <WeightBlock>
                      <WeightCheckWrapper>
                        <Check
                          isSelected={isSellPerMeasureProduct}
                          onClick={toggleMeasureProduct}
                        />
                        <WeightLabel>Weight Price</WeightLabel>
                      </WeightCheckWrapper>
                    </WeightBlock>
                  </>
                )}
                {isSellPerMeasureProduct && (
                  <>
                    <WeightBlock>
                      <WeightCheckWrapper>
                        <Check
                          isSelected={isSellPerMeasureProduct}
                          onClick={toggleMeasureProduct}
                        />
                        <WeightLabel>Weight Price</WeightLabel>
                      </WeightCheckWrapper>
                      <InputsWrapper>
                        <PriceField
                          id="price-weight-field"
                          value={
                            quantityUnitsPricing === null
                              ? 0
                              : quantityUnitsPricing
                          }
                          onValueChange={handleQuantityUnitsPricingChange}
                          removePound
                        />
                        <QuantityMeasureSelect
                          value={
                            quantityMeasurePricing === null
                              ? ''
                              : quantityMeasurePricing
                          }
                          onChange={handleQuantityMeasurePricingChange}
                          style={{
                            height: 50,
                            border: 'none',
                            background: 'var(--secondary-background-color)',
                            cursor: 'pointer',
                            width: 80,
                          }}
                          allowedMeasures={['GRAMS', 'KILOGRAMS']}
                          nullable
                        />
                      </InputsWrapper>
                    </WeightBlock>
                  </>
                )}
              </FieldWrapper>
              {/* <h3>Online Price</h3>
            <span>The amount the customer will see online.</span>
            <div style={{ flexGrow: 2 }} />
            <div
              style={{
                height: '50px',
                backgroundColor: '#fff',
                borderRadius: 'var(--devo-border-radius)',
                border: 'none',
                padding: '0.375rem 0.75rem',
                display: 'flex',
                alignItems: 'center',
                fontWeight: 600,
                userSelect: 'none',
              }}
            >
              {formatPrice(devoPrice)}
            </div> */}
            </FieldWrapper>
          </FieldContainer>
        </>
      )}
      {singleItem && (
        <div style={{ marginBottom: '20px', marginTop: '20px' }}>
          <DeliveryServicesDashboard
            devoProduct={singleItem}
            platformMargins={marginData?.response?.platformMargins}
          />
        </div>
      )}

      {!!singleItem && (
        <div className={'profit-container' + (profitOpen ? ' open' : '')}>
          <a className={'profit'} onClick={() => setProfitOpen(!profitOpen)}>
            <span className={'action'}>
              <FontAwesomeIcon icon={faChevronDown} />
              &nbsp; Show profit margin options
            </span>
          </a>

          {profitOpen && (
            <>
              <FieldContainer>
                <FieldWrapper>
                  <h3>Shop price</h3>
                  <span>Cost to your customers</span>
                  <div style={{ flexGrow: 2 }} />
                  <PriceField
                    value={shopPrice}
                    onValueChange={handleShopPriceChange}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <h3>Profit on Return (POR)</h3>
                  {!profitEnabled && (
                    <span>
                      <b>Enter a pack purchase price.</b>
                    </span>
                  )}
                  {profitEnabled && (
                    <span>{formatPrice(profitPerItem)} profit per item</span>
                  )}
                  <div style={{ flexGrow: 2 }} />
                  <PercentField
                    value={profitPercentage}
                    onValueChange={handleProfitPercentChange}
                    disabled={!profitEnabled}
                  />
                </FieldWrapper>
              </FieldContainer>
              <FieldContainer>
                <FieldWrapper>
                  <h3>Pack purchase price</h3>
                  <span>Cost for your store.</span>
                  <div style={{ flexGrow: 2 }} />
                  <PriceField
                    value={pricePerPack}
                    onValueChange={handlePricePerPackChange}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <h3>Items per pack</h3>
                  <span>{formatPrice(costPerItem)} cost per item</span>
                  <div style={{ flexGrow: 2 }} />
                  <NumberField
                    value={itemsPerPack}
                    onValueChange={handleItemsPerPackChange}
                    minimum={1}
                  />
                </FieldWrapper>
              </FieldContainer>
            </>
          )}
        </div>
      )}

      {!priceChangeOk && (
        <Error>
          <FontAwesomeIcon icon={faExclamationTriangle} size="sm" />
          &nbsp; New price cannot be more than double or less than the{' '}
          {singleItem ? 'recommended' : 'current'} price.
        </Error>
      )}

      {priceError &&
        priceError.message !== 'this.cache.batch is not a function' && (
          <Error>
            <FontAwesomeIcon icon={faExclamationTriangle} size="sm" />
            &nbsp;
            {parseError(priceError)}
          </Error>
        )}
    </Container>
  );
};

const PriceChangePopup: React.FC<PriceChangeProps> = (props) => {
  const [initialized, setInitialized] = useState(false);
  const { isOpen, closeModal, selectedItems, onPriceChange } = props;
  const { shopState } = useShopContext();
  const { undoDispatch } = useUndoContext();

  const [shopPrice, setShopPrice] = useState(0);
  const [devoPrice, setDevoPrice] = useState(0);
  const [pricePerPack, setPricePerPack] = useState(0);
  const [itemsPerPack, setItemsPerPack] = useState(1);
  const [costPerItem, setCostPerItem] = useState(
    () => pricePerPack / itemsPerPack
  );

  const onlySingleItem = props.selectedItems.length === 1;
  const singleItem = onlySingleItem ? props.selectedItems[0] : null;

  const { usePosModel = false } = props;

  const { product: posProduct } = usePosProductProvider({
    upc: singleItem?.product.upc,
    skip: !singleItem || !usePosModel,
  });

  const [quantityUnitsPricing, setQuantityUnitsPricing] = useState<
    number | null
  >(posProduct && posProduct.quantityUnitsPricing);
  const [quantityMeasurePricing, setQuantityMeasurePricing] =
    useState<QuantityMeasureEnum | null>(
      posProduct ? posProduct.quantityMeasurePricing : null
    );

  useEffect(() => {
    setQuantityUnitsPricing(posProduct && posProduct.quantityUnitsPricing);
  }, [posProduct]);

  useEffect(() => {
    setQuantityMeasurePricing(
      posProduct ? posProduct.quantityMeasurePricing : null
    );
  }, [posProduct]);

  const isSellPerMeasureProductDefault =
    !!onlySingleItem &&
    posProduct &&
    posProduct.isLive &&
    (!!posProduct.quantityMeasurePricing || !!posProduct.quantityUnitsPricing);

  const [isSellPerMeasureProduct, setIsSellPerMeasureProduct] = useState(
    isSellPerMeasureProductDefault
  );

  const toggleMeasureProduct = () => {
    setIsSellPerMeasureProduct((is) => {
      if (is) {
        setQuantityUnitsPricing(posProduct && posProduct.quantityUnitsPricing);
        setQuantityMeasurePricing(
          posProduct ? posProduct.quantityMeasurePricing : null
        );
      }
      return !is;
    });
  };

  const {
    data: marginData,
    saveProductMargin,
    refetch: refetchMargin,
  } = useShopProductMargin((singleItem ?? {}).uuid, {
    skip: !onlySingleItem,
    onMarginDataReceived: (data) => {
      setPricePerPack(data.response.productCost.packPrice);
      setItemsPerPack(data.response.productCost.packSize);
      setCostPerItem(data.response.productCost.cost);
      setShopPrice(data.response.shopPrice);
    },
  });

  useEffect(() => {
    if (!initialized) return setInitialized(true);

    Mixpanel.track(`products_bulk_price_${isOpen ? 'open' : 'close'}`, {
      number_of_items: props.selectedItems.length,
    });
    if (!isOpen) {
      setShopPrice(0);
      setDevoPrice(0);
    }
  }, [isOpen]);

  // --------------------------------------------
  // Recommended
  // --------------------------------------------

  const { data: recommendedData } = useQuery(
    GET_RECOMMENDED_PRICE_FOR_PRODUCT,
    {
      skip: !singleItem || !shopState,
      variables: {
        shopId: shopState?.uuid,
        productUpc: singleItem?.product?.upc,
      },
    }
  );

  const [recommended, setRecommended] = useState<number>(null);

  useEffect(() => {
    if (!recommendedData?.products || !singleItem) return;
    const availability = ['AVAILABLE', 'NOT_AVAILABLE'];
    const filtered = recommendedData.products?.filter((x) =>
      availability.includes(x.availabilityText)
    );
    const results = filtered.map((x) => x.shopPrice).sort();
    const median = roundTo(calculateMedian(results), 2);
    if (median > 0) setRecommended(median);
    Log.debug(`Fetched other shop's prices for product`, 'products', {
      upc: singleItem.product.upc,
      availability,
      all: recommendedData.products?.length,
      filtered: filtered.length,
      results: results?.join(', '),
      median,
    });
  }, [recommendedData]);

  // --------------------------------------------
  // API
  // --------------------------------------------

  const [remaining, setRemaining] = useState(0);

  const [priceError, setPriceError] = useState(null);
  const [_updatePrice, { loading }] = useMutation<
    UpdateShopProductResponse,
    ProductUpdatePayload
  >(PATCH_PRODUCT, {
    onCompleted: (res) => {
      Log.debug('PATCH_PRODUCT onCompleted', 'products', res);
      setRemaining((x) => {
        return x - 1;
      });
      if (remaining <= 1) closeModal(true);

      if (onPriceChange) {
        onPriceChange(res.product);
      }
    },
    onError: (err) => setPriceError(err),
  });

  const [updatePosProduct, { loading: statusUpdating, error: updateError }] =
    useMutation<UpdatePosProductResponse, UpdatePosProductPayload>(
      UPDATE_POS_PRODUCT
    );

  const updatePrice = (
    item: ShopProduct,
    shopPrice: number,
    devoPrice: number
  ) => {
    setPriceError(null);
    try {
      _updatePrice({
        variables: {
          shopId: shopState.uuid,
          productUpc: item.product.upc,
          input: { shopPrice },
        },
      });

      undoDispatch({
        type: 'ADD_CHANGE',
        payload: {
          upc: item.product.upc,
          change: { type: 'shopPrice', from: item.shopPrice, to: shopPrice },
        },
      });

      item.shopPrice = shopPrice;
      item.devoPrice = devoPrice;
    } catch (error) {
      Log.warning('Failed to update product', 'products', error);
    }
  };

  const handleConfirmPressed = () => {
    const thresh = 0.1;
    if (shopPrice >= thresh /* && devoPrice => thresh */) {
      Log.info('Changing price...', 'products', {
        shopPrice,
        devoPrice,
        items: selectedItems.length,
      });
      setRemaining(selectedItems.length);
      selectedItems.forEach((item) => {
        Mixpanel.track('products_bulk_shop_price_change', {
          number_of_items: selectedItems.length,
          upc: item.product.upc,
          name: item.product.name,
          from_shop_price: item.shopPrice,
          from_devo_price: item.devoPrice,
          to_shop_price: shopPrice,
          to_devo_price: devoPrice,
        });
        updatePrice(item, shopPrice, devoPrice);
        if (posProduct) {
          updatePosProduct({
            variables: {
              input: {
                ...posProduct,
                createdAt: new Date().toISOString(),
                shopPrice: shopPrice,
                quantityMeasurePricing: isSellPerMeasureProduct
                  ? quantityMeasurePricing
                  : null,
                quantityUnitsPricing: isSellPerMeasureProduct
                  ? quantityUnitsPricing
                  : null,
              },
            },
          });
        }
      });
    } else {
      setPriceError(`Price must be at least ${formatPrice(thresh)}.`);
    }
    saveProductMargin({
      packSize: itemsPerPack,
      packPrice: pricePerPack,
      cost: costPerItem,
    }).then(refetchMargin);
  };

  // --------------------------------------------
  // Render
  // --------------------------------------------

  var priceChangeOk =
    disablePriceChangeOkChecks ||
    selectedItems
      .map((item) => {
        return isPriceChangeAcceptable(item?.shopPrice, shopPrice);
      })
      .reduce((prev, curr) => prev && curr, true);

  const invalidMeasurePricings =
    isSellPerMeasureProduct &&
    (!quantityMeasurePricing || !quantityUnitsPricing);

  if (!!singleItem && recommended) {
    priceChangeOk =
      disablePriceChangeOkChecks ||
      isPriceChangeAcceptable(recommended, shopPrice);
  }

  const _recommended = singleItem ? recommended : null;

  return (
    <Popup isOpen={isOpen} closeModal={closeModal}>
      {selectedItems.length > 0 && (
        <YesNo
          title="Change Price"
          yesLoading={loading}
          yesDisabled={!priceChangeOk || invalidMeasurePricings}
          noText="Cancel"
          yesText="Confirm"
          yesAction={handleConfirmPressed}
          closeOnYes={false}
          closeModal={closeModal}
        >
          <PriceChange
            {...props}
            recommended={_recommended}
            shopPrice={shopPrice}
            setShopPrice={setShopPrice}
            devoPrice={devoPrice}
            setDevoPrice={setDevoPrice}
            priceError={priceError}
            setPriceError={setPriceError}
            priceChangeOk={priceChangeOk}
            itemsPerPack={itemsPerPack}
            setItemsPerPack={setItemsPerPack}
            pricePerPack={pricePerPack}
            setPricePerPack={setPricePerPack}
            costPerItem={costPerItem}
            setCostPerItem={setCostPerItem}
            marginData={marginData}
            refetchMargin={refetchMargin}
            quantityMeasurePricing={quantityMeasurePricing}
            quantityUnitsPricing={quantityUnitsPricing}
            setQuantityMeasurePricing={setQuantityMeasurePricing}
            setQuantityUnitsPricing={setQuantityUnitsPricing}
            isSellPerMeasureProduct={isSellPerMeasureProduct}
            toggleMeasureProduct={toggleMeasureProduct}
          />
        </YesNo>
      )}
    </Popup>
  );
};

export default PriceChangePopup;
