import React, { useEffect } from 'react';
import useAuth from 'contexts/auth/auth.context';
import { getCurrentUser, getRefreshExpiryTime } from 'helper/user';
import { redirectToLogin } from 'devoapi/authorization';

export function ProtectedRoute(PageComponent, props) {
  return () => {
    const { authState } = useAuth();
    let user = authState?.user;

    useEffect(() => {
      const expiry = getRefreshExpiryTime();
      if (!user || expiry < 0) redirectToLogin();
    }, [authState])
    
    if (user && getCurrentUser()) {
      return <PageComponent {...props} />;
    } else {
      return <></>;
    }
  }
};

export default ProtectedRoute;