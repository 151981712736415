import { useQuery } from '@apollo/client';
import { GET_WHOLESALE_SHOP } from './getWholesaleShop';
import {
  GetWholesaleShopPayload,
  GetWholesaleShopResponse,
} from './getWholesaleShop.entities';

export type UseWholesaleShopNamedParams = Partial<{
  skip: boolean;
}>;

export const useWholesaleShop = (
  shopUuid: string,
  { skip = false }: UseWholesaleShopNamedParams = {}
) => {
  const { data: wholesaleShopData, ...restQueryProps } = useQuery<
    GetWholesaleShopResponse,
    GetWholesaleShopPayload
  >(GET_WHOLESALE_SHOP, {
    skip: skip,
    variables: {
      shopUuid: shopUuid,
    },
  });

  return [wholesaleShopData, { ...restQueryProps }] as const;
};
