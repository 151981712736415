import styled from 'styled-components';

export const PopupWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;

  .price-marked {
    display: flex;
    flex-flow: row nowrap;
    gap: 8px;
    align-items: center;
  }
`;

export const PopupGroupRow = styled.div`
  display: flex;
  flex-direction: column;
  &:not(:first-child) {
    border-top: 1px solid var(--separator-color);
  }
  width: 100%;
`;

export const PopupRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 10px 40px;

  span {
    font-size: 18px;
    font-weight: 400;
  }
`;

export const SubcategoryRow = styled.div`
  &:not(:first-child) {
    border-top: 1px solid var(--separator-color);
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px 40px 8px 60px;
  height: 48px;

  span {
    font-size: 16px;
    font-weight: 400;
  }
`;

export const PopupInputGroup = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  height: 100%;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  height: 100%;
`;

export const PopupInput = styled.input`
  background-color: var(--secondary-background-color);
  border-radius: var(--devo-border-radius);
  border: none;
  width: 132px;
  height: 32px;
  padding: 0 8px;

  &:disabled {
    color: var(--gray-2);
  }

  &:focus {
    background-color: var(--secondary-background-color);
  }
  &::placeholder {
    color: var(--secondary-label-color);
  }
  :-ms-input-placeholder {
    color: var(--secondary-label-color);
  } /* Internet Explorer 10-11 */
  ::-ms-input-placeholder {
    color: var(--secondary-label-color);
  } /* Microsoft Edge */
`;
