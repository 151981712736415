import {
  faCheck,
  faMinus,
  IconDefinition,
  faCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

type CheckStyleProps = {
  color: string;
  $disableMarginOnMobile?: boolean;
  style?: React.CSSProperties;
};

const Container = styled.a<CheckStyleProps>`
  background-color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 2px ${(props) => props.color} solid;
  box-shadow: var(--devo-shadow);
  display: flex;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;

  &.selected {
    background-color: ${(props) => props.color};
  }

  @media screen and (max-width: 768px) {
    &.check {
      margin: ${(props) =>
        props.$disableMarginOnMobile ? 0 : '20px 5px auto 5px'};
    }

    &.mobile-permissions {
      margin: 0;
      margin-right: 4px;
    }
  }

  svg {
    align-self: center;
    color: #fff;
    width: 10px;
    height: 10px;
  }
`;

type CheckProps = {
  as?: any;
  isSelected: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  className?: string;
  icon?: 'CHECK' | 'DASH' | 'CIRCLE';
  color?: string;
  style?: React.CSSProperties;
} & Pick<CheckStyleProps, '$disableMarginOnMobile'>;

const Check: React.FC<CheckProps> = ({
  as = 'a',
  isSelected,
  onClick,
  className,
  icon = 'CHECK',
  color = 'var(--devo-red)',
  $disableMarginOnMobile,
  style,
}) => {
  const iconForStyle = (): IconDefinition => {
    switch (icon) {
      case 'CHECK':
        return faCheck;
      case 'DASH':
        return faMinus;
      case 'CIRCLE':
        return faCircle;
    }
  };

  return (
    <Container
      as={as}
      onClick={onClick}
      className={`check ${isSelected ? 'selected' : ''} ${className || ''}`}
      color={color}
      style={style}
      $disableMarginOnMobile
    >
      {isSelected && <FontAwesomeIcon icon={iconForStyle()} />}
    </Container>
  );
};

export default Check;
