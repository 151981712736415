import React from 'react';
import Popup from 'reactjs-popup';

export type DevoPopupProps = {
  isOpen: boolean;
  closeModal: any;
  position?: 'top left' | 'top right' | 'bottom right' | 'bottom left' | 'right center' | 'left center' | 'top center' | 'bottom center' | 'center center';
  allowClose?: boolean;
  children?: any;
}

export const DevoPopup: React.FC<DevoPopupProps> = ({
  isOpen,
  closeModal,
  children,
  position = 'center center',
  allowClose = true,
}) => {

  return (
    <Popup
      open={isOpen === true}
      onClose={closeModal}
      closeOnDocumentClick={allowClose}
      closeOnEscape={allowClose}
      modal
      lockScroll
      position={position}
    >
      {children}
    </Popup>
  )

};

export default DevoPopup;
