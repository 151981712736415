import React, { useEffect } from 'react';
import { DeviceType } from 'helper/useDeviceType';
import protectedRoute from 'helper/protectedRoute';
import { SEO } from 'components/SEO/SEO';
import Mixpanel from 'helper/Mixpanel';
import { WholesaleCartProvider } from 'contexts/cart/useWholesaleCart';
import AllWholesaleProducts from 'containers/Wholesale/AllWholesaleProducts';
import { useProtectWithPermission } from 'endpoints/permissions/useProtectWithPermission';

type PageProps = {
  deviceType: DeviceType;
};

const Page: React.FC<PageProps> = ({ deviceType }) => {
  useProtectWithPermission('partner_centre_wholesale');
  useEffect(() => Mixpanel.track('wholesale'), []);

  return (
    <>
      <SEO title="Wholesale | Devo Partner Centre" />
      <WholesaleCartProvider>
        <AllWholesaleProducts />
      </WholesaleCartProvider>
    </>
  );
};

export default protectedRoute(Page);
