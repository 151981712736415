import { gql } from '@apollo/client'

export const GET_STOCK_SUMMARY = gql`
  query getStockSummary($shopUuid: string, $productUpcs: string, $from: number, $to: number) {
    stockSummary(shopUuid: $shopUuid, productUpcs: $productUpcs, from: $from, to: $to)
    @rest(type: "StockEntry", 
          method: "GET", 
          path: "/stock/summary?shopUuid={args.shopUuid}&productUpcs={args.productUpcs}&from={args.from}&to={args.to}", 
          endpoint: "ss") {
      shopUuid
      stockProduct
      from
      to
    }
  }
`;

export const GET_STOCK_SUMMARY_SIMPLE = gql`
  query getStockSummary($shopUuid: string, $productUpcs: string) {
    stockSummary(shopUuid: $shopUuid, productUpcs: $productUpcs)
    @rest(type: "StockEntry", 
          method: "GET", 
          path: "/stock/summary?shopUuid={args.shopUuid}&productUpcs={args.productUpcs}", 
          endpoint: "ss") {
      shopUuid
      stockProduct
      from
      to
    }
  }
`;

export const GET_STOCK_ENTRIES = gql`
  query getStockEntries($shopUuid: string, $productUpcs: string, $from: number, $to: number) {
    stockEntries(shopUuid: $shopUuid, productUpcs: $productUpcs, from: $from, to: $to)
    @rest(type: "StockSummary", method: "GET", path: "/stock/entries?shopUuid={args.shopUuid}&productUpcs={args.productUpcs}&from={args.from}&to={args.to}", endpoint: "ss") {
      uuid
      authorUuid
      productUpc
      type
      details
      amount
      balance
      shopUuid
      updatedAt
    }
  }
`;

export const INSERT_STOCK_ENTRY = gql`
  mutation insertStockEntry($shopUuid: string, $productUpc: Int, $amount: number, $type: string, $details: string) {
    product(shopUuid: $shopUuid, productUpc: $productUpc, amount: $amount, type: $type, details: $details, input: {})
    @rest(method: "POST", 
          path: "/stock?shopUuid={args.shopUuid}&productUpc={args.productUpc}&amount={args.amount}&type={args.type}&details={args.details}",
          endpoint: "ss") {
      body
    }
  }
`;