import styled from 'styled-components';

const NAVBAR_HEIGHT = '57.6px';

export const Container = styled.div`
  .navbar {
    background-color: #fff !important;
    border-bottom: 2px #eee solid;
    z-index: 1000;
    height: ${NAVBAR_HEIGHT};
  }
  

  .brand {
    margin-left: 5px;
    
    img {
      height: 35px;
    }
  }
`;

export const Details = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-end;

  p {
    color: var(--primary-label-color);
    margin: 0;
  }

  span {
    font-size: 14px;
    color: var(--secondary-label-color);
  }
`;

export const Controls = styled.div`
  display: flex;
  margin-left: auto;
  gap: 12px;

  & > a {
    color: #000;

    :hover, :focus {
      color: initial
    }
  }
`

export const Button = styled.button`
  padding: 0;
  margin: 0;
  background: none;
  border: none;
`

export const SearchWrapper = styled.div`
  display: flex;
  gap: 12px;
  margin-right: 12px;
  align-items: center;
  flex-grow: 1;
`

export const SearchInput = styled.input`
  padding: 0;
  margin: 0;
  border: none;
  border-bottom: 2px solid #eee;
  flex-grow: 1;

  &:focus, &:active {
    outline: none;
  }
`

export const ProductList = styled.div`
  position: fixed;
  top: ${NAVBAR_HEIGHT};
  left: 0;
  width: 100vw;
  height: calc(100vh - ${NAVBAR_HEIGHT});
  padding-left: 20px;
  padding-top: 20px;
  background-color: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
`

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
`