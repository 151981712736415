import usePermissions from 'endpoints/permissions/usePermissions';
import React, { useEffect, useState } from 'react';
import {
  Wrapper,
  Group,
  Button,
  Spacer,
  DropdownButton,
  Search,
  SearchBar,
} from './Toolbar.style';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSearch,
  faCheckSquare,
  faFilter,
  faUndo,
  faCircle,
  faPencilAlt,
  faTrash,
  faPrint,
  faPlus,
  faArrowLeft,
  faThLarge,
  faPoll,
  faTag,
} from '@fortawesome/free-solid-svg-icons';
import Loader from 'components/Loader/Loader';
import { useWindowSizeDeviceType } from 'helper/useWindowSize';
import FilterProps, { FilterPropsSortingBy } from 'models/FilterProps';
import useUndoContext from 'contexts/undo/undo.context';
import { availabilityColor, availabilityDescription } from 'helper/product';
import { AvailabilityEnum } from 'models/Product';
import { ADD_PRODUCTS_ROUTE, LIVE_PRODUCTS_ROUTE } from 'constants/navigation';
import Router from 'next/router';
import { IconButton } from 'styles/Global';
import { ProductRowMode } from 'components/ProductRow/ProductRow';
import {
  createBarcodeGenerateUrl,
  createBulkPromotionsReportUrl,
} from 'helper/barcodes';
import ResultsPerPageEnum, {
  ResultsPerPage,
  mapResultsPerPageToString,
} from 'helper/products/resultsPerPage';

export type ProductsToolbarProps = {
  mode: ProductRowMode;
  loading: boolean;
  inSelection: boolean;
  handleClearSelection: (e: any) => void;
  selectedItems: number[];
  handleStatusChange: (e: any) => void;
  offset: number;
  totalResults: number;
  resultsPerPage: ResultsPerPage;
  handleResultsPerPageChange: (e: any) => void;
  handleFilterDrawerOpen: () => void;
  onSearchChange: (query: string) => void;
  onSortChange: (sortBy: FilterPropsSortingBy, isAscending: boolean) => void;
  onPriceChange: () => void;
  filter: FilterProps;
  showCategoryMenu?: boolean;
  returnToCategoryMenu?: () => void;
  global?: boolean;
  hideGlobalSearch?: () => void;
  showStatusChangePopup: () => void;
};

const ProductsToolbar: React.FC<ProductsToolbarProps> = ({
  mode,
  loading: _loading,
  inSelection,
  handleClearSelection,
  selectedItems,
  handleStatusChange,
  offset,
  totalResults,
  resultsPerPage,
  handleResultsPerPageChange,
  handleFilterDrawerOpen,
  onSearchChange,
  onSortChange,
  onPriceChange,
  filter,
  showCategoryMenu = false,
  returnToCategoryMenu,
  global,
  hideGlobalSearch,
  showStatusChangePopup,
}) => {
  const { isPosEnabled, permissions } = usePermissions();

  const { desktop, smallTablet, tablet } = useWindowSizeDeviceType();
  const [searchTimeout, setSearchTimeout] = useState<any>(0);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    setSearchText(decodeURIComponent(filter.keyword));
  }, [filter]);

  const handleSearchFieldChange = (e: any) => {
    const text: string = e.target.value?.toString() || '';
    setSearchText(text);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        onSearchChange(text);
      }, 500)
    );
  };

  // --------------------------------------------
  // Actions
  // --------------------------------------------

  const { undoState, undoDispatch } = useUndoContext();
  const handleUndo = () => undoDispatch({ type: 'UNDO' });
  const shouldShowUndo = false; // undoState.changes.length > 0;

  const handleAddClick = (e) => Router.push(ADD_PRODUCTS_ROUTE);
  const handleInventoryClick = (e) => Router.push(LIVE_PRODUCTS_ROUTE);
  const handlePrintLabelsClick = (e) => {
    if (hideGlobalSearch) hideGlobalSearch();
    Router.push(createBarcodeGenerateUrl(selectedItems));
  };
  const onBulkPromotionsClick = (e) => {
    if (hideGlobalSearch) hideGlobalSearch();
    Router.push(createBulkPromotionsReportUrl(selectedItems));
  };

  // --------------------------------------------
  // Render
  // --------------------------------------------

  const loading = _loading || undoState.inProgress;

  const resultsDescription = ((): string => {
    if (!loading && totalResults > 0 && !showCategoryMenu) {
      const firstResultIndex = offset + 1;
      const lastResultIndex = Math.min(offset + resultsPerPage, totalResults);

      if (lastResultIndex === totalResults && firstResultIndex === 1) {
        return `Showing ${totalResults > 0 && `${totalResults} `}results`;
      } else {
        return `Showing ${firstResultIndex} - ${lastResultIndex} of ${totalResults} results`;
      }
    } else {
      return null;
    }
  })();

  const getSortDescription = (
    sortBy: FilterPropsSortingBy,
    isAscending: boolean
  ): string => {
    if (!sortBy) return 'Sort';
    switch (sortBy) {
      case 'SORT_BY_NAME':
        return isAscending ? 'Name A-Z' : 'Name Z-A';
      case 'SORT_BY_SHOP_PRICE':
        return isAscending ? 'Price low to high' : 'Price high to low';
      case 'SORT_BY_QUANTITY_UNITS':
        return isAscending ? 'Size low to high' : 'Size high to low';
      case 'SORT_BY_POPULARITY':
        return isAscending
          ? 'Popularity low to high'
          : 'Popularity high to low';
    }
  };

  type SortItem = { sortingBy: FilterPropsSortingBy; isAscending: boolean };
  const SORT_ITEMS: SortItem[] = [
    { sortingBy: 'SORT_BY_NAME', isAscending: true },
    { sortingBy: 'SORT_BY_NAME', isAscending: false },
    { sortingBy: 'SORT_BY_SHOP_PRICE', isAscending: true },
    { sortingBy: 'SORT_BY_SHOP_PRICE', isAscending: false },
    { sortingBy: 'SORT_BY_QUANTITY_UNITS', isAscending: true },
    { sortingBy: 'SORT_BY_QUANTITY_UNITS', isAscending: false },
    { sortingBy: 'SORT_BY_POPULARITY', isAscending: true },
    { sortingBy: 'SORT_BY_POPULARITY', isAscending: false },
  ];

  const statuses: AvailabilityEnum[] = ['AVAILABLE', 'NOT_AVAILABLE'];

  if (inSelection) {
    return (
      <Wrapper global={global}>
        <Group>
          <Button onClick={handleClearSelection}>
            <FontAwesomeIcon className="red" icon={faCheckSquare} />
            &nbsp;&nbsp;
            {selectedItems.length} selected
          </Button>
        </Group>

        {!smallTablet && permissions.write_shop_product && (
          <DropdownButton>
            <Dropdown>
              <Dropdown.Toggle>Status</Dropdown.Toggle>
              <Dropdown.Menu>
                {statuses.map((option, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => handleStatusChange(option)}
                  >
                    <FontAwesomeIcon
                      icon={faCircle}
                      color={availabilityColor(option)}
                    />
                    &nbsp;&nbsp;
                    {availabilityDescription(option)}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </DropdownButton>
        )}

        {smallTablet && permissions.write_shop_product && (
          <Group>
            <Button onClick={showStatusChangePopup}>Status</Button>
          </Group>
        )}

        {permissions.write_shop_product && (
          <Group>
            <Button onClick={onPriceChange}>Price</Button>
          </Group>
        )}

        {permissions.read_shop_promotion && isPosEnabled && (
          <Group>
            <Button onClick={onBulkPromotionsClick}>Promotions</Button>
          </Group>
        )}

        {isPosEnabled && (
          <Group>
            <Button onClick={handlePrintLabelsClick}>Labels</Button>
          </Group>
        )}

        {!smallTablet && <Spacer />}

        {permissions.write_shop_product && (
          <Group>
            <Button onClick={() => handleStatusChange('REMOVED')}>
              Delete
            </Button>
          </Group>
        )}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {mode === 'LIVE' && !!returnToCategoryMenu && !showCategoryMenu && (
        <Group>
          <IconButton icon={faThLarge} onClick={returnToCategoryMenu} />
        </Group>
      )}

      {mode === 'ADD' && (
        <Group>
          <IconButton icon={faArrowLeft} onClick={handleInventoryClick}>
            Back
          </IconButton>
        </Group>
      )}

      {!showCategoryMenu && (
        <DropdownButton>
          <Dropdown className="sort">
            <Dropdown.Toggle>
              <FontAwesomeIcon
                icon={faSort}
                color="var(--secondary-label-color)"
              />
              &nbsp;
              {getSortDescription(filter.sortingBy, filter.isAscending)}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {SORT_ITEMS.map((item, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => onSortChange(item.sortingBy, item.isAscending)}
                >
                  {getSortDescription(item.sortingBy, item.isAscending)}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </DropdownButton>
      )}

      <Group>
        <IconButton
          icon={faFilter}
          onClick={handleFilterDrawerOpen}
          className={filter.filterEmpty() ? '' : 'filter'}
        >
          Filter
        </IconButton>
      </Group>

      {mode === 'LIVE' && !smallTablet && (
        <Group>
          <IconButton icon={faPlus} onClick={handleAddClick}>
            Add
          </IconButton>
        </Group>
      )}

      {shouldShowUndo && (
        <Group>
          <Button
            onClick={handleUndo}
            className={filter.filterEmpty() ? '' : 'filter'}
          >
            <FontAwesomeIcon icon={faUndo} />
            &nbsp; Undo
          </Button>
        </Group>
      )}

      {loading && <Loader style={{ margin: '20px auto' }} />}
      {desktop && resultsDescription && (
        <span className="results noselect">{resultsDescription}</span>
      )}

      <Spacer />

      {desktop && !showCategoryMenu && (
        <DropdownButton className="resultsPerPage">
          <Dropdown>
            <Dropdown.Toggle>
              {mapResultsPerPageToString(resultsPerPage)}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {Object.values(ResultsPerPageEnum)
                .sort((a, b) => a - b)
                .map((perPage) => (
                  <Dropdown.Item
                    key={perPage}
                    onClick={() => handleResultsPerPageChange(perPage)}
                  >
                    {mapResultsPerPageToString(perPage)}
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>
          <span className="noselect">per page</span>
        </DropdownButton>
      )}

      {(tablet || desktop) && !smallTablet && (
        <Search>
          <FontAwesomeIcon icon={faSearch} />
          <SearchBar
            placeholder="Search"
            value={searchText}
            onChange={handleSearchFieldChange}
          />
        </Search>
      )}
    </Wrapper>
  );
};

export default ProductsToolbar;
