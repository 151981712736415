import styled from 'styled-components';

export const Page = styled.div``;

export const Container = styled.div`
  .loader {
    margin: calc((100vh - 190px) / 2) auto;
  }
`;

export const Toolbar = {
  Padding: styled.div`
    padding: 20px 20px 0 20px;
    background-color: var(--secondary-background-color);
    position: sticky;
    z-index: 2;
    top: 0;
    left: 0;

    @media (max-width: 1024px) {
      padding: 0;
      margin: 0 -10px;
    }

    @media screen and (max-width: 768px) {
      top: 56px;
    }
  `,
  Box: styled.div`
    display: flex;
    align-items: flex-start;
    flex-flow: column nowrap;
    gap: 12px;
    background-color: var(--background-color);
    padding: 20px;
    border-radius: var(--devo-border-radius);
    box-shadow: var(--devo-shadow);

    @media screen and (max-width: 767px) {
      padding-bottom: 6px;
      box-shadow: none;
      border-radius: 0;
    }

    .dropdown {
      display: inline-block;
    }

    .dropdown-menu {
      border: var(--devo-border);
      border-radius: var(--devo-border-radius);
      box-shadow: var(--devo-shadow);
    }

    & > *:not(:last-child) {
      margin-right: 12px;
    }
  `,
  Container: styled.div`
    width: 100%;
    display: flex;
    gap: 12px;
    align-items: center;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }
  `,
  TopRow: styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
  `,
  BrandBox: styled.div`
    display: flex;
    flex-direction: column;
  `,
  MiddleRow: styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
  `,
};

export const SpaceBetweenToolbar = styled.div`
  margin-top: 20px;
`