import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DeliveryServiceCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 20%;
  position: relative;
`;

export const NoInternetPlaque = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  background-color: #666;
  font-size: 8px;
  text-transform: uppercase;
  color: white;
  border-radius: 4px;
  padding: 2px 4px;
`;

export const ServiceLogoWrapper = styled.div`
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 4px;
`;

export const ServiceProductMarkup = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: var(--secondary-label-color);
  margin: 0;
  line-height: 1;
`;

export const ServiceProfitWrapper = styled.div`
  display: flex;
  gap: 4px;
  padding: 4px;

  font-size: 11px;
  font-weight: 600;

  &.positive {
    background-color: #dcfce7;
    color: var(--devo-green);
    border: 0.5px solid var(--devo-green);
    border-radius: 4px;
  }

  &.negative {
    background-color: #fee2e2;
    color: var(--devo-red);
    border: 0.5px solid var(--devo-red);
    border-radius: 4px;
  }
`;

export const ServicePrice = styled.p`
  font-size: 18px;
  font-weight: 600;
  color: inherit;
  span {
    font-size: 14px;
  }
`;
