import React, { useReducer } from 'react';
import { AuthContext } from './auth.context';
import { setSessionTokens, getCurrentUser, logout } from 'helper/user';
const isBrowser = typeof window !== 'undefined';
const INITIAL_STATE = {
  form: 'SIGNIN',
  user: getCurrentUser()
};

type ActionType = {
  type:
    'SET_FORM' |
    'AUTH_SUCCESS' |
    'LOGOUT' ;
  payload?: any;
};

function reducer(state: any, action: ActionType): any {
  switch (action.type) {
    case 'SET_FORM':
      return {
        ...state,
        form: action.payload
      }
    case 'AUTH_SUCCESS':
      setSessionTokens(action.payload);
      return {
        ...state,
        user: getCurrentUser()
      };
    case 'LOGOUT':
      logout();
      return {
        ...state,
        user: null
      }
    default:
      return state;
  }
};

export const AuthProvider: React.FunctionComponent<any> = ({ children }) => {
  const [authState, authDispatch] = useReducer(reducer, INITIAL_STATE);
  return (
    <AuthContext.Provider value={{ authState, authDispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
