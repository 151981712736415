import React from 'react';
import Head from 'next/head';
import { isLocalEnvironment } from 'helper/environment';
import { IS_BETA_RELEASE } from '../../constants/constants'

type SeoProps = {
  title: string;
  description?: string;
  canonical?: string;
  noindex?: boolean;
  noindexnofollow?: boolean;
};

export const SEO: React.FC<SeoProps> = ({
  title,
  description,
  canonical,
  noindex = true,
  noindexnofollow = true,
}) => {
  let _title = title;
  if (process.env.NEXT_PUBLIC_DEVO_ENV !== 'production') {
    _title = `[${process.env.DEVO_ENV.toUpperCase()}] ${_title}`;
    if (IS_BETA_RELEASE) _title = `[BETA] ${title}`;
    if (isLocalEnvironment())  _title = `[LOCAL] ${_title}`;
  }

  const hostname = typeof window !== 'undefined' ? window.location.hostname : 'https://partner.devo.co.uk';
  const url = `${hostname}${canonical}`;
  return (
    <Head>
      <title>{_title}</title>
      <meta property="og:type" content="website" />
      <meta name="og:title" property="og:title" content={title} />
      {description &&
        <>
          <meta name="description" content={description} />
          <meta
            name="og:description"
            property="og:description"
            content={description}
          />
        </>
      }
      <meta property="og:site_name" content="Devo" />
      <meta property="og:url" content={url} />
      {canonical && <link rel="canonical" href={url} />}
      {noindex && <meta name="robots" content="noindex" />}
      {noindexnofollow && <meta name="robots" content="noindex,nofollow" />}
    </Head>
  );
}
