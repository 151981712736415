
export const normalizeProductMarkup = (markup: number) => {
  if (Number.isNaN(markup)) {
    return '';
  }

  const threshold = 1e-5;
  const percentageMarkup = markup * 100;
  const roundedMarkup = Math.round(percentageMarkup);

  if (roundedMarkup - percentageMarkup < threshold) {
    return roundedMarkup;
  }

  return percentageMarkup;
};
