import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-right: 2px #eee solid;
  padding: 25px 0 12px 0;
  overflow-y: scroll;

  .subheader {
    padding: 16px 20px 12px;
    border-top: 1px var(--separator-color) solid;
    margin-top: 12px;
    
    button { background: none; border: none; }
  }

  div.subheader {
    display: flex;
    align-items: center;

    a { cursor: pointer; }
    a > .svg-inline--fa { color: var(--secondary-label-color); }
    a:hover > .svg-inline--fa { color: var(--devo-blue); }
  }

  span.subheader, .subheader > span {
    font-size: 12px;
    font-weight: 600;
    text-transform: capitalize;
    color: var(--secondary-label-color);
  }
`;

export const SidebarSpacer = styled.div`
  border-bottom: 1px var(--separator-color) solid;
  margin: 12px 0;
  
  @media (min-width: 769px) {
    min-height: 50px;
    flex-grow: 2;
  }
`;

export const MobileHeader = styled.div`
  padding: 0 16px;

  .brand {
    margin-bottom: 30px;
  }
  h1 {
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 30px;
  }
`;

const NavLinkHPadding = 20;
const NavLinkSubmenuHPadding = 46;
const NavLinkBorderWidth = 3;
export const NavLink = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px ${NavLinkHPadding}px;
  font-size: 14px;
  color: var(--primary-label-color);

  &:not([href]) {
    color: var(--primary-label-color);
    font-weight: 400;

    :hover {
      color: initial;
    }
    &.active:hover {
      color: var(--devo-red);
    }
  }



  &:hover {
    cursor: pointer;
    text-decoration: none !important;
    border-left: ${NavLinkBorderWidth}px var(--devo-dark-red) solid;
    color: var(--primary-label-color);
  }

  &.active {
    color: var(--devo-red);
    font-weight: 600;
    border-left: ${NavLinkBorderWidth}px var(--devo-red) solid;
    svg { color: var(--devo-red); }
    &.submenu { color: var(--devo-red) !important; }
  }



  &:not(.submenu) {
    &:hover { background-color: var(--selected-background-color); }
    &:hover, &.active { padding-left: calc(${NavLinkHPadding}px - ${NavLinkBorderWidth}px); }
  }

  &.submenu {
    color: var(--secondary-label-color);
    background-color: var(--gray-6);
    padding-left: ${NavLinkSubmenuHPadding}px;

    &:hover {
      &:not(:active) { color: var(--devo-dark-red); }
      background-color: var(--gray-5); 
    }

    &:hover, &.active { padding-left: calc(${NavLinkSubmenuHPadding}px - ${NavLinkBorderWidth}px); }
    &:hover.active {
      color: var(--devo-red) !important;
    }
  }



  @media (max-width: 768px) {
    margin: 0;
    width: 100%;
    border-radius: 0;
  }
`;

export const Expand = styled.button`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
  border: 1px var(--separator-color) solid;
  border-radius: 999px;
  box-shadow: var(--devo-shadow);
  z-index: 100000;
  transition: all 0.5s;
  position: absolute;
  top: 30px;
  left: 197px;
  
  &.hidden {
    top: 40px;
    svg {
      transform: rotate(180deg);
    }
  }
  
  &:hover {
    background-color: var(--devo-blue);
    box-shadow: var(--devo-heavy-shadow);
    svg { color: white; }
  }
`;