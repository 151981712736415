import { useMutation, useQuery } from '@apollo/client';
import { ApolloError } from 'apollo-client';
import {
  GET_SHOP_PRODUCT_MARGIN,
  SAVE_SHOP_PRODUCT_MARGIN,
} from 'devoapi/products';
import {
  GetShopProductMarginPayload,
  GetShopProductMarginResponse,
  SaveShopProductMarginPayload,
  SaveShopProductMarginResponse,
} from 'models/Product';

const useShopProductMargin = (
  productUuid: string,
  namedParams: {
    skip?: boolean;
    onMarginDataReceived?: (data: GetShopProductMarginResponse) => void;
    onMarginDataError?: (error: ApolloError) => void;
  } = {}
) => {
  const { data, loading, error, refetch } = useQuery<
    GetShopProductMarginResponse,
    GetShopProductMarginPayload
  >(GET_SHOP_PRODUCT_MARGIN, {
    skip: namedParams.skip,
    fetchPolicy: 'no-cache',
    variables: {
      uuid: productUuid,
    },
    onCompleted: (data) => {
      if (data.response) {
        namedParams.onMarginDataReceived?.call(null, data);
      } else {
        namedParams.onMarginDataError?.(error);
      }
    },
  });

  const [mutateProductMargin, { loading: saving, error: saveError }] =
    useMutation<SaveShopProductMarginResponse, SaveShopProductMarginPayload>(
      SAVE_SHOP_PRODUCT_MARGIN
    );

  const saveProductMargin = ({
    uuid,
    cost,
    packPrice,
    packSize,
  }: Omit<SaveShopProductMarginPayload['input'], 'uuid'> & { uuid?: string }) =>
    mutateProductMargin({
      variables: {
        input: {
          uuid: uuid ?? productUuid,
          cost,
          packPrice,
          packSize,
        },
      },
    }).then((response) => response.data?.response);

  return {
    data,
    loading,
    error,
    saveError,
    saveProductMargin,
    saving,
    refetch,
  };
};

export default useShopProductMargin;
