const ResultsPerPageEnum = {
  veryFew: 10,
  few: 25,
  normal: 50,
  many: 100,
  all: 20000,
} as const;

export type ResultsPerPage =
  (typeof ResultsPerPageEnum)[keyof typeof ResultsPerPageEnum];

export const mapResultsPerPageToString = (perPage: ResultsPerPage) =>
  perPage === ResultsPerPageEnum.all ? 'Show all' : perPage.toString();

export const resultsPerPageRenderLimit = ResultsPerPageEnum.many;

export default ResultsPerPageEnum;