import React, { useReducer } from 'react';
import DepotShop from '../../models/DepotShop'
import { ActionType, DepotShopContext } from './depot-shop.context'

const INITIAL_STATE: DepotShop = {
  posEnabled: false,
  uuid: "",
  wholesalerShopCodes: {},
  wholesalerShopCodesString: "",
};

function reducer(state: any, action: ActionType): any {
  switch (action.type) {
    case 'SET_DEPOT_SHOP':
      let body = action?.payload || {};
      return { ...body, posEnabled: !!action?.payload };
    default:
      return state;
  }
};

export const DepotShopProvider: React.FunctionComponent<any> = ({ children }) => {
  const [depotShopState, depotShopDispatch] = useReducer(reducer, INITIAL_STATE);
  return (
    <DepotShopContext.Provider value={{ depotShopState, depotShopDispatch }}>
      {children}
    </DepotShopContext.Provider>
  );
};

export default DepotShopProvider
