import { UserModel, AuthTokenModel } from 'models/User';
import Log from './monitoring';
import * as Sentry from '@sentry/react';
import Mixpanel, { identifyToMixpanel, setMixpanelSuperProperty, setMixpanelUserProperty } from './Mixpanel';
import storage from 'helper/storage'

const AUTH_TOKEN_KEY = 'Devo-Auth-Token';
const REFRESH_TOKEN_KEY = 'Devo-Refresh-Token';

export const setSessionTokens = (tokens: AuthTokenModel) => {
  setAuthToken(tokens.authToken);
  setRefreshToken(tokens.refreshToken);

  const mixpanel = window['mixpanel'];
  const user = getCurrentUser();
  if (!mixpanel) return Log.warning('Mixpanel client not found when setting login tokens', 'mixpanel', null);

  Log.info('Identify and set super properties', 'mixpanel', { uuid: user?.uuid });
  if (user) {
    setMixpanelSuperProperty('$first_name', user.firstName);
    setMixpanelSuperProperty('$last_name', user.lastName);
    setMixpanelSuperProperty('$email', user.email);
    setMixpanelSuperProperty('$phone', user.phoneNumber);
    identifyToMixpanel(user.uuid);
  }
}

export const getAuthToken = () => {
  let authToken = storage.getItem(AUTH_TOKEN_KEY);
  if (!authToken || authToken.length < 1) { return null };
  return authToken;
}

export const setAuthToken = (authToken: string) => {
  storage.setItem(AUTH_TOKEN_KEY, authToken);
  updateSentryUser(getCurrentUser());
}

export const setRefreshToken = (refreshToken: string) =>
  storage.setItem(REFRESH_TOKEN_KEY, refreshToken);


export const getRefreshToken = () => {
  let refreshToken = storage.getItem(REFRESH_TOKEN_KEY);
  if (!refreshToken || refreshToken.length < 1) { return null };
  return refreshToken;
}

export const getCurrentUserUuid = (enableLogout?: boolean) => {
  if (getRefreshExpiryTime() < 0) {
    if (enableLogout === true) {
      Mixpanel.track('logout', { action: 'expired_token' });
      logout();
    }
    return null;
  }

  const user = getAuthData();
  if (user) {
    return user['user_uuid'];
  } else {
    return null;
  }
}

export const getCurrentUser = (disableLogout?: boolean) => {
  if (getRefreshExpiryTime() < 0) {
    if (disableLogout === true) {
      Mixpanel.track('logout', { action: 'expired_token' });
      logout();
    }
    return null;
  }

  const data = getAuthData();
  if (data) {
    return JSON.parse(data.user);
  } else {
    return null;
  }
}

export const logout = (callback: any = null) => {
  setMixpanelUserProperty({
    current_basket_items: '',
    current_basket_value: '',
    current_promotion: '',
    current_shop: '',
  }, false);
  identifyToMixpanel();

  setTimeout(() => {
    storage.removeItem(AUTH_TOKEN_KEY);
    storage.removeItem(REFRESH_TOKEN_KEY);
    updateSentryUser(null);
    window['mixpanel']?.reset();
    if (callback) callback();
  }, 500);
}

export const getAuthExpiryTime = () => getExpiryTime(AUTH_TOKEN_KEY);
export const getRefreshExpiryTime = () => getExpiryTime(REFRESH_TOKEN_KEY);

// HELPERS

export const separateName = (fullName: string) => {
  const components = fullName.split(' ').filter((val) => val.length > 0);
  if (components.length == 0) {
    return ['', ''];
  } else if (components.length > 1) {
    return [components.shift(), components.join(' ')];
  } else {
    const a = [components.shift(), ''];
    return a;
  }
};

export const formatNames = (firstName: string, lastName: string): string => {
  if (firstName && lastName) {
    if (firstName.length > 0 && lastName.length > 0) {
      return firstName + ' ' + lastName;
    } else {
      return firstName + lastName;
    }
  } else if (firstName) {
    return firstName;
  } else if (lastName) {
    return lastName;
  } else {
    return '';
  }
}

export const formatInitials = (firstName: string, lastName: string): string => {
  if (firstName && lastName) {
    if (firstName.length > 0 && lastName.length > 0) {
      return firstName[0] + lastName[0];
    } else {
      return firstName[0] + lastName[0];
    }
  } else if (firstName) {
    return firstName[0];
  } else if (lastName) {
    return lastName[0];
  } else {
    return '';
  }
}

export const formattedAddress = (a): string => {
  var str = '';
  if (a.addressLine1) str = `${str}${a.addressLine1}, `;
  if (a.addressLine2) str = `${str}${a.addressLine2}, `;
  if (a.city) str = `${str}${a.city}, `;
  if (a.postcode) str = `${str}${a.postcode}, `;

  if (str.substring(str.length-2, str.length) == ', ') {
    return str.slice(0, -2);
  }
  return str;
}

export const validateEmail = (email?: string): boolean => {
  if (!email) {
    return false;
  }

  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
}

//------------------------------------------------------------------------------
// PRIVATE UTILITIES

const updateSentryUser = (user: UserModel) => {
  if (!user) {
    Log.debug('Clearing Sentry user', 'auth', null);
    Sentry.setUser(null);
    return;
  }

  const sentry = {
    id: user.uuid,
    username: `${user.firstName} ${user.lastName}`,
    email: user.email,
  };
  Log.debug('Updating Sentry user', 'auth', sentry);
  Sentry.setUser(sentry);
};

const getExpiryTime = (key: string) => {
  let data = getData(key);
  if (!data?.exp) return null;
  let now = new Date().getTime() / 1000;
  return data.exp - now;
}

const getAuthData = () => getData(AUTH_TOKEN_KEY);
const getRefreshData = () => getData(REFRESH_TOKEN_KEY);
const getData = (key: string) => {
  let data = storage.getItem(key);
  if (!data || data.length < 1) return null;
  return parseJwt(data);
}

const parseJwt = (token: string) => {
  if (!token) return null;
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
};

export const parseUserDataFromJwt = (token: string) => {
  const data = parseJwt(token);
  return data ? JSON.parse(data.user) : null;
};
