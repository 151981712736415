import DEVO from 'image/orders/devo.png';
import DELIVEROO from 'image/orders/deliveroo.png';
import UBER_EATS from 'image/orders/uber_eats.png';
import JUST_EAT from 'image/orders/just_eat.png';
import { ExternalProduct } from 'models/DeliveryServices';
import { PlatformType } from 'models/Order';
import { platformTypeDisplayNameMap } from 'containers/ManageDeliveryServices/ManageDeliveryServices.data';
import { ShopProduct } from 'models/Product';

export interface DeliveryServiceDashboardRenderObject {
  id: string;
  thumbnailUrl: string;
  productMarkup: number | null;
  onlinePrice: number | null;
}

export const deliveryServicesDashboardObjects: DeliveryServiceDashboardRenderObject[] =
  [
    {
      id: '0',
      thumbnailUrl: DEVO,
      productMarkup: 20,
      onlinePrice: 2.4,
    },
    {
      id: '1',
      thumbnailUrl: DELIVEROO,
      productMarkup: 20,
      onlinePrice: 2.4,
    },
    {
      id: '2',
      thumbnailUrl: UBER_EATS,
      productMarkup: 20,
      onlinePrice: 2.4,
    },
    {
      id: '3',
      thumbnailUrl: JUST_EAT,
      productMarkup: null,
      onlinePrice: null,
    },
  ];

export interface ExtendedExternalProduct extends ExternalProduct {
  integrated: boolean;
}

export const extendExternalProducts = (
  services: ExternalProduct[],
  devoProduct: ShopProduct
): ExtendedExternalProduct[] =>
  Object.keys(platformTypeDisplayNameMap).map((platformType: PlatformType) => {
    if (platformType === 'DEVO') {
      return {
        integrated: true,
        availability: devoProduct.availability,
        lastUpdated: null,
        markup: devoProduct.shopCategory.devoMarkup,
        platform: platformType,
        price: devoProduct.devoPrice,
        productUpc: devoProduct.product.upc,
        shopUuid: devoProduct.shopUuid,
        synced: null,
      };
    }

    const targetExternalProduct = services.find(
      (service) => service.platform === platformType
    );

    if (targetExternalProduct) {
      return {
        ...targetExternalProduct,
        integrated: true,
      };
    }

    return {
      integrated: false,
      availability: null,
      lastUpdated: null,
      markup: null,
      platform: platformType,
      price: null,
      productUpc: null,
      shopUuid: null,
      synced: null,
    };
  });
