import { gql } from '@apollo/client';

export const GET_PLATFORM_CONFIGURATIONS = gql`
  query getPlatformConfigurations($shopUuid: String) {
    response(shopUuid: $shopUuid)
      @rest(
        type: "Configuration"
        method: "GET"
        path: "/external/configuration?shopUuid={args.shopUuid}"
        endpoint: "os"
      ) {
      autoAcceptOrders
      enabled
      online
      platform
      shopUuid
      storeId
    }
  }
`;

export const MODIFY_PLATFORM_CONFIGURATIONS = gql`
  mutation modifyPlatformConfigurations(
    $shopUuid: String
    $platform: String
    $online: Boolean
    $autoAccept: boolean
  ) {
    response(
      shopUuid: $shopUuid
      platform: $platform
      online: $online
      autoAccept: $autoAccept
      input: {}
    )
      @rest(
        type: "Configuration"
        method: "POST"
        path: "/external/configuration?shopUuid={args.shopUuid}&platform={args.platform}&online={args.online}&autoAccept={args.autoAccept}"
        endpoint: "os"
      ) {
      autoAcceptOrders
      enabled
      online
      platform
      shopUuid
      storeId
    }
  }
`;

export const GET_EXTERNAL_CATEGORIES = gql`
  query getExternalCategories($platform: String, $shopUuid: String) {
    categories(platform: $platform, shopUuid: $shopUuid)
      @rest(
        type: "ExternalCategory"
        method: "GET"
        path: "/external/categories?platform={args.platform}&shopUuid={args.shopUuid}"
        endpoint: "os"
      ) {
      categoryId
      markup
      platform
      shopUuid
    }
  }
`;

export const SYNC_EXTERNAL_CATEGORIES = gql`
  mutation syncExternalCategories($externalCategories: any) {
    sync(externalCategories: $externalCategories)
      @rest(
        type: "SyncExternalCategory"
        method: "POST"
        path: "/external/categories/sync"
        bodyKey: "externalCategories"
        endpoint: "os"
      ) {
      body
    }
  }
`;

export const GET_PLATFORM_PRODUCT_LISTING = gql`
  query getPlatformProductListing($productUpc: number, $shopUuid: string) {
    response(productUpc: $productUpc, shopUuid: $shopUuid)
      @rest(
        type: "ExternalProduct"
        method: "GET"
        path: "/external/product?productUpc={args.productUpc}&shopUuid={args.shopUuid}"
        endpoint: "os"
      ) {
      availability
      lastUpdated
      markup
      platform
      price
      productUpc
      shopUuid
      synced
    }
  }
`;
