import { flattenArray } from 'helper/array';
import { Category } from 'models/Category'

class CategoryTreeNode<CategoryLike extends Category = Category> {

  public data: CategoryLike;
  public parent: CategoryTreeNode<CategoryLike>;
  public children: CategoryTreeNode<CategoryLike>[];

  constructor (data: CategoryLike) {
    this.data = data;
    this.parent = null;
    this.children = [];
  }

  static comparer<T extends Category>(a: CategoryTreeNode<T>, b: CategoryTreeNode<T>) {
    return a.data.id < b.data.id ? 0 : 1;
  }

  static toTree<T extends Category>(data: T[]) {
    if (!data) return null;
    let nodeById: { [id: number]: CategoryTreeNode<T> } = {};
    let i = 1, l = data.length, node;

    const root = data.find(c => c.id === 1);
    nodeById[1] = new CategoryTreeNode(root);

    // Make CategoryTreeNode objects for each item
    for (i = 1; i < l; i++) {
      nodeById[data[i].id] = new CategoryTreeNode(data[i]);
    }

    // Link all CategoryTreeNode objects
    for (i = 1; i < l; i++) {
      node = nodeById[data[i].id];
      node.parent = nodeById[node.data.parentId];
      node.parent.children.push(node);
    }

    return nodeById[1].sortRecursive();
  }

  sortRecursive = () => {
    this.children.sort(CategoryTreeNode.comparer);
    for (let i = 0, l = this.children.length; i < l; i++) {
      this.children[i].sortRecursive();
    }
    return this;
  }

  getAllAncestors = () => {
    if (this.parent) {
      return this.parent.getAllAncestors().concat([this]);
    } else {
      return [this];
    }
  }

  getAllDescendants = (): CategoryTreeNode<CategoryLike>[] => {
    if (this.children.length > 0) {
      const ids = flattenArray(this.children.map(x => x.getAllDescendants()));
      return ids.concat([this]);
    } else {
      return [this];
    }
  };

  getNodeById = (id: number, defaultValue: CategoryLike = null, topLevel: boolean = true): CategoryTreeNode<CategoryLike> => {
    if (!this?.data?.id) return null;
    if (this.data.id === id) return this;
    if (this.data.id === -1 && !!defaultValue) return this.getDefaultValueNode(defaultValue);
    if (id == -1 && !!defaultValue) return this.getDefaultValueNode(defaultValue);

    let n = this.children.length || 0;
    if (n === 0) return null;

    for (let i = 0; i < n; i++) {
      let node = this.children[i];
      let childFound = node.getNodeById(id, null, false);
      if (!!childFound) return childFound;
    }

    if (!!defaultValue && topLevel) return this.getDefaultValueNode(defaultValue);
  }

  getDefaultValueNode = (defaultValue: CategoryLike): CategoryTreeNode<CategoryLike> => {
    let n = new CategoryTreeNode(defaultValue);
    n.parent = this;
    n.children = [this];
    return n;
  }

}

export default CategoryTreeNode;