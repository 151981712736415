import DEVO from 'image/orders/devo.png';
import DELIVEROO from 'image/orders/deliveroo.png';
import UBER_EATS from 'image/orders/uber_eats.png';
import JUST_EAT from 'image/orders/just_eat.png';
import { PlatformType } from 'models/Order';
import { PlatformConfiguration } from 'models/DeliveryServices';

export const platformTypeThumbnailsMap: Record<PlatformType, string> = {
  DEVO,
  DELIVEROO,
  JUST_EAT,
  UBER_EATS,
};

export const platformTypeDisplayNameMap: Record<PlatformType, string> = {
  DEVO: 'Devo',
  DELIVEROO: 'Deliveroo',
  JUST_EAT: 'Just Eat',
  UBER_EATS: 'Uber Eats',
};

export interface ExtendedPlatformConfiguration extends PlatformConfiguration {
  integrated: boolean;
}

export const extendPlatformConfigurations = (
  services: PlatformConfiguration[]
): ExtendedPlatformConfiguration[] =>
  Object.keys(platformTypeDisplayNameMap).map((platformType: PlatformType) => {
    const targetPlatformService = services.find(
      (service) => service.platform === platformType
    );

    if (targetPlatformService) {
      return {
        ...targetPlatformService,
        integrated: true,
      };
    }

    return {
      platform: platformType,
      enabled: false,
      autoAcceptOrders: false,
      online: false,
      shopUuid: '',
      storeId: '',
      integrated: false,
    };
  });
