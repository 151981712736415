import styled from 'styled-components';
import { Ctd } from '../../styles/Global'

export const Row = styled.tr`
  td {
    vertical-align: middle !important;
    // border-top: 1px solid var(--separator-color) !important;
    padding: .4rem .5rem !important;
  }

  .clickable {
    cursor: pointer;
  }

  &.selected {
    td {
      background-color: var(--selected-background-color);
    }
  }

  td .loader {
    margin: auto;
  }
  
  .form-control:disabled, .form-control[readonly] {
    background-color: transparent;
  }

  .badge {
    cursor: default;
  }

  .check {
    margin: 0 auto;
  }
`;

export const Image = styled.td`
  text-align: center;
  img {
    height: 40px;
    width: 40px;
    object-fit: contain;
  }
`;

export const Category = styled.td`
  font-size: 14px;

  a, span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  
  tr > & > a {
    cursor: pointer;
    color: var(--devo-blue);

    &:hover {
      color: var(--devo-dark-blue);
    }
  }
`;

export const Name = styled.td`
  width: 50%;

  p {
    margin-bottom: 0;
    min-width: 200px;
    font-size: 15px;
  }

  .barcode {
    font-size: 13px;
    color: #757575;
  }
`;

export const Size = styled.td`
  font-size: 14px;
  min-width: 80px;
`;

export const Price = styled.td`
  text-align: center;
  white-space: nowrap;
  
  button {
    background-color: transparent;
    border: none;
    display: flex;
    gap: 8px;
    align-items: center;
    margin: 0 auto;
  }
  
  &.pm {
    button { cursor: default; }
    p { color: var(--secondary-label-color); }
  }
  
  svg.edit-icon {
    color: var(--gray-4);
  }
  
  button:hover {
    svg.edit-icon { 
      color: var(--devo-blue);
    }
  }
`;

export const StockTd = styled(Ctd)`
  a, a:not(href), .text {
    display: inline-block;
    color: var(--primary-label-color);
    padding: 8px 12px;
    border-radius: var(--devo-border-radius);
    
    &:hover {
      cursor: pointer;
      text-decoration: none;
      background-color: var(--secondary-background-color);
      color: var(--devo-blue);
    }
  }
`;

export const StatusTd = styled.td`
  text-align: center;
`;

export const AddButton = styled.td`
  width: 160px;
  text-align: center;

  button.add {
    padding: 6px 10px;
    margin: 5px auto;
    border-radius: 999px;
    font-size: 13px;
  }

  svg.error {
    margin: 15px auto;
    color: var(--devo-yellow);
  }
`;