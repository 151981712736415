
const getClient = (): Storage => {
  if (typeof window !== 'undefined')
    return window.localStorage;
  return null;
}

const getItem = (key: string) => {
  return getClient()?.getItem(key);
}

const setItem = (key: string, value: any) => {
  getClient()?.setItem(key, value);
}

const removeItem = (key: string) => {
  getClient()?.removeItem(key);
}

const storage = {
  getItem,
  setItem,
  removeItem
}

export default storage