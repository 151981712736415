import {
  CellWrapper,
  ProductMarkupInput,
  TableCell,
  TableRow,
  VERY_TINY_BREAKPOINT_PX,
} from 'components/DeliveryServicesTable/DeliveryServicesTable.style';
import {
  ChangeEvent,
  HTMLInputTypeAttribute,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Switch } from 'styles/Form';
import OptionsMenu from 'containers/ManageDeliveryServices/OptionsMenu';
import CustomProductMarkupPopup from './CustomProductMarkupPopup';
import {
  GetExternalCategoriesPayload,
  ModifyPlatformConfigurationsPayload,
  PlatformConfiguration,
  SyncExternalCategoriesPayload,
  get_external_categories_response,
  modify_platform_configuration_response,
} from 'models/DeliveryServices';
import { platformTypeThumbnailsMap } from 'containers/ManageDeliveryServices/ManageDeliveryServices.data';
import { useMutation, useQuery } from '@apollo/client';
import {
  GET_EXTERNAL_CATEGORIES,
  MODIFY_PLATFORM_CONFIGURATIONS,
  SYNC_EXTERNAL_CATEGORIES,
} from 'devoapi/delivery_services';
import useShopContext from 'contexts/shop/shop.context';
import { useWindowSizeDeviceType } from 'helper/useWindowSize';
import { normalizeProductMarkup } from 'helper/deliveryServices';

export interface TableRowProps {
  service: PlatformConfiguration;
}

export const ROOT_CATEGORY_ID = 1;
const SYNC_TIMEOUT_MS = 550;

const ServiceTableRow: React.FC<TableRowProps> = ({ service }) => {
  const { shopState } = useShopContext();
  const [online, setOffline] = useState(service.online);
  const [enabled, setEnabled] = useState(service.autoAcceptOrders);
  const [productMarkup, setProductMarkup] = useState(0);
  const [isCustomProductMarkup, setIsCustomProductMarkup] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const { smallTablet, width } = useWindowSizeDeviceType();
  const veryTiny = width < VERY_TINY_BREAKPOINT_PX;

  const [modifyPlatformConfigurations] = useMutation<
    modify_platform_configuration_response,
    ModifyPlatformConfigurationsPayload
  >(MODIFY_PLATFORM_CONFIGURATIONS);

  const {
    data: externalData,
    loading: externalLoading,
    error: externalError,
    refetch: externalRefetch,
  } = useQuery<get_external_categories_response, GetExternalCategoriesPayload>(
    GET_EXTERNAL_CATEGORIES,
    {
      skip: !shopState?.uuid,
      variables: {
        platform: service.platform,
        shopUuid: shopState?.uuid,
      },
    }
  );

  useLayoutEffect(() => {
    if (!externalData) {
      return;
    }

    const rootCategory = externalData.categories.find(
      (category) => category.categoryId === ROOT_CATEGORY_ID
    );

    const anySubcategory = externalData.categories.find(
      (category) => category.categoryId > ROOT_CATEGORY_ID
    );

    setProductMarkup(rootCategory?.markup);
    setIsCustomProductMarkup(
      externalData.categories
        .filter((category) => category.categoryId !== ROOT_CATEGORY_ID)
        .some((category) => category.markup !== anySubcategory?.markup)
    );
  }, [externalData]);

  const [syncRootCategory] = useMutation<void, SyncExternalCategoriesPayload>(
    SYNC_EXTERNAL_CATEGORIES
  );

  const onProductMarkupChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (isCustomProductMarkup) {
      return;
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    const enteredNumber = parseInt(e.target.value);
    if (
      (Number.isNaN(enteredNumber) && e.target.value !== '') ||
      enteredNumber < 1 ||
      enteredNumber > 99
    ) {
      return;
    }

    const enteredNumberInUnitFractions = enteredNumber / 100;

    setProductMarkup(enteredNumberInUnitFractions);
    timeoutRef.current = setTimeout(() => {
      syncRootCategory({
        variables: {
          externalCategories: externalData.categories.map((category) => ({
            ...category,
            markup: enteredNumberInUnitFractions || 0.01,
          })),
        },
        onCompleted: () => externalRefetch(),
      });
    }, SYNC_TIMEOUT_MS);
  };

  const getInputValue = () => {
    if (externalLoading) {
      return 'Loading...';
    }

    if (isCustomProductMarkup) {
      return 'Custom';
    }

    return normalizeProductMarkup(productMarkup);
  };

  const getInputType = (): HTMLInputTypeAttribute => {
    if (externalLoading || isCustomProductMarkup) {
      return 'text';
    }

    return 'number';
  };

  return (
    <TableRow>
      <CustomProductMarkupPopup
        service={service}
        isOpen={isPopupOpen}
        closeModal={() => setIsPopupOpen(false)}
        onSave={() => {
          externalRefetch();
        }}
        onCancel={() => {}}
        plainExternalCategories={externalData?.categories}
      />
      <TableCell>
        <img
          src={platformTypeThumbnailsMap[service.platform]}
          alt={service.platform}
          width={veryTiny ? 32 : 40}
          height={veryTiny ? 32 : 40}
        />
      </TableCell>
      <TableCell>
        <CellWrapper $width={veryTiny ? 40 : smallTablet ? 48 : 60}>
          {!smallTablet && <span>{online ? 'Online' : 'Offline'}</span>}
          <Switch
            isOn={online}
            onClick={(e) => {
              setOffline(e.target.checked);
              modifyPlatformConfigurations({
                variables: {
                  shopUuid: shopState.uuid,
                  platform: service.platform,
                  autoAccept: enabled,
                  online: e.target.checked,
                },
                onError: () => {
                  setOffline(!e.target.checked);
                },
              });
            }}
          />
        </CellWrapper>
      </TableCell>
      <TableCell>
        <CellWrapper $width={veryTiny ? 48 : smallTablet ? 60 : 80}>
          {!smallTablet && <span>{enabled ? 'Enabled' : 'Disabled'}</span>}
          <Switch
            isOn={enabled}
            onClick={(e) => {
              setEnabled(e.target.checked);
              modifyPlatformConfigurations({
                variables: {
                  shopUuid: shopState.uuid,
                  platform: service.platform,
                  autoAccept: e.target.checked,
                  online: online,
                },
                onError: () => {
                  setEnabled(!e.target.checked);
                },
              });
            }}
          />
        </CellWrapper>
      </TableCell>
      <TableCell>
        <CellWrapper $width={veryTiny ? 16 : smallTablet ? 24 : 30}>
          <ProductMarkupInput
            value={getInputValue()}
            type={getInputType()}
            onChange={onProductMarkupChange}
          />
          <span>%</span>
          <OptionsMenu onCustomClick={() => setIsPopupOpen(true)} />
        </CellWrapper>
      </TableCell>
    </TableRow>
  );
};

export default ServiceTableRow;
