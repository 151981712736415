import React from 'react'
import { randomIntInRange } from 'helper/misc/number'
import { PriceMarkOverlay } from 'components/ProductRow/ProductRow'
import DraftBadge from 'components/Products/DraftBadge'
import { formatPriceNumber } from 'helper/product'
import { formatSizeComponents, formatUpc } from 'helper/products'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Block,
  BlocksContainer,
  ContentContainer,
  IconContainer,
  ImageContainer,
  ItemContainer,
  TopContainer
} from 'components/MobileDataItem/MobileDataItem.style'
import { Badge } from 'react-bootstrap'
import { Caption, Label, Placeholder, Spacer } from 'styles/Global'
import PlaceholderImage from 'image/product-placeholder.png';

const BlockValue = (block: MobileDataItemBlockProps) => (
  <>
    {block.value != null && <Label>{block.value}</Label>}
    {block.price != null && <Label><span>£</span>{formatPriceNumber(block.price)}</Label>}
    {block.num != null && <Label>{block.num.toString()}</Label>}
    {block.size != null && <Label>{formatSizeComponents(block.size.units, block.size.measure, "--")}</Label>}
    {block.draft === true && <Label><DraftBadge /></Label>}
  </>
)

type MobileDataItemProps = {
  image?: any;
  icon?: IconDefinition;
  iconColor?: string;
  showPlaceholder?: boolean;
  title?: string;
  showPriceMark?: boolean;
  showDraft?: boolean;
  subtitle?: string;
  subtitleUpc?: number;
  contentRight?: React.ReactElement;
  blocks?: MobileDataItemBlockProps[];
  indentBlocks?: boolean;
  scrollBlocks?: boolean;
  onClick?: () => void;
}

export type MobileDataItemBlockProps = {
  show?: boolean;
  badge?: string;
  value?: string;
  price?: number;
  num?: number;
  size?: {
    units: number;
    measure: string;
  };
  draft?: boolean;
  label: string;
}

export const MobileDataItemLoading: React.FC = () => (
  <ItemContainer className={"mobile-data-item"}>
    <TopContainer className={"top"}>
      <ContentContainer>
        <div className={"content"}>
          <Placeholder hMargin={0} vMargin={4} width={randomIntInRange(125, 175)} height={16} />
          <Placeholder hMargin={0} vMargin={4} width={randomIntInRange(50, 100)} height={12} />
        </div>
      </ContentContainer>
    </TopContainer>
  </ItemContainer>
)

const MobileDataItem: React.FC<MobileDataItemProps> = ({
  image = null,
  icon = null,
  iconColor = "var(--secondary-label-color)",
  showPlaceholder = false,
  title,
  showPriceMark = false,
  showDraft = false,
  subtitle = null,
  subtitleUpc = null,
  contentRight,
  blocks = [],
  indentBlocks = true,
  scrollBlocks = true,
  onClick,
}) => {

  let shouldIndent: boolean = !!(icon || image || showPlaceholder) && indentBlocks;
  let _blocks = blocks.filter(x => x.show || x.show == null);

  return (
    <ItemContainer className={"mobile-data-item"} as={!!onClick ? "a" : "div"} onClick={onClick}>
      <TopContainer className={"top"}>
        {image != null && icon == null && <ImageContainer>
          <img src={image} alt={title} />
        </ImageContainer>}

        {icon != null && image == null && <IconContainer>
          <FontAwesomeIcon icon={icon} color={iconColor} size={"lg"} />
        </IconContainer>}

        {image == null && icon == null && showPlaceholder && <ImageContainer>
          <img src={PlaceholderImage} alt={title} />
        </ImageContainer>}

        <ContentContainer>
          <div className={"content"}>
            {title && <Label>{title}&nbsp;{showPriceMark === true && <PriceMarkOverlay />}</Label>}
            {subtitle != null && <Caption>
              {subtitle}
              {showDraft && <>&nbsp;<DraftBadge /></>}
            </Caption>}
            {subtitleUpc != null && <Caption>
              {formatUpc(subtitleUpc.toString())}
              {showDraft && <>&nbsp;<DraftBadge /></>}
            </Caption>}
          </div>
          <Spacer />
          {contentRight || <></>}
        </ContentContainer>

      </TopContainer>

      {_blocks.length > 0 && <BlocksContainer indentBlocks={shouldIndent} scroll={scrollBlocks}>
        {_blocks.map((block, index) => (
          <Block key={index}>
            {block.badge != null ? (
              <>
                <Badge bg={block.badge}>
                  <BlockValue {...block} />
                </Badge>
                <br />
              </>
            ) : (
              <BlockValue {...block} />
            )}
            <Caption>{block.label.toLowerCase()}</Caption>
          </Block>
        ))}
      </BlocksContainer>}
    </ItemContainer>
  )
}

export default MobileDataItem