import React from 'react';
import styled from 'styled-components';

const Container = styled.a`
  background-color: #fff;
  width: 20px;
  height: 20px;
  border-radius: calc(100% / 2);
  border: 2px var(--devo-red) solid;
  box-shadow: var(--devo-shadow);
  display: flex;
  justify-content: center;
  cursor: pointer;

  &.selected {
  }
`;

const Fill = styled.div`
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  margin: 2px;
  border-radius: calc(100% / 2);
  background-color: var(--devo-red);
`;

type RadioProps = {
  isSelected: boolean;
  onClick: any;
};

const Radio: React.FC<RadioProps> = ({ isSelected, onClick }) => {

  return (
    <Container className={isSelected ? 'selected' : ''} onClick={onClick}>
      {isSelected && <Fill /> }
    </Container>
  );
};

export default Radio;