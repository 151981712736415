import gql from 'graphql-tag';
import { getAuthToken } from 'helper/user';
import { POS_PRODUCT_PROPERTIES, PosProduct } from 'models/PosProduct';

export const GET_POS_PRODUCT = gql`
  query getPosProduct($shopId: string, $upc: number) {
    product(shopId: $shopId, upc: $upc) 
    @rest(type: "PosProduct", method: "GET", path: "/pos/product?shopUuid={args.shopId}&productUpc={args.upc}", endpoint: "ss") {
      ${POS_PRODUCT_PROPERTIES}
    }
  }
`;

export const UPDATE_POS_PRODUCT = gql`
  mutation updatePosProduct($input: any) {
    product(input: $input)
    @rest(method: "POST", path: "/pos/product", endpoint: "ss") {
      ${POS_PRODUCT_PROPERTIES}
    }
  }
`;

export const getPosProduct = (
  shopUuid: string,
  upc: number
): Promise<PosProduct> => {
  return new Promise((resolve, reject) => {
    var url = new URL(`${process.env.API_SHOP_SERVICE}/pos/product`);
    const params: any = { shopUuid, productUpc: upc.toString() };
    url.search = new URLSearchParams(params).toString();

    fetch(url.toString(), {
      headers: {
        Accept: 'application/json',
        'Devo-Api-Key': process.env.DEVO_API_KEY,
        'Devo-Auth-Token': getAuthToken(),
      },
    })
      .then((res) => res.json())
      .then(resolve)
      .catch((err) => reject(err));
  });
};

export type GetBulkPosProductsResponse = {
  results: PosProduct[];
  total: number;
};

export type GetBulkPosProductsPayload = {
  shopUuid: string;
  input: number[];
};

export const getPosProducts = (
  shopUuid: string,
  upcs: number[]
): Promise<GetBulkPosProductsResponse> => {
  return new Promise((resolve, reject) => {
    let url = new URL(`${process.env.API_SHOP_SERVICE}/pos/products`);
    const params: any = { shopUuid };
    url.search = new URLSearchParams(params).toString();

    fetch(url.toString(), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Devo-Api-Key': process.env.DEVO_API_KEY,
        'Devo-Auth-Token': getAuthToken(),
      },
      body: JSON.stringify(upcs),
    })
      .then((res) => res.json())
      .then(resolve)
      .catch((err) => reject(err));
  });
};

export const GET_POS_PRODUCTS = gql`
  mutation getPosProducts($shopUuid: string, $input: any) {
    products(shopUuid: $shopUuid, input: $input)
      @rest(
        type: "PosProduct"
        method: "POST"
        path: "/pos/products?shopUuid={args.shopUuid}"
        endpoint: "ss"
      ) {
      results
      total
    }
  }
`;

export const GET_POS_INVENTORY_PRODUCTS = gql`
  mutation getPosInventoryProducts($query: string, $input: any) {
    products(query: $query, input: $input)
    @rest(
      type: "PosProduct"
      method: "POST"
      path: "/pos/products?{args.query}"
      endpoint: "ss",
    ) {
      results
      total
    }
  }
`
