import React, { useReducer } from 'react';
import { ShopContext } from './shop.context';
import { Shop } from 'models/Shop';
const INITIAL_STATE = null;

type ActionType = {
  type: 'SET_SHOP';
  payload?: Shop;
};

function reducer(state: any, action: ActionType): any {
  switch (action.type) {
    case 'SET_SHOP':
      return action.payload;
    default:
      return state;
  }
};

export const ShopProvider: React.FunctionComponent<any> = ({ children }) => {
  const [shopState, shopDispatch] = useReducer(reducer, INITIAL_STATE);
  return (
    <ShopContext.Provider value={{ shopState, shopDispatch }}>
      {children}
    </ShopContext.Provider>
  );
};
