import styled from 'styled-components'

type StockStyleWrapperProps = { loading: boolean; };
export const StockStyle = {
  Wrapper: styled.div<StockStyleWrapperProps>`
    ${props => props.loading ? "opacity: 0.5;" : ""}
  `,
  Content: styled.div`
    margin: 44px 0 24px; 
  `,
  FieldContainer: styled.div`
    display: flex;
    flex-flow: row nowrap;
    flex-align: center;
    gap: 16px;
    margin: 32px 0;
    
    input {
      text-align: center;
      font-size: 24px;
    }
  `,
  StockButton: styled.button<StockStyleWrapperProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.loading ? "var(--secondary-label-color)" : "var(--devo-blue)"};
    border: none;
    color: white;
    padding: 0 18px;
    border-radius: 999px;
  `
}