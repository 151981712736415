import { faCog } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Caption } from '../../../styles/Global';
import { WholesalerMenuOption } from '../AllWholesaleProducts';
import ChevronSmall from 'components/Chevron/ChevronSmall';
import { RHWholesaleLogoStyled, Wholesalers } from '../Wholesale.style';
import WholesaleLogo from 'components/Wholesale/WholesaleLogo';
import usePermissions from 'endpoints/permissions/usePermissions';

type AllWholesalersGridItemProps = {
  provider: WholesalerMenuOption;
  onSetupSelect: (wholesaler: WholesalerMenuOption) => void;
  customerCode: string;
};

const AllWholesalersGridItem: React.FC<AllWholesalersGridItemProps> = ({
  provider,
  onSetupSelect,
  customerCode,
}) => {
  const { permissions } = usePermissions();

  if (!!provider.customerId && permissions.depot_catalogue_read) {
    switch (provider.id) {
      case 'BOOKER':
        return (
          <Wholesalers.Link href={provider.href}>
            <Wholesalers.Item>
              <WholesaleLogo
                wholesaler={provider.id}
                height={25}
                marginTop={12}
                marginBottom={12}
              />
              <Caption>
                <b>Customer Number: </b>
                {customerCode ?? 'No customer code'}
              </Caption>
            </Wholesalers.Item>
          </Wholesalers.Link>
        );
      case 'RH_WHOLESALE':
        return (
          <Wholesalers.Link href={provider.href}>
            <Wholesalers.Item className='no-p'>
              <RHWholesaleLogoStyled />
            </Wholesalers.Item>
          </Wholesalers.Link>
        );
    }
  }

  return (
    <Wholesalers.Container>
      <Wholesalers.Item>
        <WholesaleLogo
          wholesaler={provider.id}
          height={25}
          marginTop={12}
          marginBottom={4}
        />
        <Wholesalers.Button
          variant="red"
          icon={faCog}
          onClick={(e) => onSetupSelect(provider)}
        >
          Connect to {provider.name}
          <ChevronSmall />
        </Wholesalers.Button>
      </Wholesalers.Item>
    </Wholesalers.Container>
  );
};

export default AllWholesalersGridItem;
