
export const calculateMedian = (values: number[]): number => {
  if (values.length === 0) return 0;

  values.sort((a,b) => {
    return a - b;
  });

  var half = Math.floor(values.length / 2);

  if (values.length % 2) return values[half];

  return (values[half - 1] + values[half]) / 2.0;
}

export const roundPrice = (num: number, decimalPlaces = 2): number => {
  let p = Math.pow(10, decimalPlaces);
  let n = (num * p) * (1 + Number.EPSILON);
  return Math.round(n) / p;
}