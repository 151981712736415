import gql from 'graphql-tag';
import { PRODUCTS_GQL_PROPERTIES } from 'models/Product';

export const GET_PRODUCT_VERSION_HISTORY = gql`
  query getShopProductHistory($shopId: String, $productUpc: Int) {
    changes(shopId: $shopId, productUpc: $productUpc)
    @rest(type: "Product[]",
          method: "GET",
          path: "/shopProduct/history?shopUuid={args.shopId}&productUpc={args.productUpc}",
          endpoint: "ss") {
      shopUuid
      upc
      shopPrice
      popularityScore
      availabilityText
      limit
      authorUuid
      createdAt
    }
  }
`;