import React, { useState } from 'react';
import { createContext, useContext } from 'react';

interface PermissionsState {
  groups: string[];
  setGroups: React.Dispatch<React.SetStateAction<string[]>>;
  fetchingGroups: boolean;
  setFetchingGroups: React.Dispatch<React.SetStateAction<boolean>>;
}

const PermissionsContext = createContext<Partial<PermissionsState>>({});

export const PermissionsProvider: React.FunctionComponent<any> = ({
  children,
}) => {
  const [groups, setGroups] = useState([]);
  const [fetchingGroups, setFetchingGroups] = useState(false);

  return (
    <PermissionsContext.Provider
      value={{ groups, setGroups, fetchingGroups, setFetchingGroups }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissionsContext = () => useContext(PermissionsContext);
export default usePermissionsContext;
