import { formatPrice } from 'helper/product';
import { roundPrice } from 'helper/products/price';
import React, { useEffect, useRef, useState } from 'react';
import { Field } from 'styles/Form';

type PriceFieldProps = {
  id?: string;
  value: number;
  onValueChange: (value: number) => void;
  onEnter?: () => void;
  immediateValueChange?: boolean;
  handleRecommendedPrice?: boolean;
  setHandleRecommendedPrice?: (value: boolean) => void;
  removePound?: boolean;
};

const PriceField: React.FC<PriceFieldProps> = ({
  id = null,
  value,
  onValueChange,
  onEnter = null,
  immediateValueChange = false,
  handleRecommendedPrice = false,
  setHandleRecommendedPrice = undefined,
  removePound = false,
}) => {
  const ref = useRef(null);
  const [textValue, setTextValue] = useState(formatPrice(value));
  const [hasLoadedInitialValue, setHasLoadedInitialValue] = useState(false);

  useEffect(() => {
    if (!hasLoadedInitialValue) {
      setTextValue(formatPrice(value));
      if (value) {
        setHasLoadedInitialValue(true);
      }
    }
  }, [value, hasLoadedInitialValue]);

  useEffect(() => {
    if (!handleRecommendedPrice || !setHandleRecommendedPrice) {
      return;
    }
    setTextValue(formatPrice(value, '', removePound));
    setHandleRecommendedPrice(false);
  }, [handleRecommendedPrice, value]);

  const handleFocus = (e) => {
    setTextValue('');
  };

  const handleBlur = (e) => {
    let priceText = e.target.value.replace(/[^0-9.]/g, '');
    let priceNum = parseFloat(priceText);
    if (isNaN(priceNum)) return fallback();

    let price = roundPrice(priceNum);
    setTextValue(formatPrice(price, '', removePound));
    onValueChange(price);
  };

  const fallback = () => {
    setTextValue(formatPrice(value, '', removePound));
  };

  useEffect(() => {
    setTextValue(formatPrice(value, '', removePound));
  }, [value]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      ref.current?.blur();
      if (!!onEnter) onEnter();
    }
  };

  const handleChange = (e: any) => {
    if (!immediateValueChange) {
      setTextValue(e.target.value);
      return;
    }
    const priceText = e.target.value.replace(/[^0-9.]/g, '');
    let priceNum = parseFloat(priceText);
    if (isNaN(priceNum)) return fallback();
    setTextValue(priceText);
    const price = roundPrice(priceNum);
    onValueChange(price);
  };

  return (
    <Field
      ref={ref}
      id={id}
      type="text"
      value={textValue}
      placeholder={formatPrice(value, '', removePound)}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
    />
  );
};

export default PriceField;
