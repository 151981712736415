import { roundTo } from 'helper/product'
import React, { useEffect, useRef, useState } from 'react'
import { Field } from 'styles/Form'

type PriceFieldProps = {
  id?: string;
  value: number;
  roundToDecimalPlaces?: number;
  onValueChange: (value: number) => void;
  onEnter?: () => void;
  minimum?: number;
  maximum?: number;
}

const PriceField: React.FC<PriceFieldProps> = ({
  id = null,
  value,
  roundToDecimalPlaces = 0,
  onValueChange,
  onEnter = null,
  minimum = 0,
  maximum = 1000,
}) => {
  const ref = useRef(null);
  const [textValue, setTextValue] = useState(value.toString());

  useEffect(() => {
    setTextValue(value.toString());
  }, [value])

  const handleFocus = e => {
    setTextValue("");
  }

  const handleBlur = e => {
    let num = getNumber(e);
    if (isNaN(num)) return fallback();
    num = Math.max(minimum, Math.min(num, maximum));
    num = roundTo(num, roundToDecimalPlaces);
    setTextValue(num?.toString());
    onValueChange(num);
  }

  const getNumber = (e): number => {
    let numText = e.target.value;
    return parseFloat(numText);
  }

  const fallback = () => {
    setTextValue(value?.toString());
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      ref.current?.blur();
      if (!!onEnter) onEnter();
    }
  }

  return (
    <Field
      ref={ref}
      id={id}
      type="number"
      value={textValue}
      placeholder={value?.toString()}
      onChange={e => setTextValue(e.target.value)}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      min={minimum}
      max={maximum}
    />
  )
}

export default PriceField