import { HOME_ROUTE } from 'constants/navigation';
import { Permission } from 'models/Permissions';
import usePermissions from './usePermissions';
import { useLayoutEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useTimeout } from 'hooks/useTimeout';

export const useProtectWithPermission = (
  permission: Permission,
  redirectUrl = HOME_ROUTE,
  { skip = false } = {}
) => {
  const { permissions, fetchingGroups } = usePermissions();
  const [latencyElapsed, setLatencyElapsed] = useState(false);
  const router = useRouter();

  useTimeout(() => {
    setLatencyElapsed(true);
  });

  useLayoutEffect(() => {
    if (skip || fetchingGroups || !latencyElapsed) {
      return;
    }

    if (!permissions[permission]) {
      router.push(redirectUrl);
    }
  }, [permissions, permission, router, redirectUrl, skip]);
};
