import useShopContext from 'contexts/shop/shop.context';
import { Category } from 'models/Category';
import PosProduct from 'models/PosProduct';
import { QuantityMeasureEnum, ShopProduct } from 'models/Product';

const UPC_ZERO_PRODUCT: ShopProduct = {
  uuid: '',
  shopUuid: '',
  product: {
    upc: 0,
    name: 'Grocery',
    units: 0,
    quantityUnits: 0,
    quantityMeasure: 'GRAMS',
    category: {
      id: -1,
      name: 'No Category',
      parentId: 1,
      containsAlcohol: false,
      containsTobacco: false,
      isFlammable: false,
      isDisplayed: true,
    },
    vatRate: 0,
    thumbnail: null,
    image: null,
    live: true,
    priceMarked: false,
  },
  shopCategory: {
    shopUuid: '',
    categoryId: -1,
    shopCommission: 0,
    devoMarkup: 0,
  },
  shopPrice: 0,
  devoPrice: 0,
  popularityScore: 0,
  availability: 'AVAILABLE',
  vatRate: 0,
  limit: 0,
};

const UPC_ZERO_POS_PRODUCT: PosProduct = {
  uuid: '0',
  upc: 0,
  shopUuid: '',
  name: 'Grocery',
  units: 0,
  quantityUnits: 0,
  quantityMeasure: 'GRAMS',
  categoryId: -1,
  vatCode: 0,
  thumbnailUrl: null,
  imageUrl: null,
  rsp: 0,
  shopPrice: 0,
  createdAt: '',
  isLive: false,
  priceMarked: false,
  availability: 'NOT_AVAILABLE',
  quantityMeasurePricing: null,
  quantityUnitsPricing: null,
};

const MISSING_CATEGORY = {
  id: -1,
  name: 'No Category',
  parentId: 1,
  containsAlcohol: false,
  containsTobacco: false,
  isFlammable: false,
  isDisplayed: true,
};

type ProductFudgerCallbackProps = {
  upcZeroProduct: ShopProduct;
  missingCategory: Category;
  getMissingProduct: (upc: number) => ShopProduct;
  getMissingPosProduct: (upc: number) => PosProduct;
  getMissingCategory: (id: number, name: string) => Category;
  convertPosProduct: (product: PosProduct, category: Category) => ShopProduct;
  optimiseProduct: (product: PosProduct) => PosProduct;
};

const useProductFudger = (): ProductFudgerCallbackProps => {
  const { shopState } = useShopContext();

  const getUpcZeroProduct = ({
    upc = 0,
    shopUuid = shopState?.uuid || '',
    name = 'Grocery',
    shopPrice = 0,
    devoPrice = 0,
  } = {}): ShopProduct => {
    return {
      ...UPC_ZERO_PRODUCT,
      shopUuid,
      shopPrice,
      devoPrice,
      product: {
        ...UPC_ZERO_PRODUCT.product,
        upc,
        name,
      },
      shopCategory: {
        ...UPC_ZERO_PRODUCT.shopCategory,
        shopUuid,
      },
    };
  };

  const getUpcZeroPosProduct = ({
    upc = 0,
    shopUuid = shopState?.uuid || '',
    name = '',
    shopPrice = 0,
  } = {}): PosProduct => {
    return {
      ...UPC_ZERO_POS_PRODUCT,
      upc,
      shopUuid,
      name: name || (upc > 0 ? 'Untitled' : 'Grocery'),
      shopPrice,
    };
  };

  const getMissingCategory = ({
    id = -1,
    name = 'No Category',
  } = {}): Category => {
    return {
      ...MISSING_CATEGORY,
      id,
      name,
    };
  };

  const convertPosProduct = (
    product: PosProduct,
    category: Category | null
  ): ShopProduct => {
    return {
      uuid: '',
      shopUuid: shopState?.uuid,
      product: {
        upc: product.upc,
        name: product.name || 'Untitled',
        units: product.units,
        quantityUnits: product.quantityUnits,
        quantityMeasure: product.quantityMeasure as QuantityMeasureEnum,
        category: category || getMissingCategory(),
        vatRate: product.categoryId,
        thumbnail: product.thumbnailUrl,
        image: product.imageUrl,
        live: product.isLive,
        priceMarked: product.priceMarked,
      },
      shopCategory: {
        shopUuid: shopState?.uuid,
        categoryId: category.id,
        shopCommission: 0,
        devoMarkup: 0,
      },
      shopPrice: product.shopPrice || 0,
      devoPrice: product.shopPrice || 0,
      popularityScore: 1000,
      availability: 'AVAILABLE',
      vatRate: product.vatCode,
      limit: 0,
    };
  };

  const optimiseProduct = (product: PosProduct): PosProduct => {
    let categoryId = product?.categoryId || -1;
    if (categoryId == 1) categoryId = -1;
    return {
      uuid: product.uuid || '0',
      availability: product.availability || 'NOT_AVAILABLE',
      upc: product.upc || 0,
      shopUuid: shopState?.uuid,
      name: product?.name || 'Untitled',
      units: product?.units || 0,
      quantityUnits: product?.quantityUnits || 0,
      quantityMeasure: product?.quantityMeasure || 'GRAMS',
      categoryId,
      vatCode: product?.vatCode || 0,
      thumbnailUrl: product?.thumbnailUrl || null,
      imageUrl: product?.imageUrl || null,
      rsp: product?.rsp || 0,
      shopPrice: product?.shopPrice || 0,
      createdAt: product?.createdAt || '',
      isLive: product?.isLive || false,
      priceMarked: product?.priceMarked || false,
      quantityMeasurePricing: product?.quantityMeasurePricing || null,
      quantityUnitsPricing: product?.quantityUnitsPricing || null,
    };
  };

  return {
    upcZeroProduct: getUpcZeroProduct(),
    missingCategory: getMissingCategory(),
    getMissingProduct: (upc) => getUpcZeroProduct({ upc }),
    getMissingPosProduct: (upc) => getUpcZeroPosProduct({ upc }),
    getMissingCategory: (id, name) => getMissingCategory({ id, name }),
    convertPosProduct,
    optimiseProduct,
  };
};

export default useProductFudger;
