import { gql } from '@apollo/client';

export const GET_WHOLESALE_SHOP = gql`
  query getShop($shopUuid: string) {
    shop(shopUuid: $shopUuid)
      @rest(
        type: "Shop"
        method: "GET"
        path: "/shop/{args.shopUuid}"
        endpoint: "depot"
      ) {
      uuid
      wholesalerInformation
    }
  }
`;
