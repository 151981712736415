import { LogoStyled } from './RHWholesaleLogo.style';

export type RHWholesaleLogoProps = {
  className?: string;
};

const RHWholesaleLogo: React.FC<RHWholesaleLogoProps> = ({ className }) => {
  return <LogoStyled className={className} />;
};

export default RHWholesaleLogo;
