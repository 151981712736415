import React, { useLayoutEffect, useState } from 'react';
import BetaBadge from 'components/Misc/BetaBadge';
import { useWindowSizeBreakpoints } from 'helper/useWindowSize';
import usePermissions from 'endpoints/permissions/usePermissions';
import { useRouter } from 'next/router';
import useAuth from 'contexts/auth/auth.context';
import {
  Container,
  Expand,
  MobileHeader,
  NavLink,
  SidebarSpacer,
} from './Sidebar.style';
import Link from 'next/link';
import { Title, Label2, Spacer, FlexHCenterDiv } from 'styles/Global';
import Brand from 'components/Brand/Brand';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  HOME_ROUTE,
  LOGIN_ROUTE,
  LIVE_ORDERS_ROUTE,
  ADD_PRODUCTS_ROUTE,
  LIVE_PRODUCTS_ROUTE,
  YOUR_STORE_ROUTE,
  DRAFT_PRODUCTS_ROUTE,
  PROMOTIONS_ROUTE,
  WHOLESALE_ROUTE,
  PAST_ORDERS_ROUTE,
  WHOLESALE_ORDERS_ROUTE,
  POS_SCAN_ROUTE,
  _PRODUCTS_ROUTE,
  STORE_REMOTE_REPORT_ROUTE,
  DELIVERED_ORDERS_ROUTE,
} from 'constants/navigation';
import {
  faAngleDoubleLeft,
  faBarcode,
  faBicycle,
  faHome,
  faPoll,
  faShoppingCart,
  faStore,
  faTag,
  faWarehouse,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { Badge, Collapse } from 'react-bootstrap';

type SidebarItemProps = {
  href?: string;
  onClick?: (e: any) => void;
  index: number;
  icon?: IconDefinition;
  title: string;
  active?: string;
  submenu?: boolean;
  badge?: string;
  badgeVariant?: string;
};

const SidebarItem: React.FC<SidebarItemProps> = ({
  href = '#',
  onClick,
  index,
  icon,
  title,
  active,
  submenu = false,
  badge,
  badgeVariant = 'primary',
}) => {
  return (
    <Link key={index} href={href} passHref style={{ textDecoration: 'none' }}>
      <NavLink
        onClick={onClick}
        className={`${href === active ? 'active ' : ''}${
          submenu ? 'submenu' : ''
        }`}
      >
        {icon && <FontAwesomeIcon icon={icon} />}
        <span style={{ marginLeft: icon ? 8 : 0, marginRight: 8 }}>
          {title}
        </span>
        {badge && (
          <Badge pill bg={badgeVariant}>
            {badge}
          </Badge>
        )}
      </NavLink>
    </Link>
  );
};

type SidebarProps = {
  mobile?: boolean;
  showDrawer?: boolean;
  setShowDrawer?: (set: boolean) => void;
};

const Sidebar: React.FC<SidebarProps> = ({
  mobile = false,
  showDrawer = true,
  setShowDrawer,
}) => {
  const { small: mobileBreakpoint } = useWindowSizeBreakpoints();
  const router = useRouter();
  const { pathname } = router;
  const { authState, authDispatch } = useAuth();
  const { user } = authState;
  const { firstName, lastName } = user;

  const { isStable, isPosEnabled, isBetaRelease, permissions, isDebug } =
    usePermissions();

  const isWholesaleAgent = permissions.wholesale_agent;

  const [openItems, setOpenItems] = useState({
    INVENTORY: pathname.includes(_PRODUCTS_ROUTE),
    ORDERS:
      pathname === LIVE_ORDERS_ROUTE ||
      pathname === PAST_ORDERS_ROUTE ||
      pathname === DELIVERED_ORDERS_ROUTE,
    WHOLESALE: pathname.includes(WHOLESALE_ROUTE),
  });

  useLayoutEffect(() => {
    setOpenItems({
      INVENTORY: pathname.includes(_PRODUCTS_ROUTE),
      ORDERS:
        pathname === LIVE_ORDERS_ROUTE ||
        pathname === PAST_ORDERS_ROUTE ||
        pathname === DELIVERED_ORDERS_ROUTE,
      WHOLESALE: pathname.includes(WHOLESALE_ROUTE),
    });
  }, [pathname]);

  const isOpen = (k: string) => openItems[k] || false;
  const setOpen = (k: string, v: boolean) =>
    setOpenItems({ ...openItems, [k]: v });

  const handleLogout = (e: any) => {
    e.preventDefault();
    authDispatch({ type: 'LOGOUT' });
    router.push(LOGIN_ROUTE);
  };

  return (
    <Container>
      <MobileHeader>
        {!mobile && (
          <FlexHCenterDiv spaceBetween style={{ marginBottom: 10 }}>
            <Brand red style={{ marginBottom: 0 }} />
            &nbsp;
            {isBetaRelease && <BetaBadge />}
            <Spacer />
            <Expand
              onClick={() => setShowDrawer(!showDrawer)}
              className={showDrawer ? '' : 'hidden'}
            >
              <FontAwesomeIcon icon={faAngleDoubleLeft} />
            </Expand>
          </FlexHCenterDiv>
        )}

        {!mobile && (
          <>
            {user && (
              <Label2>
                {firstName} {lastName}
              </Label2>
            )}
          </>
        )}
      </MobileHeader>

      {permissions.partner_centre_home && (
        <SidebarItem
          href={HOME_ROUTE}
          index={1}
          icon={faHome}
          title="Home"
          active={pathname}
        />
      )}

      {permissions.partner_centre_inventory && (
        <SidebarItem
          onClick={() => setOpen('INVENTORY', !isOpen('INVENTORY'))}
          index={2}
          icon={faShoppingCart}
          title="Inventory"
        />
      )}
      <Collapse in={isOpen('INVENTORY')}>
        <div>
          <SidebarItem
            href={LIVE_PRODUCTS_ROUTE}
            index={20}
            title="Your Inventory"
            active={pathname}
            submenu
          />
          {!mobile && (
            <SidebarItem
              href={ADD_PRODUCTS_ROUTE}
              index={21}
              title="Add to Store"
              active={pathname}
              submenu
            />
          )}
          <SidebarItem
            href={DRAFT_PRODUCTS_ROUTE}
            index={22}
            title="Draft Products"
            active={pathname}
            submenu
          />
        </div>
      </Collapse>

      {permissions.read_shop_order && permissions.partner_centre_orders && (
        <>
          <SidebarItem
            onClick={() => setOpen('ORDERS', !isOpen('ORDERS'))}
            index={4}
            icon={faBicycle}
            title="Orders"
          />

          <Collapse in={isOpen('ORDERS')}>
            <div>
              <SidebarItem
                href={LIVE_ORDERS_ROUTE}
                index={41}
                title="Live Orders"
                active={pathname}
                submenu
              />

              {isWholesaleAgent && (
                <SidebarItem
                  href={DELIVERED_ORDERS_ROUTE}
                  index={42}
                  title="Delivered Orders"
                  active={pathname}
                  submenu
                />
              )}

              {permissions.read_shop_order_history && (
                <SidebarItem
                  href={PAST_ORDERS_ROUTE}
                  index={43}
                  title="Past Orders"
                  active={pathname}
                  submenu
                />
              )}
            </div>
          </Collapse>
        </>
      )}

      {permissions.read_shop_report_sales &&
        permissions.partner_centre_sales_reports && (
          <SidebarItem
            href={STORE_REMOTE_REPORT_ROUTE}
            index={6}
            icon={faPoll}
            title="Sales Report"
            active={pathname}
          />
        )}

      {isPosEnabled && (
        <>
          {permissions.partner_centre_scan && (
            <SidebarItem
              href={POS_SCAN_ROUTE}
              index={7}
              icon={faBarcode}
              title="Scan"
              active={pathname}
            />
          )}

          {permissions.read_shop_promotion &&
            permissions.partner_centre_promotions && (
              <SidebarItem
                href={PROMOTIONS_ROUTE}
                index={8}
                icon={faTag}
                title="Promotions"
                active={pathname}
              />
            )}
        </>
      )}

      {permissions.read_shop_wholesale &&
        permissions.partner_centre_wholesale && (
          <>
            <SidebarItem
              onClick={() => setOpen('WHOLESALE', !isOpen('WHOLESALE'))}
              index={9}
              icon={faWarehouse}
              title="Wholesale"
              badge="BETA"
            />
            <Collapse in={isOpen('WHOLESALE')}>
              <div>
                <SidebarItem
                  href={WHOLESALE_ROUTE}
                  index={91}
                  title="Suppliers"
                  active={pathname}
                  submenu
                />
                <SidebarItem
                  href={WHOLESALE_ORDERS_ROUTE}
                  index={91}
                  title="Orders"
                  active={pathname}
                  submenu
                />
              </div>
            </Collapse>
          </>
        )}

      {!mobileBreakpoint && <div className={'subheader'} />}

      <SidebarSpacer />

      {permissions.partner_centre_your_store && (
        <SidebarItem
          href={YOUR_STORE_ROUTE}
          index={99}
          icon={faStore}
          title="Your Store"
          active={pathname}
          badge={isDebug ? 'DEBUG' : null}
        />
      )}

      <SidebarItem
        onClick={handleLogout}
        index={100}
        title="Log Out"
        active={pathname}
      />
    </Container>
  );
};

export default Sidebar;
