import React from 'react';
import { Caption } from 'styles/Global';
import { Product } from '../Orders/OrderPopup.style';
import { ShopProduct } from 'models/Product';
import { NetworkStatus, useQuery } from '@apollo/react-hooks';
import { GET_PRODUCT } from 'devoapi/products';
import useShopContext from 'contexts/shop/shop.context';
import Loader from 'components/Loader/Loader';
import { formatSizeSeparately, formatUpc } from 'helper/products';
import { formatPriceNumber, formatVatRate } from 'helper/product';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { OrderBasketItemGroup } from 'helper/orders/orders';
import { PriceMarkOverlay } from 'components/ProductRow/ProductRow';
import Status from 'components/ProductRow/Status';
import useWindowSize from 'helper/useWindowSize';
import PlaceholderImage from 'image/product-placeholder.png';

type PopupProductProps = {
  item?: OrderBasketItemGroup;
  loading?: boolean;
  product?: ShopProduct;
  appearance?: 'single' | 'list';
  onClick?: () => void;
  beforeChevronView?: () => React.ReactNode;
  chevron?: boolean;
  extraBlocks?: { [key: string]: string };
};

const PopupProduct: React.FC<PopupProductProps> = ({
  item: basketItem,
  loading: _loading,
  product: item,
  appearance = 'single',
  onClick,
  beforeChevronView = null,
  chevron = true,
  extraBlocks = {},
}) => {
  const { shopState } = useShopContext();
  const { width } = useWindowSize();
  const mobile = width < 1024;

  // --------------------------------------------
  // API
  // --------------------------------------------

  const {
    data,
    loading: queryLoading,
    error,
    refetch,
    networkStatus,
  } = useQuery(GET_PRODUCT, {
    skip: !basketItem,
    notifyOnNetworkStatusChange: true,
    variables: {
      shopId: shopState?.uuid,
      productUpc: basketItem?.productUpc,
    },
  });
  let upc = item?.product.upc || data?.product.product.upc;
  let product = item || (data?.product as ShopProduct);
  let loading = _loading || queryLoading;
  let refetching = networkStatus === NetworkStatus.refetch;

  // --------------------------------------------
  // Actions
  // --------------------------------------------

  const handleClick = (e) => {
    if (!!onClick && appearance === 'list') onClick();
  };

  const handleStatusClick = (val: string) => {
    refetch();
  };

  // --------------------------------------------
  // Render
  // --------------------------------------------

  if (loading && !refetching) {
    return (
      <Product.Wrapper>
        <Loader />
      </Product.Wrapper>
    );
  }


  let [sizeQuantity, sizeMeasure] = formatSizeSeparately(product);

  if (product) {
    return (
      <Product.Wrapper
        className={`popup-product ${appearance.toLowerCase()} noselect`}
        onClick={handleClick}
      >
        <Product.Content>
          <Product.Image
            src={product.product.thumbnail || PlaceholderImage}
            alt={product.product.name}
          />

          <Product.Right>
            <Product.Title>{product.product.name}</Product.Title>
            <Caption style={{ display: 'block', marginBottom: 12 }}>
              {formatUpc(product.product.upc.toString())}
            </Caption>

            {mobile && appearance == 'single' && (
              <Product.Status>
                {refetching ? (
                  <Loader />
                ) : (
                  <Status
                    value={product.availability}
                    onSelect={handleStatusClick}
                  />
                )}
              </Product.Status>
            )}

            <Product.Blocks>
              {upc > 0 && (
                <Product.Block key={0}>
                  <p>
                    {sizeQuantity || '--'}
                    <span>{sizeMeasure}</span>
                  </p>
                  <span>size</span>
                </Product.Block>
              )}
              {upc > 0 && (
                <Product.Block key={1}>
                  <p>
                    <span>£</span>
                    {formatPriceNumber(product.shopPrice)}
                    &nbsp;
                    <PriceMarkOverlay enabled={product.product.priceMarked} />
                  </p>
                  <span>shop price</span>
                </Product.Block>
              )}
              {upc > 0 && (
                <Product.Block key={2}>
                  <p>
                    {formatVatRate(product.vatRate, false)}
                    <span>%</span>
                  </p>
                  <span>VAT</span>
                </Product.Block>
              )}
              {Object.keys(extraBlocks).map((key, index) => (
                <Product.Block key={index + 3}>
                  <p>{extraBlocks[key]}</p>
                  <span>{key}</span>
                </Product.Block>
              ))}
            </Product.Blocks>
          </Product.Right>

          {!!beforeChevronView && (
            <Product.Inserted>{beforeChevronView()}</Product.Inserted>
          )}

          {chevron && appearance == 'list' && (
            <Product.Chevron>
              <FontAwesomeIcon icon={faChevronRight} />
            </Product.Chevron>
          )}
        </Product.Content>

        {!mobile && appearance == 'single' && upc > 0 && (
          <Product.Status>
            {refetching ? (
              <Loader />
            ) : (
              <Status
                value={product.availability}
                onSelect={handleStatusClick}
              />
            )}
          </Product.Status>
        )}
      </Product.Wrapper>
    );
  }

  return <></>;
};

export default PopupProduct;
