import { ProductsViewMode } from "containers/Products/Products";
import { BooleanValueNode } from "graphql";
import { cloneObject } from "helper/object";
import { AvailabilityEnum, QuantityMeasureEnum } from "./Product";
import { FieldsOf } from "helper/types/FieldsOf";

export type FilterPropsSortingBy = "SORT_BY_NAME" | "SORT_BY_SHOP_PRICE" | "SORT_BY_QUANTITY_UNITS" | "SORT_BY_POPULARITY";

export const copyFilter = (orig: FilterProps) => {
  const copy = cloneObject(orig);
  copy.availabilityList = Object.assign([], orig.availabilityList);
  return copy;
}

export type FilterConstructorParams = Partial<FieldsOf<FilterProps>>

export class FilterProps {
  public keyword: string;

  public availabilityList: AvailabilityEnum[];

  public categoryIds: number[];
  public includeSubcats: boolean;

  public fromQuantity: number;
  public toQuantity: number;
  public quantityMeasure: QuantityMeasureEnum;

  public fromShopPrice: number;
  public toShopPrice: number;
  public isPriceMarked: boolean;

  public sortingBy: FilterPropsSortingBy;
  public isAscending: boolean;

  public toQueryString(mode: ProductsViewMode): string {
    var result = "&";

    if (this.keyword && this.keyword.length > 0) {
      result += `keyword=${this.keyword}&`;
    }

    if (this.availabilityList.length > 0 && mode === 'LIVE') {
      result += `availabilityList=${this.availabilityList.join(',')}&`;
    }

    if (this.categoryIds.length > 0) {
      result += `categoryIds=${this.categoryIds.join(',')}&includeSubcats=${this.includeSubcats}&`;
    }

    if (this.fromQuantity) result += `fromQuantity=${this.fromQuantity}&`;
    if (this.toQuantity) result += `toQuantity=${this.toQuantity}&`;
    if (this.quantityMeasure) result += `quantityMeasure=${this.quantityMeasure}&`;

    if (this.fromShopPrice) result += `fromShopPrice=${this.fromShopPrice}&`;
    if (this.toShopPrice) result += `toShopPrice=${this.toShopPrice}&`;
    if (this.isPriceMarked != null) result += `isPriceMarked=${this.isPriceMarked}&`;

    if (this.sortingBy) {
      result += `sortingBy=${this.sortingBy}&`;
      result += `isAscending=${this.isAscending}&`;
    }

    if (result[result.length-1] === '&') {
      result = result.slice(0, -1);
    }

    return result;
  };

  public categoryIsRoot(): boolean {
    return this.categoryIds.length === 1 && this.categoryIds[0] === 1;
  }

  public filterEmpty(): boolean {
    return (
      this.availabilityList.length === 2 &&
      this.availabilityList[0] === 'AVAILABLE' &&
      this.availabilityList[1] === 'NOT_AVAILABLE' &&
      this.categoryIds.length === 0 &&
      this.includeSubcats &&
      this.fromQuantity === null &&
      this.toQuantity === null &&
      this.quantityMeasure === null &&
      this.fromShopPrice === null &&
      this.toShopPrice === null &&
      this.isPriceMarked === null
    );
  }

  public filterIsEmptyWithRootCategory(): boolean {
    return (
      this.availabilityList.length === 2 &&
      this.availabilityList[0] === 'AVAILABLE' &&
      this.availabilityList[1] === 'NOT_AVAILABLE' &&
      this.categoryIds.length === 1 &&
      this.categoryIds[0] === 1 &&
      this.includeSubcats &&
      this.fromQuantity === null &&
      this.toQuantity === null &&
      this.quantityMeasure === null &&
      this.fromShopPrice === null &&
      this.toShopPrice === null &&
      this.isPriceMarked === null
    );
  }

  public queryEmpty(): boolean {
    return this.filterEmpty() && this.keyword === "";
  }

  public queryEmptyWithRootCategory(): boolean {
    return this.filterIsEmptyWithRootCategory() && this.keyword === "";
  }

  constructor(createFilterProps?: FilterConstructorParams) {
    this.keyword = createFilterProps?.keyword ?? "";
    this.availabilityList = createFilterProps?.availabilityList ?? ['AVAILABLE', 'NOT_AVAILABLE'];
    this.categoryIds = createFilterProps?.categoryIds ?? [];
    this.includeSubcats = createFilterProps?.includeSubcats ?? true;
    this.fromQuantity = createFilterProps?.fromQuantity ?? null;
    this.toQuantity = createFilterProps?.toQuantity ?? null;
    this.quantityMeasure = createFilterProps?.quantityMeasure ?? null;
    this.fromShopPrice = createFilterProps?.fromShopPrice ?? null;
    this.toShopPrice = createFilterProps?.toShopPrice ?? null;
    this.isPriceMarked = createFilterProps?.isPriceMarked ?? null;
    this.sortingBy = createFilterProps?.sortingBy ?? null;
    this.isAscending = createFilterProps?.isAscending ?? true;
  }

  static copyOf(otherFilter: FilterProps): FilterProps {
    return new FilterProps(otherFilter);
  }

  get copy(): FilterProps {
    return FilterProps.copyOf(this);
  }
};

export default FilterProps;