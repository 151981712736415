import { useState, useEffect } from 'react';
import { StyleBreakpoints } from 'styles/Global'

type WindowSizeProps = {
  width: number;
  height: number;
}

type WindowSizeType = {
  mobile: boolean;
  smallTablet: boolean;
  liveOrders: boolean;
  tablet: boolean;
  desktop: boolean;
}

type SizeBreakpointType = {
  tiny: boolean;
  small: boolean;
  medium: boolean;
  large: boolean;
  huge: boolean;
}

const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    
    // Add event listener
    window.addEventListener("resize", handleResize);
    
    // Call handler right away so state gets updated with initial window size
    handleResize();
    
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
};

export const useWindowSizeDeviceType = (): WindowSizeType & WindowSizeProps => {
  const { width, height } = useWindowSize();
  return {
    mobile: width <= 450,
    smallTablet: width <= 768,
    liveOrders: width <= 850,
    tablet: width <= 1024,
    desktop: width > 1024,
    width, height
  }
};

export const useWindowSizeBreakpoints = (): SizeBreakpointType & WindowSizeProps => {
  const { width, height } = useWindowSize();
  return {
    tiny: width <= StyleBreakpoints.TINY,
    small: width <= StyleBreakpoints.SMALL,
    medium: width <= StyleBreakpoints.MEDIUM,
    large: width <= StyleBreakpoints.LARGE,
    huge: width <= StyleBreakpoints.HUGE,
    width, height
  }
};

export default useWindowSize;