import React from 'react';
import styled, { CSSProperties } from 'styled-components'
import LogoRed from 'image/devo-red-brand.png';
import LogoWhite from 'image/devo-white-brand.png';

const Wrapper = styled.div`
  img {
    margin-left: 8px;
    height: 24px;

    @media (max-width: 768px) {
      margin: 0 0 0 0;
      height: 40px;
    }
  }
`;

type BrandProps = {
  red?: boolean;
  white?: boolean;
  style?: CSSProperties;
};

const Brand: React.FC<BrandProps> = ({
  red = false,
  white = false,
  style
}) => {

  var src = LogoRed;
  if (red) src = LogoRed;
  if (white) src = LogoWhite;

  return (
    <Wrapper className='brand' style={style}>
      <img src={src} alt='Devo' />
    </Wrapper>
  );
};

export default Brand;
