import React from 'react';
import Popover from 'react-bootstrap/Popover';

const PriceMarkPopover = (
  <Popover id="popover-basic">
    <Popover.Body>
      This item is <b>price marked</b>.
    </Popover.Body>
  </Popover>
);

export default PriceMarkPopover;