import CategoryTreeNode from 'models/CategoryTreeNode'
import React from 'react';
import { CategoryRow, CategoryItem, CategoryCheck } from './CategoryList.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';

type CategoryListProps = {
  node: CategoryTreeNode;
  n?: number;
  showing?: boolean;
  expandedCategories: number[];
  onToggleCategoryExpand: (c: CategoryTreeNode) => void;
  selectedCategories: number[];
  onCategorySelect: (c: CategoryTreeNode) => void;
  disableExpand?: boolean;
  allItemsLabel?: React.ReactNode;
};

export const Category: React.FC<CategoryListProps> = ({
  node,
  n,
  showing,
  expandedCategories,
  onToggleCategoryExpand,
  selectedCategories,
  onCategorySelect,
  disableExpand = false,
  allItemsLabel
}) => {
  const isExpanded = expandedCategories.includes(node.data.id);
  const isSelected = selectedCategories.includes(node.data.id);
  const hasChildren = !disableExpand && node.children.length > 0;
  const selectedCategoryModels = node.getAllDescendants().filter(x => selectedCategories.includes(x.data.id));;
  const hasSelectedChildren = !isSelected && hasChildren && selectedCategoryModels.length > 0;

  const icon = () => {
    if (hasSelectedChildren) return 'DASH';
    return 'CHECK';
  }

  if (node.data.id === 1 && !allItemsLabel) return <></>;

  return (
    <CategoryRow key={node.data.id}>

      {/* @ts-ignore */}
      <CategoryCheck n={n}
        icon={icon()}
        isSelected={hasSelectedChildren || isSelected}
        onClick={() => onCategorySelect(node)}
        className={`${n} ${showing ? 'showing' : ''}`}
        $disableMarginOnMobile
      />

      <CategoryItem
        className={`item ${showing ? 'showing' : ''} ${hasChildren ? 'hasChildren' : ''} noselect`}
        onClick={e => onToggleCategoryExpand(node)}
      >
        {node.data.id === 1 ? allItemsLabel : node.data.name}
        {hasChildren &&
          <FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronRight} />
        }
      </CategoryItem>
    </CategoryRow>
  );
};

export const CategoryList: React.FC<CategoryListProps> = ({ node, n = 0, showing = true, expandedCategories, onToggleCategoryExpand, selectedCategories, onCategorySelect, allItemsLabel = "All items" }) => {
  return <>
    {n === 0 &&
      <Category 
        disableExpand={true} 
        node={node} 
        n={n} 
        showing={showing} 
        expandedCategories={expandedCategories} 
        onToggleCategoryExpand={onToggleCategoryExpand}
        selectedCategories={selectedCategories}
        onCategorySelect={onCategorySelect}
        allItemsLabel={allItemsLabel}
      />
    }
    {node.children.map(child => (
      <>
        <Category
          key={child.data.id}
          node={child} 
          n={n} 
          showing={showing} 
          expandedCategories={expandedCategories} 
          onToggleCategoryExpand={onToggleCategoryExpand} 
          selectedCategories={selectedCategories}
          onCategorySelect={onCategorySelect}
          allItemsLabel={allItemsLabel}
        />
        <CategoryList
          key={child.data.id*100}
          node={child} 
          n={n+1} 
          showing={expandedCategories.includes(child.data.id)}
          expandedCategories={expandedCategories} 
          onToggleCategoryExpand={onToggleCategoryExpand}
          selectedCategories={selectedCategories}
          onCategorySelect={onCategorySelect}
          allItemsLabel={allItemsLabel}
        />
      </>
    ))}
  </>;
};

export default CategoryList;