import { gql } from '@apollo/client';

export const GET_WHOLESALE_CUSTOMER = gql`
  query getWholesaleCustomer($shopUuid: String) {
    customers(shopUuid: $shopUuid)
      @rest(
        type: "Any"
        path: "/wholesale_customer?shopUuid={args.shopUuid}"
        endpoint: "os"
      ) {
      uuid
      shopUuid
      name
      email
      phoneNumber
      addressLine1
      addressLine2
      postcode
      city
      instructions
    }
  }
`;
