import { useQuery } from '@apollo/react-hooks';
import { GET_WHOLESALE_CUSTOMER } from './getWholesaleCustomer';
import {
  GetWholesaleCustomerPayload,
  GetWholesaleCustomerResponse,
} from './getWholesaleCustomer.entities';

export type UseWholesaleCustomerNamedParams = {
  keyword: string;
  skip?: boolean;
};

export const useWholesaleCustomer = (
  shopUuid: string,
  { keyword, skip }: UseWholesaleCustomerNamedParams = {
    keyword: '',
    skip: false,
  }
) => {
  type NewType = GetWholesaleCustomerResponse;

  const { data, ...restQueryProps } = useQuery<
    NewType,
    GetWholesaleCustomerPayload
  >(GET_WHOLESALE_CUSTOMER, {
    variables: {
      shopUuid,
    },
    skip: !shopUuid || skip,
  });

  return { data, ...restQueryProps };
};
