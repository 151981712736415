import React, { useState, createContext, useContext, useEffect } from 'react';
import Log from 'helper/monitoring';
import { POS_SERVICES_CATEGORY_ID } from 'constants/constants';
import useShopContext from 'contexts/shop/shop.context';
import { getCategories } from 'devoapi/category';
import { Category } from 'models/Category';
import CategoryTreeNode from 'models/CategoryTreeNode';
import { ExternalCategory } from 'models/DeliveryServices';
import { ROOT_CATEGORY_ID } from 'components/DeliveryServicesTable/ServiceTableRow';

interface CategoriesState {
  allCategories: Category[];
  allCategoriesInStore: Category[];
  categoriesWithoutServices: Category[];
  rootCategory: CategoryTreeNode | null;
  getTopLevelCategory: (c: CategoryTreeNode) => CategoryTreeNode;
  getCategoryForId: (id: number) => CategoryTreeNode;
  loading: boolean;
  error: any;
}

const CategoriesContext = createContext<Partial<CategoriesState>>({});

export const CategoriesProvider: React.FC<any> = ({ children }) => {
  const { shopState } = useShopContext();
  let shopUuid = shopState?.uuid;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [allCategories, setAllCategories] = useState<Category[]>([]);
  const [allCategoriesInStore, setAllCategoriesInStore] = useState<Category[]>(
    []
  );
  const [categoriesWithoutServices, setCategoriesWithoutServices] = useState<
    Category[]
  >([]);
  const [categoryMap, setCategoryMap] = useState<{
    [key: number]: CategoryTreeNode;
  }>({});
  const [rootCategory, setRootCategory] = useState<CategoryTreeNode | null>(
    null
  );

  useEffect(() => {
    async function fetch() {
      Log.debug('Fetching...', 'categories-context');

      let results = (await getCategories(shopUuid)) || [];
      let _root = CategoryTreeNode.toTree(results);
      setRootCategory(_root);
      setAllCategories(results);
      setAllCategoriesInStore(results.filter((x) => x.productsCount > 0));
      setCategoriesWithoutServices(
        results.filter((x) => x.id != POS_SERVICES_CATEGORY_ID)
      );

      let catMap = {};
      results.forEach((x) => (catMap[x.id] = _root.getNodeById(x.id)));
      setCategoryMap(catMap);

      setLoading(false);
      Log.debug('Response...', 'categories-context', {
        results: results.length,
      });
    }

    if (shopUuid) {
      try {
        fetch();
      } catch (error) {
        setLoading(false);
        setError(error);
      }
    }
  }, [shopUuid]);

  const getCategoryForId = (id: number): CategoryTreeNode => {
    return categoryMap[id];
  };

  const getTopLevelCategory = (curr: CategoryTreeNode): CategoryTreeNode => {
    let current = curr;
    while (current.parent.data.id > 1) {
      current = current.parent;
    }
    return current;
  };

  const props: CategoriesState = {
    allCategories,
    allCategoriesInStore,
    categoriesWithoutServices,
    rootCategory,
    getTopLevelCategory,
    getCategoryForId,
    loading,
    error,
  };

  return (
    <CategoriesContext.Provider value={props}>
      {children}
    </CategoriesContext.Provider>
  );
};

export const useCategoriesContext = () => useContext(CategoriesContext);

export interface CombinedCategory extends Category, ExternalCategory {}

export const useCombinedCategories = (
  plainExternalCategories: ExternalCategory[] | undefined
) => {
  const { allCategories } = useCategoriesContext();

  const [combinedCategories, setCombinedCategories] = useState<
    CombinedCategory[]
  >([]);
  const [rootCategory, setRootCategory] =
    useState<CategoryTreeNode<CombinedCategory> | null>(null);

  const discardCategoryChanges = () => {
    const allCombinedCategories = allCategories.map((category, i) => ({
      ...category,
      ...plainExternalCategories[i],
    }));

    const combinedCategoriesTree = CategoryTreeNode.toTree<CombinedCategory>(
      allCombinedCategories
    );

    setCombinedCategories(allCombinedCategories);
    setRootCategory(combinedCategoriesTree);
  };

  const sliceOutExternalCategories = (
    excludeRootCategory: boolean
  ): ExternalCategory[] => {
    return combinedCategories
      .filter((category) =>
        excludeRootCategory ? category.categoryId !== ROOT_CATEGORY_ID : true
      )
      .map((category) => ({
        categoryId: category.categoryId,
        markup: category.markup || 0.01,
        platform: category.platform,
        shopUuid: category.shopUuid,
      }));
  };

  useEffect(() => {
    if (!plainExternalCategories) {
      return;
    }
    const allCombinedCategories = allCategories.map((category, i) => ({
      ...category,
      ...plainExternalCategories[i],
    }));

    const combinedCategoriesTree = CategoryTreeNode.toTree<CombinedCategory>(
      allCombinedCategories
    );

    setCombinedCategories(allCombinedCategories);
    setRootCategory(combinedCategoriesTree);
  }, [allCategories, plainExternalCategories]);

  useEffect(() => {
    if (combinedCategories.length <= 0) {
      return;
    }

    const combinedCategoriesTree =
      CategoryTreeNode.toTree<CombinedCategory>(combinedCategories);

    setRootCategory(combinedCategoriesTree);
  }, [combinedCategories]);

  return {
    combinedCategories,
    rootCategory,
    setRootCategory,
    setCombinedCategories,
    discardCategoryChanges,
    sliceOutExternalCategories,
  };
};

export default useCategoriesContext;
