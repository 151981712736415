import dynamic from 'next/dynamic';
import { ChangeEvent, useState } from 'react';

import {
  ExternalCategory,
  PlatformConfiguration,
  SyncExternalCategoriesPayload,
} from 'models/DeliveryServices';
import { platformTypeDisplayNameMap } from 'containers/ManageDeliveryServices/ManageDeliveryServices.data';
import { useCombinedCategories } from 'contexts/categories';
import CategoryTreeNode from 'models/CategoryTreeNode';
import CategoryList from './CategoryList';
import { SelectorWrapper } from './CategoryList.style';
import useShopContext from 'contexts/shop/shop.context';
import { SYNC_EXTERNAL_CATEGORIES } from 'devoapi/delivery_services';
import { useMutation } from '@apollo/client';

const Popup = dynamic(() => import('components/Popup/Popup'));
const YesNo = dynamic(() => import('components/Popup/YesNo'));

export interface CustomProductMarkupPopupProps {
  isOpen: boolean;
  closeModal: () => void;
  service: PlatformConfiguration;
  onSave: () => void;
  onCancel: () => void;
  plainExternalCategories: ExternalCategory[] | undefined;
}

export interface ShowSubcategoriesState {
  showSubcategories: boolean;
}

const CustomProductMarkupPopup: React.FC<CustomProductMarkupPopupProps> = ({
  isOpen,
  closeModal,
  service,
  onSave,
  onCancel,
  plainExternalCategories,
}) => {
  const { shopState } = useShopContext();

  const {
    rootCategory,
    setCombinedCategories,
    discardCategoryChanges,
    sliceOutExternalCategories,
  } = useCombinedCategories(plainExternalCategories);

  const [expandedCategories, setExpandedCategories] = useState<number[]>([]);

  const [syncExternalCategories, { loading: syncingExternalCategories }] =
    useMutation<void, SyncExternalCategoriesPayload>(SYNC_EXTERNAL_CATEGORIES);

  const onToggleCategoryExpand = (c: CategoryTreeNode) => {
    setExpandedCategories((_prev) => {
      const prev = _prev.slice();
      if (prev.includes(c.data.id)) {
        c.getAllDescendants()
          .concat(c)
          .forEach((x) => {
            const i = prev.indexOf(x.data.id);
            if (i > -1) prev.splice(prev.indexOf(i), 1);
          });
      } else if (c.children.length > 0) {
        prev.push(c.data.id);
      }
      return prev;
    });
  };

  const onCategoryMarkupPercentageChange = (
    e: ChangeEvent<HTMLInputElement>,
    categoryIds: number[]
  ) => {
    const enteredNumber = parseInt(e.target.value);
    if (
      Number.isNaN(enteredNumber) && e.target.value !== '' ||
      enteredNumber < 1 ||
      enteredNumber > 99
    ) {
      return;
    }

    const enteredNumberInUnitFractions = enteredNumber / 100;
    categoryIds.forEach((categoryId) => {
      setCombinedCategories((previousCategories) =>
        previousCategories.map((category) =>
          category.id === categoryId
            ? { ...category, markup: enteredNumberInUnitFractions }
            : category
        )
      );
    });
  };

  return (
    <Popup isOpen={isOpen} closeModal={closeModal}>
      <YesNo
        title={`${
          platformTypeDisplayNameMap[service.platform]
        } Product Markups`}
        closeModal={closeModal}
        stickyButtons={true}
        yesText={!syncingExternalCategories ? 'Save' : 'Saving...'}
        noText="Cancel"
        closeOnYes={false}
        yesAction={async () => {
          await syncExternalCategories({
            variables: { externalCategories: sliceOutExternalCategories(true) },
          });
          onSave();
          closeModal();
        }}
        noAction={() => {
          discardCategoryChanges();
          onCancel();
        }}
      >
        <SelectorWrapper>
          {rootCategory && (
            <CategoryList
              node={rootCategory}
              expandedCategories={expandedCategories}
              onToggleCategoryExpand={onToggleCategoryExpand}
              onCategoryMarkupPercentageChange={
                onCategoryMarkupPercentageChange
              }
            />
          )}
        </SelectorWrapper>
      </YesNo>
    </Popup>
  );
};

export default CustomProductMarkupPopup;
