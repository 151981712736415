import { Shop } from 'models/Shop';
import { createContext, useContext } from 'react';
export const ShopContext = createContext<
  Partial<{
    shopState: Shop;
    shopDispatch: Function;
  }>
>({});

export const useShopContext = () => useContext(ShopContext);
export const useIsWholesalerShop = () =>
  useShopContext().shopState?.typeEnum === 'WHOLESALER';
export default useShopContext;
