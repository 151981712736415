import { RemoteReportsProvider } from 'contexts/remote-reports';
import { CategoriesProvider } from 'contexts/categories';
import { PermissionsProvider } from 'contexts/permissions';
import { ProductsProvider } from 'contexts/products';
import React, { useEffect } from 'react';
import App from 'next/app';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import AppLayout from 'containers/AppLayout/AppLayout';
import { useDeviceType } from 'helper/useDeviceType';
import { parseCookies } from 'helper/parseCookies';
import { AuthProvider } from 'contexts/auth/auth.provider';
import { GlobalStyle } from 'styles/Global';
import { ShopProvider } from 'contexts/shop/shop.provider';
import Log from 'helper/monitoring';
import Package from 'package.json';
import IntercomWrapper from 'containers/IntercomWrapper/IntercomWrapper';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import DepotShopProvider from '../contexts/depot-shop/depot-shop.provider';
import { ToastContainer } from 'react-toastify';

const ExtendedApp = function ExtendedApp({
  Component,
  pageProps,
  userAgent,
  locale,
  query,
}) {
  const deviceType = useDeviceType(userAgent);
  const deployment = process.env.DEPLOY_ENV || 'localhost';
  const environment = process.env.DEVO_ENV;
  const isLocal = !process.env.NEXT_PUBLIC_DEVO_ENV;

  useEffect(() => {
    console.log(`VERSION ${Package.version}`);
    Log.info(`DEPLOY_ENV ${process.env.NEXT_PUBLIC_DEVO_ENV}`, 'general', null);
    Log.info(`DEVO_ENV ${environment}`, 'general', null);
  }, []);

  // Sentry
  useEffect(() => {
    // GTM: https://medium.com/@rodolfo.3/sentry-for-react-gtm-and-express-41c869021990
    if (isLocal) return;

    const sampleRate = environment === 'stable' ? 1.0 : 0.25;
    Log.info('Initializing Sentry...', 'sentry', { sampleRate });

    Sentry.init({
      dsn: process.env.SENTRY_URL,
      environment: environment,
      release: 'devo-web-app@' + Package.version,
      debug: environment === 'stable',
      integrations: [new TracingIntegrations.BrowserTracing()],
      tracesSampleRate: sampleRate,
      beforeSend: function (event, hint) {
        var gtm = false;
        event.extra = event.extra || {};
        event.tags = event.tags || {};
        event.tags.logger = event.tags.logger || '_default_';
        return event;
      },
    });
    Sentry.setContext('Devo', {
      env: environment,
      deployment: deployment,
    });
  }, []);

  return (
    <Sentry.ErrorBoundary fallback={ErrorBoundary as any} showDialog>
      <AuthProvider>
        <ShopProvider>
          <DepotShopProvider>
            <PermissionsProvider>
              <ProductsProvider>
                <CategoriesProvider>
                  <RemoteReportsProvider id={1}>
                    <GlobalStyle />
                    <IntercomWrapper deviceType={deviceType} />

                    <AppLayout {...pageProps} deviceType={deviceType}>
                      <Component {...pageProps} deviceType={deviceType} />
                      <ToastContainer
                        draggable={false}
                        autoClose={1250}
                        theme="colored"
                        hideProgressBar
                        pauseOnFocusLoss={false}
                      />
                    </AppLayout>
                  </RemoteReportsProvider>
                </CategoriesProvider>
              </ProductsProvider>
            </PermissionsProvider>
          </DepotShopProvider>
        </ShopProvider>
      </AuthProvider>
    </Sentry.ErrorBoundary>
  );
};

export default ExtendedApp;

ExtendedApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  const { req, query } = appContext.ctx;
  const userAgent = req ? req.headers['user-agent'] : navigator.userAgent;
  const { locale } = parseCookies(req);
  return { ...appProps, userAgent, query };
};
