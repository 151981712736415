import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import WholesaleLogo from 'components/Wholesale/WholesaleLogo'
import React, { useEffect, useState } from 'react'
import dynamic from 'next/dynamic';
import { Form } from 'react-bootstrap'
import { Field } from '../../../styles/Form'
import { WholesalerMenuOption } from '../AllWholesaleProducts'
import { NoAccount } from './SetupPopup.style'
const Popup = dynamic(() => import('components/Popup/Popup'));
const YesNo = dynamic(() => import('components/Popup/YesNo'));

export type SetupPopupProps = {
  isOpen: boolean;
  closeModal: () => void;
  provider: WholesalerMenuOption;
}

const Setup: React.FC<SetupPopupProps> = ({ provider: _provider }) => {

  const [provider, setProvider] = useState<WholesalerMenuOption>(_provider);
  useEffect(() => setProvider(_provider), [_provider]);

  const onCustomerIdChange = e => {
    let val = e.target.value.toString() || "";
    if (val.length > 0) {
      setProvider({ ...provider, customerId: e.target.value });
    }
  }

  return (
    <div>
      <p>Enter your customer number below to connect to your {provider.name} account.</p>
      <WholesaleLogo wholesaler={_provider.id} height={24} marginTop={20} marginBottom={20} />

      {!!provider.signUpHref &&
        <NoAccount href={provider.signUpHref} target="_blank">
          <div className="content">
            <p>Don't have a {provider.name} account?</p>
            <span>Create an account on {provider.name}'s website.</span>
          </div>
          <FontAwesomeIcon icon={faExternalLinkAlt} color="var(--devo-blue)" />
        </NoAccount>
      }

      <Form>
        <Form.Group>
          <Form.Label>
            Customer Number
          </Form.Label>
          <Field
            id="customerId"
            value={provider.customerId}
            onChange={onCustomerIdChange}
            type="text"
            placeholder="Enter customer number..."
          />
        </Form.Group>
      </Form>
    </div>
  )
}

const SetupPopup: React.FC< SetupPopupProps> = props => {

  const { isOpen, closeModal, provider } = props;

  if (!provider) return <></>;

  return (
    <Popup isOpen={isOpen} closeModal={closeModal}>
      <YesNo
        title={`Connect to ${provider.name}`}
        noText="Cancel"
        yesText="Confirm"
        closeModal={closeModal}>
        <Setup
          {...props}

        />
      </YesNo>
    </Popup>
  )
}

export default SetupPopup


