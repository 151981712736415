import { isLocalEnvironment } from 'helper/environment'
import styled, { createGlobalStyle, CSSProperties } from 'styled-components';
import { MobileDrawerModal } from 'containers/AppLayout/AppLayout.style';
import { ModalPopup } from 'components/Popup/Popup.style';
import { ClampTextLines, DropdownStyling, HideScrollBars, InputSelectNoArrows, PreventTextSelectStyle } from 'styles/helper'
import { Colors, Spacings, Other } from './constants';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { MouseEventHandler } from 'react';

export const StyleBreakpoints = {
  TINY: 450 as const,
  SMALL: 600 as const,
  MEDIUM: 768 as const,
  LARGE: 992 as const,
  HUGE: 1200 as const
}

export const GlobalStyle = createGlobalStyle`
  :root {
    ${Colors}
    ${Spacings}
    ${Other}
  }

  ${MobileDrawerModal}
  ${ModalPopup}
  ${PreventTextSelectStyle}
  ${InputSelectNoArrows}
  ${HideScrollBars}
  ${ClampTextLines}
  ${DropdownStyling}
`;

export const Content = styled.div`
    width: 700px;
    margin: 20px auto 0 auto;
    min-height: calc(100vh - 90px);
`;

export const TabHeader = styled.div`
  @media (min-width: 768px) {
    padding: 0 20px !important;
  }

  .nav {
    border: none;
    padding-bottom: 20px;
    position: sticky;
    background-color: #fff;
    z-index: 1000;

    .nav-item {
      border: none;

      h4 {
        margin: 0;
        padding-bottom: 5px;
        color: #757575;
      }

      .nav-item:focus {
        outline: none;
      }
    }
  
    .active h4 {
      border-bottom: 2px var(--devo-red) solid;
      color: var(--devo-red);
      font-weight: 700;
    }

    .nav-item:hover h4 {
      color: var(--devo-red);
    }

    .nav-item:focus {
      outline: none;
    }
  }

  .tab-content {
    margin: 0 5px;
  }
`;

export const Title = styled.h1`
    font-weight: 700;
    font-size: 30px;
`;

export const Title2 = styled.h2`
    font-weight: 700;
    font-size: 24px;
`;

export const Title3 = styled.h3`
    font-weight: 800;
    font-size: 20px;
`;

export const Label = styled.p`
  margin: 0;
  font-size: 16px;
  color: var(--primary-label-color);
  
  &.strike {
    font-size: 14px;
    color: var(--secondary-label-color);
    text-decoration: line-through;  
  }
`;

export const Label2 = styled.p`
  font-size: 15px;
  color: var(--secondary-label-color);
`;

export const Subheadline = styled.h4`
  font-size: 14px;
`;

const _Caption = styled.span`
  font-size: 14px;
  color: var(--secondary-label-color);
`;
export const Caption = ({ children, style = {}, className = "" }) => (
  <_Caption className={"caption " + className} style={style}>{children}</_Caption>
);

export const Link = styled.a`
  &, &:not([href]) {
    color: var(--devo-blue);
    font-weight: 600;
    cursor: pointer;
  }
  
  &:hover, &:not([href]):hover {
    color: var(--devo-dark-blue);
  }
`;

export const Module = styled.div`
  background-color: var(--background-color);
  box-shadow: var(--devo-shadow);
  border-radius: var(--devo-border-radius);

  .loader {
    margin: 0 auto;
  }

  & > .content {
    padding: 24px;
  }
 
  @media print {
    box-shadow: none !important;
    border: 1px var(--separator-color) solid !important;  
  }
`;

interface ModuleHeaderProps {
  noHeader?: boolean;
  padding?: boolean;
  mobileColumn?: boolean;
  gap?: number;
  mobileScroll?: boolean;
  $symmetricBorder?: boolean;
}
export const ModuleHeader = styled.div<ModuleHeaderProps>`
  display: flex;
  align-items: center;
  padding: 20px 24px;
  border-radius: var(--devo-border-radius) var(--devo-border-radius) 0 0;
  ${(props) =>
    props.$symmetricBorder
      ? `border-top: 2px solid var(--separator-color);
  border-top-right-radius: 0;
  border-top-left-radius: 0;`
      : ''}
  

  h3, p {
    margin: 0;
  }

  h3 {
    font-size: 20px;
    font-weight: 800;
  }

  @media screen and (max-width: 768px){
    overflow-x: ${props => props.mobileScroll && 'scroll'};
  }

  ${props => props.gap > 0 ? `gap: ${props.gap}px;` : ``}
  ${props => props.noHeader !== true ? "border-bottom: 2px var(--separator-color) solid;" : "padding-bottom: 10px;"}
  ${props => !!props.mobileColumn === true ? `
    @media (max-width: ${StyleBreakpoints.SMALL}px) {
      flex-flow: column nowrap;
      align-items: flex-start;
      gap: 4px;
    }
  ` : ""}
`;

export const TabContainer = styled.div`
  position: relative;
  
  ul.nav-pills {
    display: block;
    gap: 4px;
    overflow-x: scroll;
    height: 52px;
    white-space: nowrap;
    
    li.nav-item { display: inline-block; }
    li.nav-item:not(:last-child) { margin-right: 4px; }
    
    button.nav-link, button.nav-link.active {
      color: var(--secondary-label-color);
      border-radius: 0;
      background-color: transparent;
      border-radius: var(--devo-border-radius);
      
      &.active { 
        color: var(--devo-blue);
        font-weight: 600;
      }
      &:hover { background-color: var(--gray-5); }
      &:active { background-color: var(--gray-4); }
    }
    
    button.nav-link.active {
      position: relative;
      
      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: -8px;
        height: 3px;
        width: calc(100% - 6px);
        margin-left: 3px;
        background: var(--devo-blue);
        border-radius: 1.5px;
      }    
    }
  }

  .tab-content {
    margin-top: 32px;
  }
`;

type StackProps = {
  gap?: number;
}

export const HorizontalStack = styled.div<StackProps>`
  display: flex;
  flex-flow: column nowrap;
  ${props => props.gap ? `gap: ${props.gap}px;` : ""}
`;

// --------------------------------------------
// Button
// --------------------------------------------

type ButtonVariant = "" | "green" | "red" | "red-no-border" | "orange" | "orange-no-border" | "blue" | "blue-no-border" | "white";
type ButtonProps = { variant?: ButtonVariant; }
export const Button = styled.button<ButtonProps>`
  border: none;
  border-radius: var(--devo-border-radius);
  transition: all 0.2s;

  &:hover { text-decoration: none; }
  &:focus { outline: none; }

  ${props => () => {
    switch (props.variant ?? "white") {
      case "green": return `
        background-color: var(--devo-green);
        color: var(--white);
        font-weight: 600;
        transition: transform 0.1s;
        &:hover { transform: scale(1.1); }
        &:active { background-color: var(--devo-dark-green); }
      `;

      case "red": return `
        font-weight: 600;
        background-color: var(--devo-red);
        color: var(--white);
        font-weight: 600;
        & > svg { color: var(--white); }
        &:hover { background-color: var(--devo-dark-red); }
        &:active { background-color: var(--devo-dark-red); }
      `;

      case "red-no-border": return `
        background-color: transparent;
        color: var(--devo-red);
        font-weight: 800;
        &:hover { background-color: var(--devo-red); color: var(--white); }
        &:active { background-color: var(--devo-dark-red); color: var(--white); }
      `;

      case "orange": return `
        background-color: var(--devo-orange);
        color: var(--white);
        font-weight: 600;
        &:hover { background-color: var(--devo-dark-orange); }
        &:active { background-color: var(--devo-dark-orange); }
      `;

      case "orange-no-border": return `
        background-color: transparent;
        color: var(--devo-orange);
        font-weight: 800;
        &:hover { background-color: var(--devo-orange); color: var(--white); }
        &:active { background-color: var(--devo-dark-orange); color: var(--white); }
      `;

      case "blue": return `
        background-color: var(--devo-blue);
        color: var(--white);
        font-weight: 600;
        & > svg { color: var(--white); }
        transition: transform 0.1s;
        &:hover { transform: scale(1.05); }
        &:active { background-color: var(--devo-dark-blue); }
      `;

      case "blue-no-border": return `
        background-color: transparent;
        color: var(--devo-blue);
        font-weight: 800;
        &:hover { background-color: var(--devo-blue); color: var(--white); }
        &:active { background-color: var(--devo-dark-blue); color: var(--white); }
      `;

      case "white": return `
        padding: 5px 10px;
        color: var(--primary-label-color);
        background-color: var(--background-color);
        border: var(--devo-border);
        font-weight: 400;
        &:hover {
          background-color: var(--secondary-background-color);
          border-color: var(--primary-label-color);
          box-shadow: var(--devo-shadow);
        }
        &:active {
          color: var(--primary-label-color);
          background-color: var(--secondary-background-color);
        }
      `;

      default: return "";
    }
  }}  
`;

export const LinkButton = styled.a<ButtonProps>`
  display: inline-block;
  border: none;
  border-radius: var(--devo-border-radius);
  transition: all 0.2s;
  cursor: pointer;

  &:hover { text-decoration: none; }
  &:focus { outline: none; }

  ${props => () => {
  switch (props.variant ?? "white") {
    case "green": return `
        background-color: var(--devo-green);
        color: var(--white);
        font-weight: 600;
        transition: transform 0.1s;
        &:hover { transform: scale(1.1); }
        &:active { background-color: var(--devo-dark-green); }
      `;

    case "red": return `
        font-weight: 600;
        background-color: var(--devo-red);
        color: var(--white);
        font-weight: 600;
        & > svg { color: var(--white); }
        &:hover { background-color: var(--devo-dark-red); }
        &:active { background-color: var(--devo-dark-red); }
      `;

    case "red-no-border": return `
        background-color: transparent;
        color: var(--devo-red);
        font-weight: 800;
        &:hover { background-color: var(--devo-red); color: var(--white); }
        &:active { background-color: var(--devo-dark-red); color: var(--white); }
      `;

    case "orange": return `
        background-color: var(--devo-orange);
        color: var(--white);
        font-weight: 600;
        &:hover { background-color: var(--devo-dark-orange); }
        &:active { background-color: var(--devo-dark-orange); }
      `;

    case "orange-no-border": return `
        background-color: transparent;
        color: var(--devo-orange);
        font-weight: 800;
        &:hover { background-color: var(--devo-orange); color: var(--white); }
        &:active { background-color: var(--devo-dark-orange); color: var(--white); }
      `;

    case "blue": return `
        background-color: var(--devo-blue);
        color: var(--white);
        font-weight: 600;
        & > svg { color: var(--white); }
        transition: transform 0.1s;
        &:hover { transform: scale(1.05); }
        &:active { background-color: var(--devo-dark-blue); }
      `;

    case "blue-no-border": return `
        background-color: transparent;
        color: var(--devo-blue);
        font-weight: 800;
        &:hover { background-color: var(--devo-blue); color: var(--white); }
        &:active { background-color: var(--devo-dark-blue); color: var(--white); }
      `;

    case "white": return `
        padding: 5px 10px;
        color: var(--primary-label-color);
        background-color: var(--background-color);
        border: var(--devo-border);
        font-weight: 400;
        &:hover {
          background-color: var(--secondary-background-color);
          border-color: var(--primary-label-color);
          box-shadow: var(--devo-shadow);
        }
        &:active {
          color: var(--primary-label-color);
          background-color: var(--secondary-background-color);
        }
      `;

    default: return "";
  }
}}  
`;

type IconButtonProps = {
  variant?: ButtonVariant;
  icon: IconDefinition;
  color?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  href?: string;
  className?: string;
  style?: CSSProperties;
  children?: React.ReactNode;
  openInNewTab?: boolean;
}
export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => (
  <Button ref={ref} variant={props.variant || "white"} onClick={props.onClick} style={props.style} className={props.className}>
    <FontAwesomeIcon icon={props.icon} color={props.color || "var(--secondary-label-color)"} />
    {!!props.children &&
      <>
        &nbsp;&nbsp;
        {props.children}
      </>
    }
  </Button>
));
export const IconLink = React.forwardRef<HTMLAnchorElement, IconButtonProps>((props, ref) => (
  <LinkButton ref={ref} variant={props.variant || "white"} href={props.href} style={props.style} className={props.className} target={props.openInNewTab ? "_blank" : null}>
    <FontAwesomeIcon icon={props.icon} color={props.color || "var(--secondary-label-color)"} />
    {!!props.children &&
    <>
      &nbsp;&nbsp;
      {props.children}
    </>
    }
  </LinkButton>
));

// --------------------------------------------
// Fields
// --------------------------------------------

export const PrefixField = styled.div`
  position: relative;
  
  input {
    padding-left: 1.75rem;
  }
  
  &::before {
    position: absolute;
    top: 13px;
    left: 0.8em;
    color: #495057;
  }
  
  @supports (-moz-appearance:none) {
    &::after {
      left: 0.8em;
    }
  }
`;

export const SuffixField = styled.div`
  position: relative;
  
  &::after {
    position: absolute;
    top: 2px;
    right: .5em;
    transition: all .05s ease-in-out;
  }
  
  &:hover::after,
    div:focus-within::after {
    right: 1.5em;
  }
  
  @supports (-moz-appearance:none) {
    &::after {
      right: 1.5em;
    }
  }
`;

// --------------------------------------------
// Table
// --------------------------------------------

export const TableContainer = styled.div`
  background-color: var(--background-color);
  border-radius: var(--devo-border-radius);
  box-shadow: var(--devo-shadow);
  margin: 20px;

  .table-responsive {
    padding: 20px;
  }

  .table th, .table td {
    padding: .5rem;
    vertical-align: middle;
  }
  
  td.shrink {
    width: 0.1%;
    white-space: nowrap;
    padding: 0.5rem 0.7rem;
  }

  @media (max-width: ${StyleBreakpoints.MEDIUM}px) {
    margin: 12px 0;
  }
`;

type TableLayoutProps = { width?: number; }

export const Cth = styled.th<TableLayoutProps>`
  text-align: center;
  width: ${props => props.width || 'auto'};
  white-space: nowrap;
`;
export const Rth = styled.th<TableLayoutProps>`
  text-align: right;
  width: ${props => props.width || 'auto'};
`;
export const Ctd = styled.td<TableLayoutProps>`
  text-align: center;
  width: ${props => props.width || 'auto'};
`;
export const Rtd = styled.td<TableLayoutProps>`
  text-align: right;
  width: ${props => props.width || 'auto'};
`;

export const TableHeader = styled.thead`
  th {
    font-size: 12px;
    color: var(--secondary-label-color);
    border: none;
    padding: 20px 5px;
    border-top: none !important;
    border-bottom: none !important;
    text-transform: uppercase;
  }

  th a:not(.dropdown-item) {
    cursor: pointer;

    &:hover {
      color: var(--devo-red);
    }
  }
`;

type PlaceholderProps = { width?: number; height: number; vMargin?: number; hMargin?: number; }
export const Placeholder = styled.div<PlaceholderProps>`
  width: ${props => !!props.width ? `${props.width}px` : '100%'};
  height: ${props => props.height}px;
  border-radius: ${props => props.height/2}px;
  margin: ${props => props.vMargin != undefined ? `${props.vMargin}px` : 'auto'} ${props => props.hMargin != undefined ? `${props.hMargin}px` : 'auto'};
  background: var(--gray-6);

  animation-duration: 5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, var(--gray-6) 10%, var(--gray-5) 18%, var(--gray-6) 33%);
  background-size: ${props => props.width*5}px ${props => props.height}px;
  position: relative;

  @keyframes placeholderShimmer {
    from { background-position: 100% 0; }
    to { background-position: -100% 0; }
  }
`;

// --------------------------------------------
// Flex
// --------------------------------------------

type FlexHCenterDivProps = { spaceBetween?: boolean; gap?: number; alignItems?: string; flexDirection?: string; mobileColumn?: boolean; }
export const FlexHCenterDiv = styled.div<FlexHCenterDivProps>`
  display: flex;
  align-items: ${props => props.alignItems || "center"};
  ${props => !!props.flexDirection ? `flex-direction: ${props.flexDirection};` : ""}
  ${props => props.spaceBetween ? "justify-content: space-between;" : ""}
  ${props => !!props.gap ? `gap: ${props.gap}px;` : ""}
  @media (max-width: ${StyleBreakpoints.SMALL}px) {
    ${props => props.mobileColumn ? `flex-direction: column; align-items: flex-start; gap: 8px;` : ""}
  }
`;

type DynamicFlexDivProps = { spaceBetween?: boolean; alignItems?: string; gap?: number; layout?: string; mobileLayout?: string; breakpoint?: number };
export const DynamicFlexDiv = styled.div<DynamicFlexDivProps>`
  display: flex;
  align-items: ${props => props.alignItems || "center"};
  flex-flow: ${props => props.layout || "row"} nowrap;
  ${props => props.spaceBetween ? "justify-content: space-between;" : ""}
  ${props => !!props.gap ? `gap: ${props.gap}px;` : ""}
  
  @media (max-width: ${props => props.breakpoint || StyleBreakpoints.SMALL}px) {
    flex-flow: ${props => props.mobileLayout || "column"} nowrap;
  }
`;

export const Spacer = styled.div`
  flex-grow: 2;
`;

// --------------------------------------------
// Debug
// --------------------------------------------

const _DebugText = styled.pre`
  color: red;
  margin: 4px;
`;
export const DebugText = ({ children }) => {
  return isLocalEnvironment() ? <_DebugText>{children}</_DebugText> : <></>;
}

export const cubic = (a = 0.4, b = 0, c = 0.2, d = 1) =>
  `cubic-bezier(${a}, ${b}, ${c}, ${d})`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;