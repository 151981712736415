import useProductFudger from 'hooks/useProductFudger'
import React, { useState, createContext, useContext, useEffect } from 'react'
import { uniq } from 'helper/array'
import useShopContext from 'contexts/shop/shop.context'
import { getPosProducts } from 'devoapi/pos_products'
import Log from 'helper/monitoring'
import PosProduct from 'models/PosProduct'

type UPC = number;
export type ProductError = any;

export interface ProductResponse {
  upc: number;
  product: PosProduct;
  loading: boolean;
  error: ProductError;
}

interface ProductsState {
  products: { [key: number]: PosProduct };
  loading: boolean;
  getProductByUpc: (upc: number) => ProductResponse;
  requestProducts: (upcs: number[]) => void;
}

const ProductsContext = createContext<Partial<ProductsState>>({});

export const ProductsProvider: React.FunctionComponent<any> = ({ children }) => {

  const { shopState } = useShopContext();
  const { getMissingPosProduct, optimiseProduct } = useProductFudger();
  let shopUuid = shopState?.uuid;

  const [products, setProducts] = useState<{[key: number] : PosProduct}>({
    0: getMissingPosProduct(0)
  });
  const [loadingUpcs, setLoadingUpcs] = useState<UPC[]>([]);

  let successUpcs = Object.values(products).map(x => x.upc);

  useEffect(() => {
    async function fetch() {
      let inFlightUpcs = [...loadingUpcs];
      Log.debug("Fetching...", "product-context", { requested: inFlightUpcs.length });

      let response = await getPosProducts(shopUuid, inFlightUpcs);
      let results = (response?.results || []).map(optimiseProduct);
      let responseUpcs = results.map(x => x.upc);
      let failUpcs = inFlightUpcs.filter(upc => !responseUpcs.includes(upc));
      let failedProducts = failUpcs.map(upc => getMissingPosProduct(upc));

      saveProducts(results.concat(failedProducts));
      setLoadingUpcs(prev => prev.filter(x => !inFlightUpcs.includes(x)));
      let debugData = { requested: inFlightUpcs.length, response: responseUpcs.length, failed: failUpcs.length };
      Log.debug("Response...", "product-context", debugData);
    }

    if (shopUuid && loadingUpcs.length > 0) fetch();
  }, [loadingUpcs]);

  const getProductByUpc = (upc: number): ProductResponse => {
    let product = products[upc] || null;
    let loading = loadingUpcs.includes(upc);
    let error = (!product && !loading) ? "ERROR" : null;
    return { upc, product, loading, error };
  }

  const saveProducts = (products: PosProduct[]) => {
    if (products && products.length > 0) {
      Log.debug(`Adding ${products.length} products...`, "product-context");
      setProducts(prev => {
        let result = { ...prev };
        products.forEach(product => result[product.upc] = product);
        return result;
      });
    }
  }

  const requestProducts = (upcs: number[]) => {
    let upcsToFetch = upcs.filter(x => !(x in products));
    Log.debug("Requested products...", "product-context", {
      exist: successUpcs.length,
      requested: upcs.length,
      toRequest: upcsToFetch.length
    });
    if (upcsToFetch.length > 0) {
      setLoadingUpcs(prev => uniq([...prev, ...upcsToFetch]));
    }
  }

  const props = {
    products,
    loading: loadingUpcs.length > 0,
    getProductByUpc,
    requestProducts,
  }

  return (
    <ProductsContext.Provider value={props}>
      {children}
    </ProductsContext.Provider>
  );
};

export const useProductsContext = () => useContext(ProductsContext);
export default useProductsContext;
