import styled from 'styled-components';

export const Product = {
  List: styled.div`
    overflow-y: scroll;
    border: 1px var(--separator-color) solid;
    border-radius: var(--devo-border-radius);
    @media (min-width: 576px) {
      max-height: 70vh;
    }
  `,
  Wrapper: styled.a`
    display: flex;
    align-items: center;
    padding: 10px 0;

    .loader {
      margin: 0 auto;
    }

    &.single {
      padding: 10px;
      border: 1px var(--separator-color) solid;
      border-radius: var(--devo-border-radius);
      margin-bottom: 20px;
    }

    &.list {
      &:not(:last-child) {
        border-bottom: 1px var(--separator-color) solid;
      }

      img {
        margin-left: 10px;
      }

      &:hover {
        background-color: var(--secondary-background-color);
        cursor: pointer;
      }

      &:active {
        background-color: var(--gray-5);
        cursor: pointer;
      }
    }
    
    &.bordered {
      padding: 10px;
      border: 1px var(--separator-color) solid;
      border-radius: var(--devo-border-radius);
    }
    
    .mobile-data-item {
      padding: 8px 0;
    }

    @media (max-width: 1024px) {
      align-items: flex-start;
      flex-flow: column nowrap;
    }
  `,
  Image: styled.img`
    height: 56px;
    width: 56px;
    object-fit: contain;
    margin-right: 12px;
  `,
  Title: styled.p`
    font-weight: 800;
    margin: 0;
  `,
  Content: styled.div`
    display: flex;
    flex-flow: row nowrap;
    flex-grow: 2;
    width: 100%;
    align-items: center;
  `,
  Right: styled.div`
    flex-grow: 2;
  `,
  Blocks: styled.div`
    display: flex;
  `,
  Block: styled.div`
    display: flex;
    flex-flow: column nowrap;

    &:not(:last-child) {
      margin-right: 20px;
    }

    span {
      font-size: 12px;
      color: var(--secondary-label-color);
    }

    p {
      font-size: 16px;
      color: var(--primary-label-color);
      margin: 0;
    }

    p > span {
      font-size: 14px;
      color: var(--primary-label-color);
    }
  `,
  Inserted: styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-self: center;
    margin: auto 8px;
  `,
  Chevron: styled.div`
    align-self: center;
    margin: auto 20px;
    color: var(--border-color);
  `,
  Status: styled.div`
    a.dropdown-item {
      color: var(--primary-label-color) !important;
    }
  `
}

export const ManualForm = {
  Wrapper: styled.div`
    label > span {
      font-size: 14px;
      color: var(--secondary-label-color);
    }
  `,
}

export const Quantity = {
  Wrapper: styled.div`
    display: flex;
    flex-flow: row wrap;
  `,
  Block: styled.a`
    background-color: var(--secondary-background-color);
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 20px;
    border-radius: var(--devo-border-radius);
    text-align: center;
    margin: 0 20px 20px 0;
    cursor: pointer;

    &:hover { background-color: var(--gray-4); }
    &:active { background-color: var(--gray-2); }

    &.selected { 
      color: var(--white); 
      background-color: var(--devo-red);
      &:hover { color: var(--gray-5); }
      &:active { background-color: var(--devo-dark-red); }
    }

    @media (max-width: 768px) {
      width: 50px;
      height: 50px;
      line-height: 50px;
      margin: 0 8px 8px 0;
    }
  `,
}

export const SplitHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 4px 4px;

  .enter-manually {
    color: var(--devo-blue);
    font-weight: 600;
    cursor: pointer;

    &:hover {
      color: var(--devo-dark-blue);
    }
  }

  @media (max-width: 575px) {
    margin: 24px 0 8px;
  }
`;

export const Suggested = styled.div`
  .nav-item {
    font-size: 14px;
    margin-bottom: 4px;
    width: 100%;
    text-align: center;
  }

  .nav-item a {
    color: var(--devo-blue);
  }

  @media (min-width: 576px) {
    #suggested-sidebar {
      padding-right: 0;
    }
  }
`;

export const EmptyState = {
  Wrapper: styled.div`
    text-align: center;
    margin: 75px 0;

    svg {
      color: var(--devo-orange);
      margin: 12px 0;
    }

    p {
      color: var(--secondary-label-color);
    }

    .loader {
      margin: 0 auto;
    }
  `,
}