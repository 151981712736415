import styled from 'styled-components'
import { StyleBreakpoints } from 'styles/Global'

export const ListWrapper = styled.div`
  width: 100%;
`;  

export const List = styled.div`
  .loader { margin: 32px auto; }
`;

export const Section = styled.div`
  margin: 20px 0;
  
  & > .add:not(.round) { border-radius: 8px 8px 0 0; }
  & > .add.round { border-radius: 8px; } 
  .item:last-child { border-radius: 0 0 8px 8px; }
`;

export const RowContent = styled.div`
  display: flex;
  flex-flow: column nowrap;

  p {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }

  p.untitled {
    font-weight: 400;
    opacity: 0.5;
  }
`;

export const RowLayout = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  flex-grow: 2;
  
  @media (min-width: ${StyleBreakpoints.SMALL+1}px) {
    .blocks {
      text-align: right;
      justify-content: flex-end;
    }
  }
  
  @media (max-width: ${StyleBreakpoints.SMALL}px) {
    flex-flow: column nowrap;
    align-items: flex-start;
    gap: 4px;
    .date { display: none; }
  }
`;

export const Row = styled.a`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 16px 24px;
  background-color: var(--background-color);
  border: 1px var(--separator-color) solid;

  &.add { 
    padding: 20px 25px;
    color: var(--devo-red);
  }
  
  &.empty, &:not([href]).empty {
    display: block;
    padding: 24px;
    text-align: center;
    color: var(--secondary-label-color);
    font-weight: 600;
  }
  
  & > .flex { width: 100%; }

  img {
    height: 50px;
    width: 50px;
  }

  &:not(last-child) { border-bottom: none; }
  &:not(.static) {
    cursor: pointer;
    &:focus { background-color: var(--secondary-background-color); }
    &.icon:hover { color: var(--devo-dark-red); }
    &:hover { background-color: #f7f7f7; }
  }
`;

export const Hover = styled.div`
  background-color: red;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 20px;

  -webkit-backdrop-filter: var(--blur-filter);
  backdrop-filter: var(--blur-filter);
  background-color: var(--blur-color);

  box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 0.1);

  button:not(last-child) {
    margin-right: 10px;
  }
`;