import React from 'react'
import styled from 'styled-components';
import Loader from '../../components/Loader/Loader'

export const LayoutWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background-color: var(--secondary-background-color);

  div, input, span, p, a {
    font-family: 'Inter', 'Segoe UI', sans-serif;
  }

  h1 {
    font-size: 32px;
    font-weight: 800;
  }

  h2 {
    font-size: 28px;
    font-weight: 600;
  }

  a.dropdown-item { 
    color: var(--primary-label-color); 
  }

  &.mobile {
    height: auto;
    /* overflow-y: scroll; */
  }

  .sticky-outer-wrapper.mobile-navbar {
    z-index: 2000;
  }

  form > div:not(:last-child), .form > div:not(:last-child) { 
    margin-bottom: 20px; 
  }

  .bg-primary { background-color: var(--devo-blue); }
  .bg-success { background-color: var(--devo-green); }
  .bg-purple { background-color: var(--devo-purple); }
  .bg-primary, .bg-secondary, .bg-success, .bg-danger, .bg-info, .bg-dark { color: #fff !important; }
  .bg-warning, .bg-light { color: #212529 !important }
  
  .table-responsive > .table > tbody > tr:last-child > td {
    border-bottom-style: none;
  }
  
  .sticky-inner-wrapper {
    z-index: 10;
  }
  
  .error-message {
    color: red;
    font-weight: 600;
    font-size: 15px;
  }
  
  @media print {
    .hideForPrint {
      display: none;
    }
  }
`;

interface PageWrapperProps { navBarHeight: number; }
export const PageWrapper = styled.div<PageWrapperProps>`
`;

type SidebarWrapperProps = { animate: boolean; }
export const SidebarWrapper = styled.div<SidebarWrapperProps>`
  height: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: var(--background-color);
  ${props => props.animate ? "transition: transform 0.3s;" : ""}
  
  & > * {
    width: 215px;
  }
  
  &.hidden {
    transform: translateX(-215px);
  }
  
  @media print {
    transform: translateX(-215px);
    display: none;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: var(--secondary-background-color);
  transition: margin-left 0.3s;
  
  &:not(.login) {
    margin-left: 215px;
    max-width: calc(100% - 215px);
  }
  
  &.hideDrawer {
    margin-left: 0px;
    max-width: 100%;
  }
  
  @media print {
    margin-left: 0px !important;
    max-width: 100%;
  }
`;

export const MobileDrawerModal = `
  .devo-dialog {
    margin: 0 !important;
  }

  .modal-content {
    width: 100vw !important;
    padding: 40px 0 !important;
    border-radius: 0 !important;
    border: none !important;
  }

  .modal.fade .devo-dialog {
    transform: translateY(-200px) !important;
  }

  .modal.show .devo-dialog {
    transform: none !important;
  }
`;

export const MobileSidebarWrapper = styled.div`
  background-color: var(--background-color);
`;

export const MobileContentWrapper = styled.div<PageWrapperProps>`
  display: block;
  width: 100%;
  background-color: var(--secondary-background-color);
  min-height: calc(100vh - ${props => props.navBarHeight}px);

  &:not(.login) > div {
    padding: 10px;
  }
`;

export const Container = styled.div`
  height: 100%;
  background-color: var(--secondary-background-color);
`;

export const Wrapper = styled.div`
  padding: 50px 0;
  width: 90%;
  max-width: 850px;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 95%;
    padding: 25px 0;
  }
  
  @media print {
    width: 95% !important;
    padding: 25px 0 !important;
  }
`;

const FullScreenLoaderContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const FullScreenLoader: React.FC = () => (
  <FullScreenLoaderContainer>
    <Loader />
  </FullScreenLoaderContainer>
)