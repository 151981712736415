import React, { useEffect, useState } from 'react';
import PopupPosProduct from 'components/Previews/PopupPosProduct';
import { useMutation } from '@apollo/react-hooks';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useBulkStockProvider from 'hooks/useBulkStockProvider';
import PosProduct from 'models/PosProduct';
import { Form } from 'react-bootstrap';
import { INSERT_STOCK_ENTRY } from 'devoapi/stock';
import { DEFAULT_STOCK_MESSAGE } from '../../constants/constants';
import useShopContext from '../../contexts/shop/shop.context';
import Log from '../../helper/monitoring';
import { StockSummaryProduct } from '../../models/Stock';
import { Field } from '../../styles/Form';
import Popup from '../Popup/Popup';
import YesNo from '../Popup/YesNo';
import { StockStyle } from './StockPopup.style';

type PosStockProps = {
  currentSummary?: StockSummaryProduct;
  amount: number;
  setAmount: (amount: number) => void;
  details: string;
  setDetails: (details: string) => void;
  loading: boolean;
};

type PosStockPopupProps = {
  upc: number;
  summary?: StockSummaryProduct;
  isOpen: boolean;
  closeModal: (updatedData: StockSummaryProduct & { upc: number }) => void;
};

const Stock: React.FC<PosStockPopupProps & PosStockProps> = ({
  upc,
  currentSummary,
  amount: stockValue,
  setAmount,
  details,
  setDetails,
  loading,
}) => {
  const setStockValue = (val: number) => {
    if (!loading) setAmount(Math.max(0, val));
  };
  const decrement = () => setStockValue(stockValue - 1);
  const increment = () => setStockValue(stockValue + 1);

  let extraBlocks = {};
  extraBlocks['stock'] = currentSummary?.balance || 0;

  return (
    <StockStyle.Wrapper loading={loading}>
      <PopupPosProduct upc={upc} />

      <StockStyle.Content>
        <p>Enter a new stock value for this product below:</p>

        <StockStyle.FieldContainer>
          <StockStyle.StockButton onClick={decrement} loading={loading}>
            <FontAwesomeIcon icon={faMinus} />
          </StockStyle.StockButton>

          <Field
            type="number"
            placeholder={'Stock'}
            value={stockValue == 0 ? '' : stockValue.toFixed(0)}
            onChange={(e) => setStockValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') e.target.blur();
            }}
          />

          <StockStyle.StockButton onClick={increment} loading={loading}>
            <FontAwesomeIcon icon={faPlus} />
          </StockStyle.StockButton>
        </StockStyle.FieldContainer>

        <Form.Group>
          <Form.Label>
            Description <small>(optional)</small>
          </Form.Label>
          <Field
            type="text"
            placeholder={DEFAULT_STOCK_MESSAGE}
            value={details}
            onChange={(e) => setDetails(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') e.target.blur();
            }}
          />
        </Form.Group>
      </StockStyle.Content>
    </StockStyle.Wrapper>
  );
};

const PosStockPopup: React.FC<PosStockPopupProps> = (props) => {
  const { shopState } = useShopContext();
  const { isOpen, closeModal, upc } = props;

  const [summary, setSummary] = useState<StockSummaryProduct>(props.summary);
  useEffect(() => {
    Log.debug('Opening new stock popup...', 'stock', props.summary);
    configure(props.summary);
  }, [props.summary]);

  const configure = (p: StockSummaryProduct) => {
    setSummary(p);
    setAmount(p?.balance || 0);
  };

  const originalAmount = summary?.balance || 0;
  const [amount, setAmount] = useState(originalAmount);
  const [_details, setDetails] = useState(DEFAULT_STOCK_MESSAGE);
  const [error, setError] = useState(null);

  useEffect(() => {
    setDetails(DEFAULT_STOCK_MESSAGE);
    setError(null);
  }, [isOpen]);

  const { stockSummary } = useBulkStockProvider({ upcs: [upc ?? 0] });
  useEffect(() => {
    if (!stockSummary || !upc) {
      configure(null);
    } else {
      let stockProduct = stockSummary?.stockProduct?.[upc] || {};
      if (Object.keys(stockProduct).length > 0)
        configure(stockProduct as StockSummaryProduct);
      Log.debug('Fetching stock value for new popup...', 'stock', {
        stockSummary,
      });
    }
  }, [stockSummary]);

  const [insertStockEntry, { loading }] = useMutation(INSERT_STOCK_ENTRY);

  const onConfirm = async () => {
    let details = _details;
    if (details.length == 0) details = DEFAULT_STOCK_MESSAGE;

    setError(null);
    try {
      const variables = {
        productUpc: upc,
        shopUuid: shopState.uuid,
        amount,
        details,
        type: 'OVERRIDE',
      };
      const res = await insertStockEntry({ variables });

      const newSummary = { ...summary, balance: amount, upc };
      Log.debug('INSERT_STOCK_ENTRY onCompleted', 'stock', {
        res,
        summary,
        newSummary,
      });
      closeModal(newSummary);
    } catch (error) {
      setError('Something went wrong - please try again.');
      Log.warning('Failed to update product', 'products', error);
    }
  };

  return (
    <Popup isOpen={isOpen} closeModal={closeModal}>
      <YesNo
        title="Update Stock"
        yesLoading={loading}
        yesDisabled={loading || amount < 0 || amount == originalAmount}
        noText="Cancel"
        yesText="Confirm"
        yesAction={onConfirm}
        closeOnYes={false}
        closeModal={closeModal}
        stickyButtons
      >
        <Stock
          {...props}
          {...{
            currentSummary: summary,
            amount,
            setAmount,
            details: _details,
            setDetails,
            loading,
          }}
        />
        {error && <p style={{ color: 'red' }}>{error.toString()}</p>}
      </YesNo>
    </Popup>
  );
};

export default PosStockPopup;
