import Check from 'components/Check/Check';
import styled from 'styled-components';

interface CategoryProps { n: number; }
const categoryAnimationTime = 0.3;
const animation = `transition: max-height ${categoryAnimationTime}s, opacity ${categoryAnimationTime}s, padding-top ${categoryAnimationTime}s, padding-bottom ${categoryAnimationTime}s;`;

export const CategoryRow = styled.div`
  display: flex;
  align-items: center;

  .item {
    margin-left: 10px;
    flex-grow: 2;
  }
`;

export const CategoryItem = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 0;
  opacity: 0;
  padding: 0 20px 0 0;
  ${animation}

  svg {
    color: var(--devo-red);
  }

  &.showing {
    max-height: 50px;
    opacity: 1;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &.hasChildren {
    cursor: pointer;
  }
`;

export const CategoryCheck = styled(Check)`
  max-height: 0;
  opacity: 0;
  margin-left: ${props => {
    // @ts-ignore
    return props.n * 20;
  }}px;
  ${animation}

  &.showing {
    max-height: 50px;
    opacity: 1;
  }

  &:not(.showing) { 
    border: none;
  }
`;

export const SelectorWrapper = styled.div`
  width: 90%;
  
  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;