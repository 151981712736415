import styled from 'styled-components';
import { StyleBreakpoints } from 'styles/Global';

export const Container = styled.div`
  .recommended,
  .profit {
    display: block;
    margin: 15px 0 5px;
    color: var(--secondary-label-color);
    cursor: pointer;
    padding: 8px;
    margin: 4px -4px;
    border-radius: var(--devo-border-radius);

    span:first-child,
    svg {
      font-weight: 700;
      cursor: pointer;
      .recommended {
      }
      .profit {
        color: var(--devo-red);
      }
    }

    &:hover {
      color: var(--secondary-label-color);
      background-color: var(--secondary-background-color);
    }
  }

  .recommended {
    span:first-child,
    svg {
      color: var(--devo-blue);
    }
  }

  .recommended.price {
    margin: 0;
    padding: 0 4px;

    span.action {
      font-size: 14px;
    }

    span.average-price {
      font-size: 12px;
    }
  }

  .profit {
    span:first-child,
    svg {
      color: var(--devo-red);
    }
  }

  .profit-container {
    transition: all 0.1s;
    border-radius: var(--devo-border-radius);
    padding: 8px 16px;
    border: 1px var(--separator-color) solid;
  }

  .profit-container.open {
    .profit .svg-inline--fa {
      transform: rotate(180deg);
    }
  }

  .profit {
    .svg-inline--fa {
      transition: transform 0.1s;
    }
  }
`;

export const FieldContainer = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 768px) {
    flex-flow: column;

    & > div {
      padding: 10px 0;
    }
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 10px;

  h3 {
    font-size: 16px;
  }

  span {
    font-size: 14px;
    color: var(--secondary-label-color);
    margin-bottom: 15px;
  }

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
  @media (max-width: 768px) {
    &:first-child {
      padding-right: 0;
    }
    &:last-child {
      padding-left: 0;
    }
  }
`;

export const Link = styled.a`
  display: inline-block;
  margin: 25px 0 15px;
  font-weight: 700;
  color: var(--devo-red) !important;
  cursor: pointer;
`;

export const Note = styled.span`
  display: block;
  color: var(--secondary-label-color);
  font-size: 14px;
`;

export const Error = styled.span`
  display: block;
  margin-top: 20px;
  font-size: 14px;
  color: var(--devo-red);
`;

export const ShopPriceHeader = styled.h3`
  font-size: 18px;
  font-weight: 600;
`;

export const WeightBlock = styled.div`
  position: relative;
  top: -12px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media screen and (min-width: ${StyleBreakpoints.SMALL}px) {
    top: -39.6px;
  }
`;

export const WeightCheckWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

export const WeightLabel = styled.span`
  margin-bottom: 0;
`;

export const InputsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;

  & > #price-weight-field {
    width: 120px;
  }
`;
