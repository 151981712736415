const locale = 'en-GB';
const shopTimeZone = 'Europe/London';

export const SHORT_MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
] as const;
export const LONG_MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
] as const;
export const SHORT_DAYS_OF_WEEK = [
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
  'Sun',
] as const;
export const LONG_DAYS_OF_WEEK = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
] as const;

// ------------------------------
// Properties
// ------------------------------

export const isEqualTime = (d1: Date, d2: Date): boolean => {
  if (!d1 || !d2) return false;
  return (
    d1.getFullYear() == d2.getFullYear() &&
    d1.getMonth() == d2.getMonth() &&
    d1.getDate() == d2.getDate() &&
    d1.getHours() == d2.getHours() &&
    d1.getMinutes() == d2.getMinutes()
  );
};

export const isInToday = (d: Date): boolean => {
  return isInSameDate(d, new Date());
};

export const isInTomorrow = (d: Date): boolean => {
  const today = new Date();
  const tomorrow = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 1
  );
  return isInSameDate(d, tomorrow);
};

export const isInSameDate = (d1: Date, d2: Date = new Date()): boolean => {
  return (
    d1.getDate() == d2.getDate() &&
    d1.getMonth() == d2.getMonth() &&
    d1.getFullYear() == d2.getFullYear()
  );
};

export function getWeekOfYear(date: Date): number {
  const dateUTC = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
  const nearestThursday = new Date(
    Date.UTC(
      dateUTC.getFullYear(),
      dateUTC.getMonth(),
      dateUTC.getDate() + 4 - (dateUTC.getUTCDay() || 7)
    )
  );
  const firstDayOfYear = new Date(
    Date.UTC(nearestThursday.getFullYear(), 0, 1)
  );
  const weekNumber = Math.ceil(
    ((nearestThursday.getTime() - firstDayOfYear.getTime()) / 86400000 + 1) / 7
  );
  return weekNumber;
}

export const toHHMMSS = (input): string => {
  if (!input) return null;
  var sec_num = parseInt(input.toString(), 10); // don't forget the second param
  var hours: any = Math.floor(sec_num / 3600);
  var minutes: any = Math.floor((sec_num - hours * 3600) / 60);
  var seconds: any = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return hours + ':' + minutes + ':' + seconds;
};

export const getStartOfDay = (d: Date): Date => {
  d.setHours(0, 0, 0, 0);
  return d;
};

export const getEndOfDay = (d: Date): Date => {
  d.setHours(23, 59, 59, 999);
  return d;
};

// ------------------------------
// Manipulation
// ------------------------------

export const addSecondsToDate = (date: Date, numberOfSeconds: number): Date => {
  let newDate = new Date(date);
  newDate.setSeconds(newDate.getSeconds() + numberOfSeconds);
  return newDate;
};

export const addDaysToDate = (date: Date, numberOfDays: number): Date => {
  let newDate = new Date(date);
  newDate.setDate(newDate.getDate() + numberOfDays);
  return newDate;
};

export const addWeeksToDate = (date: Date, numberOfWeeks: number): Date => {
  return addDaysToDate(date, numberOfWeeks * 7);
};

export const addMonthsToDate = (date: Date, numberOfMonths: number): Date => {
  let newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + numberOfMonths);
  return newDate;
};

export const addMinutesToDate = (date: Date, minutes: number): Date => {
  let newDate = new Date(date);
  newDate.setMinutes(date.getMinutes() + minutes);
  return newDate;
};

export const addYearsToDate = (date: Date, years: number): Date => {
  var newDate = new Date(date);
  newDate.setFullYear(date.getFullYear() + years);
  return newDate;
};

// ------------------------------
// Formatting
// ------------------------------

export const formatToAPI = (d: Date): string => {
  return d?.toISOString();
};

export const formatDayOfWeekString = (
  d: Date,
  options: { relative?: boolean; style?: 'short' | 'long' } = {}
): string => {
  const { relative = true, style = 'long' } = options;
  if (relative && isInToday(d)) {
    return 'Today';
  } else if (relative && isInTomorrow(d)) {
    return 'Tomorrow';
  } else {
    const options: Intl.DateTimeFormatOptions = {
      weekday: style,
      timeZone: 'UTC',
    };
    return d.toLocaleString(locale, options);
  }
};

export const formatTinyDate = (d: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC',
  };
  return d.toLocaleString(locale, options);
};

export const formatShortDate = (d: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC',
  };
  return d.toLocaleString(locale, options);
};

export const formatLongDate = (d: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
    timeZone: 'UTC',
  };
  return d.toLocaleString(locale, options);
};

export const formatTimeString = (date: Date): string => {
  var options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC',
  };

  return date.toLocaleTimeString(locale, options);
};

export const formatShortDateTimeString = (date: Date): string => {
  return `${formatShortDate(date)}, ${formatTimeString(date)}`;
};

export const formatDayDateMonthStr = (
  date: Date,
  options: { weekday?: boolean } = {}
): string => {
  if (!date) return null;
  const { weekday = true } = options;
  const dayNumber = date.getDay();
  const sundayIndex = SHORT_DAYS_OF_WEEK.length - 1;
  var wd = SHORT_DAYS_OF_WEEK[dayNumber === 0 ? sundayIndex : dayNumber - 1];
  var d = date.getDate();
  var m = SHORT_MONTHS[date.getMonth()];

  if (weekday) {
    return `${wd}, ${d} ${m}`;
  } else {
    return `${d} ${m}`;
  }
};

// ------------------------------
// Date Range
// ------------------------------

type DateStepper = (date: Date, numberOfDays: number) => Date;
const _getAllDatesBetweenUsingStepper = (
  from: Date,
  to: Date,
  stepper: DateStepper
) => {
  let result = [to];
  let i = 1;
  while (result[result.length - 1] > from) {
    const d = stepper(to, -i);
    result.push(d);
    i += 1;
  }
  return result;
};

export const getAllDatesBetween = (from: Date, to: Date) =>
  _getAllDatesBetweenUsingStepper(from, to, addDaysToDate);
export const getAllWeekDatesBetween = (from: Date, to: Date) =>
  _getAllDatesBetweenUsingStepper(from, to, addWeeksToDate);
export const getAllMonthDatesBetween = (from: Date, to: Date) =>
  _getAllDatesBetweenUsingStepper(from, to, addMonthsToDate);
export const getAllYearDatesBetween = (from: Date, to: Date) =>
  _getAllDatesBetweenUsingStepper(from, to, addYearsToDate);

// ------------------------------
// Helpers
// ------------------------------

const timeZoneIsDifferentFromShop = () => {
  return getCurrentTimeZone() !== 'Europe/London';
};

const getCurrentTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getDateOfISOWeek = (week: number, y) => {
  let simple = new Date(y, 0, 1 + (week - 1) * 7);
  let dow = simple.getDay();
  let isoWeekStart = simple;
  if (dow <= 4) isoWeekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else isoWeekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return isoWeekStart;
};

export const formatDateToDDMMMYYYYHHMM = (date: Date) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ] as const;

  const day = String(date.getDate()).padStart(2, '0');
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day} ${month} ${year} ${hours}:${minutes}`;
};
