import styled from 'styled-components'

export const NoAccount = styled.a`
  display: flex;
  align-items: center;
  background-color: var(--secondary-background-color);
  margin-bottom: 24px;
  padding: 16px;
  border-radius: var(--devo-border-radius);
  cursor: pointer;
  color: var(--primary-label-color);
  
  .content {
    flex-grow: 2;
    p { 
      margin: 0;
      font-weight: 600; 
    }
  }
  
  .svg-inline--fa {
    margin: 0 4px;
  }
  
  &:hover {
    text-decoration: none;
    color: var(--primary-label-color);
    background-color: var(--gray-5);
`;