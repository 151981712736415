import styled from 'styled-components';

const BadgeStyle = `
  .badge > span {
    font-size: 10px;
    font-size: 600;
  }
  .bg-dark, .bg-warning, .bg-success { color: white; }
  .bg-warning { background-color: var(--devo-orange); }
  .bg-dark { background-color: var(--devo-removed-gray); }
`;

export const Container = styled.div`
  background-color: var(--background-color);

  .loader {
    margin: 10px auto;
  }

  .empty-state {
    padding: 50px 50px 30px;
    text-align: center;

    * { color: var(--gray) }

    svg {
      margin-bottom: 5px;
    }
  }

  ${BadgeStyle}
`;

export const ConfirmContainer = styled.div`
  ${BadgeStyle}
`;

export const ConfirmChange = styled.div`
  display: flex;
  margin: 0 -20px 20px;

  .change-row:last-child {
    margin: 0;
    flex-grow: 2;
    background-color: var(--gray-6);
  }
`;

export const UndoBadge = styled.div`
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--devo-dark-red);

  svg {
    color: var(--white);
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -20px;
  border-bottom: 1px var(--separator-color) solid;
  padding: 20px;

  p {
    margin: 0;
  }

  .date {
    font-size: 14px;
    color: var(--secondary-label-color);
  }

  &:last-child {
    border: none;
  }
`;

export const Left = styled.div`
`;

export const Right = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;

export const Button = styled.button`
  background-color: var(--background-color);
  border: 1px var(--border-color) solid;
  border-radius: 6px;
  padding: 3px 10px;
`;

export const ProductPreview = styled.div`
  height: 90px;
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  margin: -20px -20px 0;
  padding: 20px;
  border-bottom: 1px var(--separator-color) solid;

  &.sticky {
    position: sticky;
    top: 54px;
    margin-top: -21px;
    border-top: 1px var(--separator-color) solid;
  }

  .left {
    height: 100%;

    img {
      height: 100%;
    }
  }

  .right {
    flex-grow: 2;
    margin-left: 10px;

    p {
      margin: 0;
    }
  
    span {
      color: var(--secondary-label-color);
      font-size: 14px;
    }
  }
`;