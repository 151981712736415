import React, { useEffect, useRef, useState } from 'react'
import { Field } from 'styles/Form'
import { roundTo } from 'helper/product'

type PercentFieldProps = {
  id?: string;
  value: number;
  onValueChange: (value: number) => void;
  onEnter?: () => void;
  disabled?: boolean;
  minimum?: number;
  maximum?: number;
}

const formatPercent = (input: number): string => {
  return roundTo(input * 100, 2).toString() + "%";
}

const PercentField: React.FC<PercentFieldProps> = ({
  id = null,
  value,
  onValueChange,
  onEnter = null,
  disabled = false,
  minimum = 0,
  maximum = 1
}) => {

  const ref = useRef(null);
  const [textValue, setTextValue] = useState(formatPercent(value));

  useEffect(() => {
    setTextValue(formatPercent(value));
  }, [value]);

  const handleFocus = e => {
    setTextValue("");
  }

  const handleBlur = e => {
    let percentText = e.target.value.replace(/[^0-9.]/g,'');
    let percentNum = parseFloat(percentText);
    if (isNaN(percentNum)) return fallback();

    let rounded = roundTo(percentNum, 2) / 100;
    let value = Math.max(0, Math.min(rounded, 1));
    setTextValue(formatPercent(value));
    onValueChange(value);
  }

  const fallback = () => {
    setTextValue(formatPercent(value));
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      ref.current?.blur();
      if (!!onEnter) onEnter();
    }
  }

  return (
    <Field
      ref={ref}
      id={id}
      type="text"
      value={textValue}
      placeholder={formatPercent(value)}
      onChange={e => setTextValue(e.target.value)}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      disabled={disabled}
    />
  )
}

export default PercentField