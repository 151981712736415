import React, { createContext, useContext, useState } from 'react'
import { useWindowSizeBreakpoints } from 'helper/useWindowSize'
import { BASE_DATA_KEY_FORMAT_AS_PRICE } from 'helper/dashboard/remote-reports'
import useTimeFrame, { TimeFrameProps } from 'helper/datetime/useTimeFrame'
import { formatPrice } from 'helper/product'
import { RemoteReportBaseModel, RemoteReportBaseModelDataValueKey } from 'models/RemoteReports/BaseRemoteReports'

export type RemoteReportsViewMode = "SALES" | "SALES_AND_REVENUE" | "TRANSACTIONS" | "TRANSACTIONS_AND_REVENUE" | "REVENUE";

interface RemoteReportsState {
  isMobile: boolean;
  id: number;
  timeFrame: TimeFrameProps;
  viewMode: RemoteReportsViewMode;
  setViewMode: React.Dispatch<React.SetStateAction<RemoteReportsViewMode>>;
  viewModeFormatAsPrice: boolean;
  viewModeIncludeExtraRevenue: boolean;
  combinedDataKey: RemoteReportBaseModelDataValueKey;
  inStoreDataKey: RemoteReportBaseModelDataValueKey;
  onlineDataKey: RemoteReportBaseModelDataValueKey;
  sortingDataKey: RemoteReportBaseModelDataValueKey;
  formatDataValue: (data: RemoteReportBaseModel, key: RemoteReportBaseModelDataValueKey) => string;
}

interface RemoteReportsProviderProps {
  id: number;
  children?: any;
}

const RemoteReportsContext = createContext<Partial<RemoteReportsState>>({});

export const RemoteReportsProvider: React.FC<RemoteReportsProviderProps> = ({
  id,
  children
}) => {

  const { medium: isMobile } = useWindowSizeBreakpoints();

  const timeFrame = useTimeFrame();

  const [viewMode, setViewMode] = useState<RemoteReportsViewMode>("SALES");

  const getCombinedDataKey = (): RemoteReportBaseModelDataValueKey => {
    switch (viewMode) {
      case "SALES": return "countProducts";
      case "SALES_AND_REVENUE": return "countProducts";
      case "TRANSACTIONS": return "countTransactions";
      case "TRANSACTIONS_AND_REVENUE": return "countTransactions";
      case "REVENUE": return "totalRevenue";
    }
  }

  const getInStoreDataKey = (): RemoteReportBaseModelDataValueKey => {
    switch (viewMode) {
      case "SALES": return "countDevoPOSProducts";
      case "SALES_AND_REVENUE": return "countDevoPOSProducts";
      case "TRANSACTIONS": return "countDevoPOSTransactions";
      case "TRANSACTIONS_AND_REVENUE": return "countDevoPOSTransactions";
      case "REVENUE": return "totalPOSRevenue";
    }
  }

  const getOnlineDataKey = (): RemoteReportBaseModelDataValueKey => {
    switch (viewMode) {
      case "SALES": return "countDevoOnlineProducts";
      case "SALES_AND_REVENUE": return "countDevoOnlineProducts";
      case "TRANSACTIONS": return "countDevoOnlineTransactions";
      case "TRANSACTIONS_AND_REVENUE": return "countDevoOnlineTransactions";
      case "REVENUE": return "totalDevoOnlineRevenue";
    }
  }

  const getSortingDataKey = (): RemoteReportBaseModelDataValueKey => {
    switch (viewMode) {
      case "SALES": return "countProducts";
      case "SALES_AND_REVENUE": return "totalRevenue";
      case "TRANSACTIONS": return "countProducts";
      case "TRANSACTIONS_AND_REVENUE": return "totalRevenue";
      case "REVENUE": return "totalRevenue";
    }
  }

  const _formatDataValue = (value: number, formatAsPrice: boolean = false) => {
    return formatAsPrice ? formatPrice(value) : value.toLocaleString();
  }

  const formatDataValue = (data: RemoteReportBaseModel, key: string): string => {
    if (!data || !key) return _formatDataValue(0);
    let shouldFormatAsPrice = BASE_DATA_KEY_FORMAT_AS_PRICE[key] == true;
    let value = data[key] || 0;
    return _formatDataValue(value, shouldFormatAsPrice);
  }

  const props: RemoteReportsState = {
    isMobile,
    id,
    timeFrame,
    viewMode,
    setViewMode,
    viewModeFormatAsPrice: viewMode == "REVENUE",
    viewModeIncludeExtraRevenue: viewMode == "SALES_AND_REVENUE" || viewMode == "TRANSACTIONS_AND_REVENUE",
    combinedDataKey: getCombinedDataKey(),
    inStoreDataKey: getInStoreDataKey(),
    onlineDataKey: getOnlineDataKey(),
    sortingDataKey: getSortingDataKey(),
    formatDataValue,
  }

  return (
    <RemoteReportsContext.Provider value={props}>
      {children}
    </RemoteReportsContext.Provider>
  );
};

export const useRemoteReportsContext = () => useContext(RemoteReportsContext);
export default useRemoteReportsContext;
