import {
  getAllDatesBetween, getAllMonthDatesBetween,
  getAllWeekDatesBetween, getAllYearDatesBetween,
  getWeekOfYear,
  LONG_DAYS_OF_WEEK,
  LONG_MONTHS,
  SHORT_DAYS_OF_WEEK,
  SHORT_MONTHS
} from 'helper/datetime/dates'
import { TimeFrame } from 'helper/datetime/timeFrame'
import { formatLocalLongDate, formatLocalShortDate } from 'helper/datetime/toLocalDate';

// ------------------------------
// Data model
// ------------------------------

export const BASE_DATA_KEY_FORMAT_AS_PRICE = {
  "countProducts": false,
  "countDevoPOSProducts": false,
  "countDevoOnlineProducts": false,
  "countTransactions": false,
  "countDevoPOSTransactions": false,
  "countDevoOnlineTransactions": false,
  "totalRevenue": true,
  "totalPOSRevenue": true,
  "totalDevoOnlineRevenue": true,
}

// ------------------------------
// Date groupings
// ------------------------------

export type DateGroupKey = "daily" | "weekly" | "monthly" | "yearly" | "hourly" | "dayOfWeek";

const INDEX_TO_DAY_OF_WEEK = { 0: "MONDAY", 1: "TUESDAY", 2: "WEDNESDAY", 3: "THURSDAY", 4: "FRIDAY", 5: "SATURDAY", 6: "SUNDAY" };
const DAY_OF_WEEK_TO_INDEX = { "MONDAY": 0, "TUESDAY": 1, "WEDNESDAY": 2, "THURSDAY": 3, "FRIDAY": 4, "SATURDAY": 5, "SUNDAY": 6 };

export const DATE_GROUP_KEY_LABELS = {
  "daily": "day",
  "weekly": "week",
  "monthly": "month",
  "yearly": "year",
  "hourly": "time of day",
  "dayOfWeek": "day of week"
};

// ------------------------------
// Date group key
// ------------------------------

const getSameDate = (date: Date): Date => date;
const getFirstOfWeekBeforeDate = (date: Date): Date => new Date(date.setDate(date.getDate() - date.getDay() + (date.getDay() == 0 ? -6 : 1)));
const getFirstOfMonthBeforeDate = (date: Date): Date => new Date(date.setDate(1));
const getFirstOfYearBeforeDate = (date: Date): Date => new Date(new Date(date.setDate(1)).setMonth(0));
const formatDateKey = (date: Date) => date.toISOString().split('T')[0];

export const getDateKey = (date: Date, keyType: DateGroupKey = "daily") => {
  switch (keyType) {
    case "daily":       return formatDateKey(date);
    case "weekly":      return formatDateKey(getFirstOfWeekBeforeDate(date));
    case "monthly":     return formatDateKey(getFirstOfMonthBeforeDate(date));
    case "yearly":      return date.getFullYear().toString() + "-01-01";
    case "hourly":      return date.getHours().toString() + ":00";
    case "dayOfWeek":   return INDEX_TO_DAY_OF_WEEK[(date.getDay() + 6) % 7];
  }
}

const _SUGGESTED_GROUPING_FOR_TIME_FRAME = {
  "TODAY": "hourly",
  "YESTERDAY": "hourly",
  "LAST_7_DAYS": "daily",
  "LAST_14_DAYS": "daily",
  "LAST_30_DAYS": "daily",
  "LAST_6_MONTHS": "monthly",
  "LAST_12_MONTHS": "monthly",
  "CUSTOM": "daily",
};
export const getInitialGroupingForTimeFrame = (timeFrame: TimeFrame): DateGroupKey => {
  // @ts-ignore
  return _SUGGESTED_GROUPING_FOR_TIME_FRAME[timeFrame];
}

// ------------------------------
// Date key sorting
// ------------------------------

export const sortDateKeys = (keys: string[], keyType: DateGroupKey, startDate: Date = null, endDate: Date = null): string[] => {
  if (!keys || keys.length == 0) return [];
  switch (keyType) {
    case "daily":       return sortDayKeys(keys, startDate, endDate);
    case "weekly":      return sortWeekKeys(keys, startDate, endDate);
    case "monthly":     return sortMonthKeys(keys, startDate, endDate);
    case "yearly":      return sortYearKeys(keys, startDate, endDate);
    case "hourly":      return sortTimeKeys(keys, startDate, endDate);
    case "dayOfWeek":   return LONG_DAYS_OF_WEEK.map(key => key.toUpperCase());
  }
}

type DateSorter = (keys: string[], startDate: Date, endDate: Date) => string[]

const dateSorter = (keys: string[], startDate: Date, endDate: Date, limiter: (d: Date) => Date, stepper: (f: Date, t: Date) => Date[]): string[] => {
  const toDates = keys.map(k => new Date(k));
  const minDate = limiter(startDate || new Date(Math.min.apply(null, toDates)));
  const maxDate = limiter(endDate || new Date(Math.max.apply(null, toDates)));
  return stepper(minDate, maxDate)
    .sort((a, b) => a.getTime() - b.getTime())
    .map(d => getDateKey(d));
}

const sortDayKeys: DateSorter = (keys, startDate = null, endDate = null) =>
  dateSorter(keys, startDate, endDate, getSameDate, getAllDatesBetween);
const sortWeekKeys = (keys, startDate = null, endDate = null) =>
  dateSorter(keys, startDate, endDate, getFirstOfWeekBeforeDate, getAllWeekDatesBetween);
export const sortMonthKeys = (keys, startDate = null, endDate = null) =>
  dateSorter(keys, startDate, endDate, getFirstOfMonthBeforeDate, getAllMonthDatesBetween);
export const sortYearKeys = (keys, startDate = null, endDate = null) =>
  dateSorter(keys, startDate, endDate, getFirstOfYearBeforeDate, getAllYearDatesBetween);

export const sortTimeKeys = (keys, startDate = null, endDate = null) => {
  let numKeys = keys.map(x => parseInt(x)).sort((a, b) => a-b);
  let min = Math.min(9, numKeys[0]);
  const max = Math.max(17, numKeys[numKeys.length-1]);
  let newKeys = [];
  while (min <= max) {
    newKeys.push(min.toString().padStart(2, "0") + ":00");
    min += 1;
  }
  return newKeys;
}

// ------------------------------
// Date key format
// ------------------------------

export const dateKeyToLabel = (key: string, keyType: DateGroupKey, short = false): string => {
  let date = new Date(key);
  switch (keyType) {
    case "daily":
      let f = short ? formatLocalShortDate : formatLocalLongDate;
      return f(date);
    case "weekly":
      let week = getWeekOfYear(date);
      if (short) {
        return "Wk " + week.toString() + ", " + date.getFullYear().toString();
      } else {
        return "Week " + week.toString() + " (beginning " + formatLocalShortDate(date) + ")";
      }
    case "monthly":
      let monthArr = short ? SHORT_MONTHS : LONG_MONTHS;
      return monthArr[date.getMonth()] + " " + date.getFullYear().toString();
    case "yearly":
      return date.getFullYear().toString();
    case "hourly":
      return key;
    case "dayOfWeek":
      let daysArr = short ? SHORT_DAYS_OF_WEEK : LONG_DAYS_OF_WEEK;
      return daysArr[DAY_OF_WEEK_TO_INDEX[key]];
  }
}
