
export const PreventTextSelectStyle = `
  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }
`;

export const InputSelectNoArrows = `
  /* Chrome, Safari, Edge, Opera */
  input.noarrows::-webkit-outer-spin-button,
  input.noarrows::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input.noarrows[type=number] { -moz-appearance: textfield; }
`;

export const HideScrollBars = `
  /* Hide scrollbar for Chrome, Safari and Opera */
  .hidescrollbar::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .hidescrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
`;

export const ClampTextLines = `
  .clamplines, .clamplines-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  .clamplines-3 { -webkit-line-clamp: 3; }
`;

export const DropdownStyling = `
  .dropdown button {
    white-space: nowrap;
  }
`;