import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
  Container,
  Row,
  Left,
  Right,
  Button,
  ConfirmContainer,
  ConfirmChange,
  UndoBadge,
  ProductPreview,
} from './VersionHistoryPopup.style';
import { availabilityDescription, availabilityToBootstrapVariant, formatPrice } from 'helper/product';
import { ShopProduct } from 'models/Product';
import useShopContext from 'contexts/shop/shop.context';

import dynamic from 'next/dynamic';
import { GET_PRODUCT_VERSION_HISTORY } from 'devoapi/version-history';
import useAuth from 'contexts/auth/auth.context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory, faUndo } from '@fortawesome/free-solid-svg-icons';
import { Badge } from 'react-bootstrap';
import Loader from 'components/Loader/Loader';
import RecommendedPriceProvider from 'hooks/RecommendedPriceProvider';
import { formatSize, formatProductUpc } from 'helper/products';
import { Change, Version } from 'models/VersionHistory';
import PlaceholderImage from 'image/product-placeholder.png';
import { formatLocalLongDate, formatLocalTimeString } from 'helper/datetime/toLocalDate';

const Popup = dynamic(() => import('components/Popup/Popup'));
const YesNo = dynamic(() => import('components/Popup/YesNo'));

const ALL_CHANGE_PROPERTIES: ('shopPrice' | 'availabilityText' | 'limit' | 'popularityScore')[] = ['shopPrice', 'availabilityText', 'limit', 'popularityScore'];

type ChangePriceProps = {
  isOpen: boolean;
  closeModal: () => void;
  item: ShopProduct;
  onSelectRevert: (version: Version) => void;
};

type ChangePricePopupProps = {
  recommended: number;
  onSelectVersion: (version: Version) => void;
};

const priceText = (price, recommended, variant) => {
  if (price == recommended) {
    return (<>
      <Badge pill bg={variant}>{formatPrice(price)}</Badge>
      &nbsp;
      <span>(recommended)</span>
    </>);
  } else {
    return (<Badge pill bg={variant}>
      {formatPrice(price)}
    </Badge>);
  }
}

const ChangeLabel = ({ change, recommended }) => {
  return (
    <>
      {change.type === 'shopPrice' &&
        <p>Price changed from {priceText(change.from, recommended, "info")} to {priceText(change.to, recommended, "primary")}</p>
      }
      {change.type === 'availabilityText' &&
        <p>Availability changed from <Badge pill bg={availabilityToBootstrapVariant(change.from)}>{availabilityDescription(change.from)}</Badge> to <Badge pill bg={availabilityToBootstrapVariant(change.to)}>{availabilityDescription(change.to)}</Badge></p>
      }
      {change.type === 'limit' &&
        <p>Purchase limit changed from <b>{change.from}</b> to <b>{change.to}</b></p>
      }
    </>
  )
};

const RevertLabel = ({ change, recommended }) => {
  return (
    <>
      {change.type === 'shopPrice' &&
        <p>This will revert the shop price to {priceText(change.from, recommended, "info")}.</p>
      }
      {change.type === 'availabilityText' &&
        <p>This will revert the availability to <Badge pill bg={availabilityToBootstrapVariant(change.from)}>{availabilityDescription(change.from)}</Badge>.</p>
      }
      {change.type === 'limit' &&
        <p>This will revert the purchase limit to <b>{change.from}</b>.</p>
      }
    </>
  )
};

const ChangeRow = ({ className = "", version, handleRevertClick: onRevertClick = null, recommended = null }) => (
  <Row className={`change-row ${className}`}>
    <Left>
      <ChangeLabel change={version.change} recommended={recommended} />
      <span className="date">{formatLocalTimeString(version.time)} • {formatLocalLongDate(version.time)}</span>
    </Left>
    {onRevertClick && 
      <Right>
        <Button onClick={() => onRevertClick(version)}>
          <FontAwesomeIcon icon={faUndo} size="sm" />
          &nbsp;
          Revert
        </Button>
      </Right>
    }
  </Row>
);

const Product = ({ item, className = "" }) => (
  <ProductPreview className={className}>
    <div className="left">
      <img src={item.product.thumbnail || PlaceholderImage} alt={item.product.name} />
    </div>
    <div className="right">
      <p>{item.product.name} {formatSize(item)}</p>
      <span>{formatProductUpc(item)}</span>
    </div>
  </ProductPreview>
);

const ChangePrice: React.FC<ChangePriceProps & ChangePricePopupProps> = ({ item, isOpen, onSelectVersion, recommended }) => {
  const { authState: { user } } = useAuth();
  const { shopState } = useShopContext();

  // --------------------------------------------
  // API
  // --------------------------------------------

  const [versionHistory, setVersionHistory] = useState([]);

  const { data, loading, error } = useQuery(GET_PRODUCT_VERSION_HISTORY, {
    skip: !isOpen,
    variables: {
      shopId: shopState.uuid,
      productUpc: item.product.upc,
    },
    onCompleted: res => {
      const changes = res?.changes;
      if (changes?.length < 1) return;
      setVersionHistory(parseChanges(changes));
    },
  });

  // --------------------------------------------
  // Actions
  // --------------------------------------------

  const handleRevertClick = (version) => {
    if (version && version.change) onSelectVersion(version);
  };

  // --------------------------------------------
  // Helpers
  // --------------------------------------------

  const itemToChangesFormat = () => {
    return { 
      authorUuid: user.uuid,
      availabilityText: item.availability,
      createdAt: new Date(),
      limit: item.limit,
      popularityScore: item.popularityScore,
      shopPrice: item.shopPrice,
      shopUuid: item.shopUuid,
      upc: item.product.upc,
    };
  };

  const parseChanges = (changes): Version[] => {
    const current = itemToChangesFormat();
    const paired = changes.map((x, i) => [x, (i === 0) ? current : changes[i-1]] );
    return paired.map(x => {
      const [from, to] = x;
      var change: Change = null;
      ALL_CHANGE_PROPERTIES.forEach(prop => {
        if (from[prop] !== to[prop]) change = { type: prop, from: from[prop], to: to[prop] };
      });
      return { from, to, change, time: new Date(to.createdAt) };
    }).filter(x => !!x.change);
  };

  // --------------------------------------------
  // Render
  // --------------------------------------------

  if (loading) return <Container><Loader /></Container>;

  if (versionHistory.length > 0) {
    return (
      <Container>
        {versionHistory.map((version, index) => (
          <>
            {version.change &&
              <ChangeRow
                key={index}
                version={version} 
                handleRevertClick={handleRevertClick} 
                recommended={recommended} 
              />
            }
          </>
        ))}
      </Container>
    );
  } else {
    return (
      <Container>
        <div className="empty-state">
          <FontAwesomeIcon icon={faHistory} size="lg" />
          <p>No previous changes</p>
        </div>
      </Container>
    );
  }
};

const ChangePricePopup: React.FC<ChangePriceProps> = props => {
  const { isOpen, closeModal, item, onSelectRevert } = props;

  const [version, setVersion] = useState(null);
  useEffect(() => setVersion(null), [isOpen]);

  const handleConfirm = () => {
    onSelectRevert(version);
  };

  // --------------------------------------------
  // Render
  // --------------------------------------------

  if (!item?.product) return <></>;

  if (version) {
    return (
      <Popup isOpen={isOpen} closeModal={closeModal}>
        <YesNo 
          title="Revert Change"
          noText="Cancel"
          noAction={() => setVersion(null)}
          yesText="Confirm"
          yesAction={handleConfirm}
          yesLoading={false}
          closeOnNo={false}
          closeOnYes={false}
          closeModal={closeModal}
        >
          <Product item={item} />
          <RecommendedPriceProvider shopId={item.shopUuid} productUpc={item.product.upc}>
            {props => (
              <ConfirmContainer>
                <ConfirmChange>
                  <UndoBadge>
                    <FontAwesomeIcon icon={faUndo} />
                  </UndoBadge>
                  <ChangeRow version={version} />
                </ConfirmChange>
                <p>Are you sure you want to revert this change?</p>
                <RevertLabel change={version.change} recommended={props.recommended} />
              </ConfirmContainer>
            )}
          </RecommendedPriceProvider>
        </YesNo>
      </Popup>
    );
  }

  return (
    <Popup isOpen={isOpen} closeModal={closeModal}>
      <YesNo 
        title="Version History"
        closeModal={closeModal}
      >
        <Product item={item} className="sticky" />
        <RecommendedPriceProvider shopId={item.shopUuid} productUpc={item.product.upc}>
          {recommendedProps => (
            <ChangePrice
              recommended={recommendedProps.recommended}
              onSelectVersion={version => setVersion(version)}
              {...props}
            />
          )}
        </RecommendedPriceProvider>
      </YesNo>
    </Popup>
  );
};

export default ChangePricePopup;