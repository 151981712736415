import styled from 'styled-components';
import { StyleBreakpoints } from 'styles/Global';

export const VERY_TINY_BREAKPOINT_PX = 420;

export const ServicesTable = styled.table`
  width: 100%;
`;

export const TableRow = styled.tr`
  &:nth-child(1) {
    border-bottom: 1px solid var(--separator-color);
    border-top: none;
  }

  &:nth-child(2) {
    border: none;
  }

  border-top: 1px solid var(--separator-color);
`;

export const TableHeadCell = styled.th`
  font-size: 11px;
  font-weight: 600;
  white-space: nowrap;
  width: 25%;
  padding-bottom: 6px;

  @media screen and (min-width: ${VERY_TINY_BREAKPOINT_PX}px) {
    font-size: 14px;
    padding-bottom: 8px;
  }

  @media screen and (min-width: ${StyleBreakpoints.MEDIUM}px) {
    padding-bottom: 10px;
  }
`;

export const TableCell = styled.td`
  font-size: 11px;
  font-weight: 400;
  padding: 6px 2px;

  &:first-child {
    padding-left: 12px;
  }

  @media screen and (min-width: ${VERY_TINY_BREAKPOINT_PX}px) {
    font-size: 14px;
    padding: 8px 4px;

    &:first-child {
      padding-left: 20px;
    }
  }

  @media screen and (min-width: ${StyleBreakpoints.MEDIUM}px) {
    padding: 10px 4px;
    font-size: 16px;

    &:first-child {
      padding-left: 120px;
    }
  }
`;

export interface CellWrapperProps {
  $width: number;
}

export const CellWrapper = styled.div<CellWrapperProps>`
  display: flex;
  align-items: center;
  gap: 2px;

  @media screen and (min-width: ${VERY_TINY_BREAKPOINT_PX}px) {
    gap: 4px;
  }

  @media screen and (min-width: ${StyleBreakpoints.MEDIUM}px) {
    gap: 10px;
  }

  & > span {
    width: ${(props) => props.$width}px;
  }
`;

export const ProductMarkupInput = styled.input`
  background-color: var(--secondary-background-color);
  border-radius: var(--devo-border-radius);
  border: none;
  width: 30%;
  padding: 0 2px;
  font-size: 13px;

  &:disabled {
    color: var(--gray-2);
  }

  &:focus {
    background-color: var(--secondary-background-color);
  }
  &::placeholder {
    color: var(--secondary-label-color);
  }
  :-ms-input-placeholder {
    color: var(--secondary-label-color);
  } /* Internet Explorer 10-11 */
  ::-ms-input-placeholder {
    color: var(--secondary-label-color);
  } /* Microsoft Edge */

  @media screen and (min-width: ${VERY_TINY_BREAKPOINT_PX}px) {
    padding: 0 4px;
    font-size: 14px;
  }

  @media screen and (min-width: ${StyleBreakpoints.MEDIUM}px) {
    font-size: 16px;
  }
`;

export const NonIntegratedText = styled.p`
  margin: 0;
  color: var(--secondary-label-color);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 240px;
`;
