import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Container,
  Controls,
  Button,
  SearchWrapper,
  SearchInput,
  ProductList,
  LoaderWrapper,
} from './NavBar.style';
import Link from 'next/link';
import Navbar from 'react-bootstrap/Navbar';
import Brand from 'components/Brand/Brand';
import { POS_SCAN_ROUTE } from 'constants/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBarcode,
  faSearch,
  faClose,
} from '@fortawesome/free-solid-svg-icons';
import { Search } from 'components/ProductsToolbar/Toolbar.style';
import { useQuery } from '@apollo/react-hooks';
import { GET_PRODUCTS } from 'devoapi/products';
import { ProductsResponse } from 'models/Product';
import { ShopProduct } from 'models/Product';
import useAuth from 'contexts/auth/auth.context';
import { POS_SERVICES_CATEGORY_ID } from 'constants/constants';
import FilterProps, {
  FilterConstructorParams,
  FilterPropsSortingBy,
} from 'models/FilterProps';
import { AvailabilityEnum } from 'models/Product';
import { ProductsViewMode } from 'containers/Products/Products';
import ProductRow, { ProductRowProps } from 'components/ProductRow/ProductRow';
import { cloneObject } from 'helper/object';
import useUndoContext from 'contexts/undo/undo.context';
import { Category } from 'models/Category';
import Loader from 'components/Loader/Loader';
import { Container as ToolbarContainer } from 'components/ProductsToolbar/Toolbar.style';
import ProductsToolbar, {
  ProductsToolbarProps,
} from 'components/ProductsToolbar/ProductsToolbar';
import dynamic from 'next/dynamic';
import FilterDrawer from 'containers/FilterDrawer/FilterDrawer';
import StatusChangePopup from 'components/StatusChangePopup/StatusChangePopup';
import ResultsPerPageEnum, {
  ResultsPerPage,
} from 'helper/products/resultsPerPage';
import useTimeFrame from 'helper/datetime/useTimeFrame';
import useBulkStockProvider from 'hooks/useBulkStockProvider';
import { StockSummaryProduct } from 'models/Stock';
import PosStockPopup from 'components/Stock/PosStockPopup';
import usePermissions from 'endpoints/permissions/usePermissions';
const PriceChangePopup = dynamic(
  () => import('components/PriceChangePopup/PriceChangePopup')
);

type NavBarProps = {
  onMenuClick: () => void;
  reference: React.MutableRefObject<any>;
};

const fetchPolicy = 'cache-and-network';
const mode: ProductsViewMode = 'LIVE';

const NavBar: React.FC<NavBarProps> = ({ onMenuClick, reference }) => {
  const { authState } = useAuth();
  const { user } = authState;
  const shopId = user?.shopUuid;
  const { undoDispatch } = useUndoContext();
  const { isPosEnabled, permissions } = usePermissions();

  const timeFrameProps = useTimeFrame();

  const [showSearch, setShowSearch] = useState(false);
  const [filter, setFilter] = useState(new FilterProps());
  const [offset, setOffset] = useState(0);
  const [productsPerPage, setProductsPerPage] = useState<ResultsPerPage>(
    ResultsPerPageEnum.normal
  );
  const [items, setItems] = useState<ShopProduct[]>([]);
  const [selectedUpcs, setSelectedUpcs] = useState<number[]>([]);
  const [priceChangePopup, setPriceChangePopup] = useState<ShopProduct[]>(null);
  const [allSelected, setAllSelectedBool] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [showStatusChangePopup, setShowStatusChangePopup] = useState(false);
  const [stockItemUpc, setStockItemUpc] = useState<number | null>(null);

  const inSelection = selectedUpcs.length > 0;

  const { loading, refetch: _refetch } = useQuery<ProductsResponse>(
    GET_PRODUCTS,
    {
      skip: !shopId || !filter.keyword,
      fetchPolicy,
      variables: {
        shopId: shopId,
        offset: offset,
        limit: productsPerPage,
        filterQuery: filter.toQueryString(mode),
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: (res) => {
        setItems(
          res?.products?.shopProducts?.filter(
            (x) => x.product.category.id != POS_SERVICES_CATEGORY_ID
          ) || []
        );
        setTotalResults(res?.products?.results || 0);
      },
    }
  );

  const updateFilter = (propertiesToUpdate: FilterConstructorParams) => {
    setFilter(
      (oldFilter) => new FilterProps({ ...oldFilter, ...propertiesToUpdate })
    );
    setOffset(0);
  };

  const handleOpenButtonClick = () => {
    setShowSearch(true);
  };

  const handleCloseButtonClick = () => {
    setShowSearch(false);
    updateFilter({ keyword: '' });
    setItems([]);
  };

  useEffect(() => {
    if (!showSearch && items.length > 0) {
      setItems([]);
      setOffset(0);
    }
  }, [showSearch, items.length]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFilter({ keyword: encodeURIComponent(e.target.value) });
  };

  const handleToolbarSearchChange = (query: string) => {
    updateFilter({ keyword: encodeURIComponent(query || '') });
    setSelectedUpcs([]);
    setAllSelected(false);
  };

  const itemIsSelected = (item: ShopProduct): boolean => {
    return selectedUpcs.includes(item.product.upc);
  };

  const handleToggleSelection = (item: ShopProduct) => {
    var prev = selectedUpcs.slice();
    if (itemIsSelected(item)) {
      prev.splice(prev.indexOf(item.product.upc), 1);
    } else {
      prev.push(item.product.upc);
    }
    setSelectedUpcs(prev);
  };

  const changeSelectedStatus = (
    items: ShopProduct[],
    status: AvailabilityEnum,
    addUndo: Boolean
  ) => {
    if (addUndo) {
      items.forEach((item) => {
        undoDispatch({
          type: 'ADD_CHANGE',
          payload: {
            upc: item.product.upc,
            change: {
              type: 'availabilityText',
              from: item.availability,
              to: status,
            },
          },
        });
      });
    }

    setItems((_prev) => {
      const prev = _prev.slice();
      prev.forEach((item) => {
        if (items.includes(item)) item.availability = status;
      }, []);
      return prev;
    });
  };

  const handleClickCategory = (category: Category) => {
    filter.categoryIds = [category.id];
    filter.includeSubcats = true;
    setOffset(0);
  };

  const handlePriceClick = (item: ShopProduct) => setPriceChangePopup([item]);

  const handleFilterChanged = (newFilter: FilterProps) => {
    setFilter(newFilter);
  };

  const setAllSelected = (allSelected: boolean) => {
    setSelectedUpcs(allSelected ? items.map((x) => x.product.upc) : []);
    setAllSelectedBool(allSelected);
  };

  const getSelectedItems = (): ShopProduct[] => {
    return items.filter((x) => itemIsSelected(x));
  };

  const onResultsPerPageChange = (n: ResultsPerPage) => {
    var newOffset = Math.round(offset / n) * n;
    if (newOffset > totalResults) newOffset = 0;

    setProductsPerPage(n);
    setOffset(newOffset);
    setSelectedUpcs([]);
  };

  const handleSortChange = (
    sortingBy: FilterPropsSortingBy,
    isAscending: boolean
  ) => {
    updateFilter({ sortingBy, isAscending });
  };

  const showPriceChangePopup = () => setPriceChangePopup(getSelectedItems());

  const handleFilterDrawerOpen = () => {
    setFilterDrawerOpen(true);
  };

  const openStockPopup = (withItemUpc: number) => {
    setStockItemUpc(withItemUpc);
  };

  const closeStockPopup = (summary: StockSummaryProduct & { upc: number }) => {
    refetchStock();
    setStockItemUpc(null);
  };

  const memoizedItemUpcs = useMemo(
    () => items.map((product) => product.product.upc),
    [items]
  );

  const {
    getStockBalance,
    fetched: stockFetched,
    refetch: refetchStock,
    updateStockProduct,
  } = useBulkStockProvider({
    upcs: memoizedItemUpcs,
    queryLimit: items?.length ?? 50,
  });

  const productRowProps = (
    item: ShopProduct,
    index: number
  ): ProductRowProps => {
    return {
      mobile: true,
      item,
      index,
      mode: mode,
      showOptions: !inSelection,
      inSelection: inSelection,
      isSelected: itemIsSelected(item),
      toggleSelected: () => handleToggleSelection(item),
      setStatus: (val, addUndo) => changeSelectedStatus([item], val, addUndo),
      stock: getStockBalance(item.product.upc),
      openStock: () => openStockPopup(item.product.upc),
      categoryShortcutEnabled: !inSelection,
      onClickCategory: handleClickCategory,
      onClickPrice: () => handlePriceClick(item),
      timeFrameProps,
    };
  };

  const closePriceChangeModal = (shouldRefetch: boolean) => {
    setPriceChangePopup(null);
  };

  const hideGlobalSearch = () => {
    setShowSearch(false);
    filter.keyword = '';
    setItems([]);
  };

  const productToolbarProps = (): ProductsToolbarProps => {
    return {
      mode,
      loading: false,
      inSelection: inSelection,
      handleClearSelection: () => setAllSelected(false),
      selectedItems: selectedUpcs,
      handleStatusChange: (val) =>
        changeSelectedStatus(getSelectedItems(), val, true),
      offset: offset,
      totalResults: totalResults,
      resultsPerPage: productsPerPage,
      handleResultsPerPageChange: onResultsPerPageChange,
      handleFilterDrawerOpen,
      onSearchChange: handleToolbarSearchChange,
      onSortChange: handleSortChange,
      onPriceChange: showPriceChangePopup,
      filter: filter,
      showCategoryMenu: true,
      returnToCategoryMenu: () => {},
      hideGlobalSearch,
      showStatusChangePopup: () => setShowStatusChangePopup(true),
    };
  };

  const priceChangePopupKey = useMemo(
    () =>
      priceChangePopup
        ?.map((product) => product.product.upc)
        ?.join('-')
        ?.concat('-navbar') ?? null,
    [priceChangePopup]
  );

  return (
    <Container ref={reference}>
      <Navbar bg="light" sticky="top">
        {!showSearch ? (
          <>
            <Navbar.Brand
              style={{ fontSize: '24px', width: '24px' }}
              href="#"
              onClick={() => onMenuClick()}
            >
              &#9776;
            </Navbar.Brand>
            <Brand
              red
              style={{
                margin: 0,
                position: 'absolute',
                top: '0.5rem',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            />
            <Controls>
              <Button onClick={handleOpenButtonClick}>
                <FontAwesomeIcon
                  icon={faSearch}
                  color="black"
                  width={20}
                  height={20}
                />
              </Button>
              <Link href={POS_SCAN_ROUTE} passHref>
                <FontAwesomeIcon icon={faBarcode} />
              </Link>
            </Controls>
          </>
        ) : (
          <>
            <SearchWrapper>
              <FilterDrawer
                mode={mode}
                isOpen={filterDrawerOpen}
                closeModal={() => setFilterDrawerOpen(false)}
                filter={filter}
                setFilter={setFilter}
              />
              {permissions.read_stock && (
                <PosStockPopup
                  upc={stockItemUpc}
                  isOpen={!!stockItemUpc}
                  closeModal={closeStockPopup}
                />
              )}
              <PriceChangePopup
                key={priceChangePopupKey}
                isOpen={!!priceChangePopup}
                closeModal={closePriceChangeModal}
                selectedItems={priceChangePopup || []}
                usePosModel={true}
              />
              <StatusChangePopup
                isOpen={showStatusChangePopup}
                closeModal={() => setShowStatusChangePopup(false)}
                handleStatusChange={(availability) =>
                  changeSelectedStatus(getSelectedItems(), availability, true)
                }
              />
              <FontAwesomeIcon
                icon={faSearch}
                color="black"
                width={20}
                height={20}
              />
              <SearchInput
                value={decodeURIComponent(filter.keyword)}
                onChange={handleSearchChange}
                placeholder="Search"
              />
            </SearchWrapper>
            <Button onClick={handleCloseButtonClick}>
              <FontAwesomeIcon icon={faClose} color="black" />
            </Button>
            {items.length > 0 && (
              <ProductList>
                <ToolbarContainer global>
                  <ProductsToolbar global {...productToolbarProps()} />
                </ToolbarContainer>
                {loading ? (
                  <LoaderWrapper>
                    <Loader />
                  </LoaderWrapper>
                ) : (
                  items.map((item, index) => (
                    <ProductRow {...productRowProps(item, index)} />
                  ))
                )}
              </ProductList>
            )}
          </>
        )}
      </Navbar>
    </Container>
  );
};

export default NavBar;
