import { GENERATE_LABELS_ROUTE, PROMOTIONS_ROUTE } from 'constants/navigation'

export const createBarcodeGenerateUrl = (upcs: number[]): string => {
  return GENERATE_LABELS_ROUTE + '?barcodes=' + upcs.join(',');
}

export const createBulkPromotionsReportUrl = (upcs: number[]): string => {
  return PROMOTIONS_ROUTE + '?upcs=' + upcs.join(',');
}

export const splitArrayIntoChunksOfLen = (arr: number[], len: number) => {
  var chunks = [], i = 0, n = arr.length;
  while (i < n) {
    chunks.push(arr.slice(i, i += len));
  }
  return chunks;
}