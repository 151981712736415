import { IS_BETA_RELEASE } from 'constants/constants'
import useShopContext from 'contexts/shop/shop.context'
import React, { createContext, useContext } from 'react';
import DepotShop from 'models/DepotShop'

export type ActionType = {
  type: 'SET_DEPOT_SHOP';
  payload?: DepotShop;
};

type DepotShopContextProps = {
  depotShopState: DepotShop;
  depotShopDispatch: React.Dispatch<ActionType>;
}

export const DepotShopContext = createContext<Partial<DepotShopContextProps>>({});

export const useDepotShopContext = () => useContext(DepotShopContext);

export default useDepotShopContext;
