import { IS_BETA_RELEASE } from 'constants/constants';
import usePermissionsContext from 'contexts/permissions';
import {
  isLocalEnvironment,
  isProductionEnvironment,
  isStableEnvironment,
} from 'helper/environment';
import { PermissionsLibrary } from 'models/Permissions';

interface UsePermissionsCallback {
  groups: string[];
  fetchingGroups: boolean;
  isProduction: boolean;
  isStable: boolean;
  isDebug: boolean;
  isPosEnabled: boolean;
  isBetaRelease: boolean;
  checkPermissionEnabled: (key: string) => boolean;
  permissions: PermissionsLibrary;
}

const usePermissions = (): UsePermissionsCallback => {
  const { groups, fetchingGroups } = usePermissionsContext();

  const check = (key: string): boolean => {
    return groups.includes(key);
  };

  return {
    groups,
    fetchingGroups,
    isProduction: isProductionEnvironment(),
    isStable: isStableEnvironment(),
    isDebug: check('devo_debug') || isLocalEnvironment(),
    isPosEnabled: check('read_shop_pos'),
    isBetaRelease: IS_BETA_RELEASE,
    checkPermissionEnabled: check,
    permissions: {
      write_shop_open_status: check('write_shop_open_status'),
      read_shop_invoice: check('read_shop_invoice'),
      write_shop_commission_based_services: check(
        'write_shop_commission_based_services'
      ),
      write_shop_petty_cash_options: check('write_shop_petty_cash_options'),
      write_shop_suppliers: check('write_shop_suppliers'),
      write_shop_staff: check('write_shop_staff'),
      write_shop_notification_preferences: check(
        'write_shop_notification_preferences'
      ),
      read_shop_activity_log: check('read_shop_activity_log'),
      write_shop_wholesale_account: check('write_shop_wholesale_account'),
      read_shop_wholesale: check('read_shop_wholesale'),
      write_shop_wholesale_order: check('write_shop_wholesale_order'),
      read_shop_wholesale_order: check('read_shop_wholesale_order'),
      read_shop_order: check('read_shop_order'),
      write_shop_order: check('write_shop_order'),
      read_shop_order_history: check('read_shop_order_history'),
      write_shop_product: check('write_shop_product'),
      write_shop_stock: check('write_shop_stock'),
      read_shop_promotion: check('read_shop_promotion'),
      action_shop_pos_use_print: check('action_shop_pos_use_print'),
      write_shop_pos_favourites: check('write_shop_pos_favourites'),
      action_shop_pos_use_commission_service: check(
        'action_shop_pos_use_commission_service'
      ),
      action_shop_pos_use_manual_barcode: check(
        'action_shop_pos_use_manual_barcode'
      ),
      action_shop_pos_use_cashback: check('action_shop_pos_use_cashback'),
      action_shop_pos_use_discount: check('action_shop_pos_use_discount'),
      write_shop_user_credit: check('write_shop_user_credit'),
      action_shop_pos_use_voucher: check('action_shop_pos_use_voucher'),
      write_shop_pos_void_items: check('write_shop_pos_void_items'),
      action_shop_pos_use_refund: check('action_shop_pos_use_refund'),
      action_shop_pos_drawer_open_transaction: check(
        'action_shop_pos_drawer_open_transaction'
      ),
      action_shop_pos_drawer_open_manual: check(
        'action_shop_pos_drawer_open_manual'
      ),
      action_shop_pos_use_petty_cash: check('action_shop_pos_use_petty_cash'),
      read_shop_report_sales: check('read_shop_report_sales'),
      write_shop_pos_skip_transaction: check('write_shop_pos_skip_transaction'),
      read_shop_report_product: check('read_shop_report_product'),
      read_shop_pos_skip_transaction: check('read_shop_pos_skip_transaction'),
      read_shop_report_calendar: check('read_shop_report_calendar'),
      depot_catalogue_read: check('depot_catalogue_read'),
      depot_order_write: check('depot_order_write'),
      read_stock: check('read_stock'),
      write_stock: check('write_stock'),
      read_shop_pos_report: check('read_shop_pos_report'),
      partner_centre_home: check('partner_centre_home'),
      partner_centre_inventory: check('partner_centre_inventory'),
      partner_centre_orders: check('partner_centre_orders'),
      partner_centre_promotions: check('partner_centre_promotions'),
      partner_centre_sales_reports: check('partner_centre_sales_reports'),
      partner_centre_scan: check('partner_centre_scan'),
      partner_centre_wholesale: check('partner_centre_wholesale'),
      partner_centre_your_store: check('partner_centre_your_store'),
      wholesale_agent: check('wholesale_agent'),
    },
  };
};

export default usePermissions;
