import { getWholesalerById } from 'data/wholesalers';
import React, { useReducer, useContext, createContext } from 'react';
import { reducer, cartItemsTotalPrice } from './wholesaleCart.reducer';
import { useStorage } from 'helper/useStorage';
import { WholesaleProduct } from 'models/Wholesale';
import arrayUniq from 'array-uniq';
import { WholesalerEnum } from 'entities';
import { WholesalerMenuOption } from 'containers/Wholesale/AllWholesaleProducts';
import { RehydrateWholesaleCartReducerAction } from './wholesaleCart.reducer.entities';

type WholesaleCartContextProviderValue = {
  items: WholesaleProduct[];
  itemsCount: number;
  provider: WholesalerMenuOption;
  addItem: (item: WholesaleProduct, quantity?: number) => void;
  removeItem: (item: WholesaleProduct, quantity?: number) => void;
  removeItemFromCart: (item: WholesaleProduct) => void;
  setSubstitutePreference: (upc: string | number, preference: boolean) => void;
  clearCart: () => void;
  isInCart: (data: WholesaleProduct) => boolean;
  getItem: (upc: string | number) => WholesaleProduct;
  calculateSubtotal: () => number;
  getProductUpcs: () => (string | number)[];
  getItemByMidas: (midas: string) => WholesaleProduct;
  getItemByUuid: (uuid: string) => WholesaleProduct;
  setProvider: (providerId: WholesalerEnum) => void;
};

const WholesaleCartContext = createContext(
  {} as WholesaleCartContextProviderValue
);

export type WholesaleCartProps = {
  provider: WholesalerEnum;
  items: WholesaleProduct[];
};

const INITIAL_STATE: WholesaleCartProps = {
  provider: '',
  items: [],
};

const useCartActions = (initialCart = INITIAL_STATE) => {
  const [state, dispatch] = useReducer(reducer, initialCart);

  const addItemHandler = (item: WholesaleProduct, quantity = 1) => {
    if (state.provider == '' || state.provider == item.wholesalerType) {
      dispatch({ type: 'ADD_ITEM', payload: { ...item, quantity } });
      dispatch({ type: 'SET_PROVIDER', payload: item.wholesalerType });
    }
  };

  const removeItemHandler = (item: WholesaleProduct, quantity = 1) => {
    dispatch({ type: 'REMOVE_ITEM', payload: { ...item, quantity } });
  };

  const clearItemFromCartHandler = (item) => {
    dispatch({ type: 'CLEAR_ITEM_FROM_CART', payload: item });
    dispatch({ type: 'SET_PROVIDER', payload: '' });
  };

  const setSubstitutePreferenceHandler = (
    upc: string | number,
    preference: boolean
  ) => {
    dispatch({
      type: 'SET_SUBSTITUTE_PREFERENCE',
      payload: { upc, preference },
    });
  };

  const clearCartHandler = () => dispatch({ type: 'CLEAR_CART' });

  const rehydrateLocalState = (
    payload: RehydrateWholesaleCartReducerAction['payload']
  ) => {
    dispatch({ type: 'REHYDRATE', payload });
  };

  const isInCartHandler = (data: WholesaleProduct) => {
    return state.items?.some((item) => {
      return item?.uuid === data?.uuid;
    });
  };

  const getItemHandler = (upc: string | number) => {
    return state.items?.find((item) => item.upc === upc);
  };

  const getItemByMidasHandler = (midas: string) => {
    return state.items?.find((item) => item.wholesalerProductCode === midas);
  };

  const getItemByUuidHandler = (uuid: string) => {
    return state.items?.find((item) => item.uuid === uuid);
  };

  const getCartItemsPrice = () => cartItemsTotalPrice(state.items);

  const getItemsCount = state.items?.reduce(
    (acc, item) => acc + item.quantity,
    0
  );

  const getProductsUpcsHandler = (): (string | number)[] => {
    return arrayUniq(state.items.map((x) => x.upc));
  };

  const setProviderHandler = (provider: WholesalerEnum) => {
    dispatch({ type: 'SET_PROVIDER', payload: provider });
  };

  return {
    state,
    getItemsCount,
    rehydrateLocalState,
    addItemHandler,
    removeItemHandler,
    clearItemFromCartHandler,
    setSubstitutePreferenceHandler,
    clearCartHandler,
    isInCartHandler,
    getItemHandler,
    getCartItemsPrice,
    getProductsUpcsHandler,
    getItemByMidasHandler,
    getItemByUuidHandler,
    setProviderHandler,
  };
};

export const WholesaleCartProvider = ({ children }) => {
  const {
    state,
    rehydrateLocalState,
    getItemsCount,
    addItemHandler,
    removeItemHandler,
    clearItemFromCartHandler,
    setSubstitutePreferenceHandler,
    clearCartHandler,
    isInCartHandler,
    getItemHandler,
    getCartItemsPrice,
    getProductsUpcsHandler,
    getItemByMidasHandler,
    getItemByUuidHandler,
    setProviderHandler,
  } = useCartActions();

  const { rehydrated, error } = useStorage(state, rehydrateLocalState);

  return (
    <WholesaleCartContext.Provider
      value={{
        items: state.items,
        itemsCount: getItemsCount,
        provider: getWholesalerById(state.provider),
        addItem: addItemHandler,
        removeItem: removeItemHandler,
        removeItemFromCart: clearItemFromCartHandler,
        setSubstitutePreference: setSubstitutePreferenceHandler,
        clearCart: clearCartHandler,
        isInCart: isInCartHandler,
        getItem: getItemHandler,
        calculateSubtotal: getCartItemsPrice,
        getProductUpcs: getProductsUpcsHandler,
        getItemByMidas: getItemByMidasHandler,
        getItemByUuid: getItemByUuidHandler,
        setProvider: setProviderHandler,
      }}
    >
      {children}
    </WholesaleCartContext.Provider>
  );
};

export const useWholesaleCart = () => useContext(WholesaleCartContext);

export default useWholesaleCart;
