import React, { useEffect, useState } from 'react'
import { addDaysToDate } from 'helper/datetime/dates'
import { DEFAULT_TIME_FRAME, TimeFrame, getTimeFrameDescription, getTimeFrameDates, getPreviousTimeFrameDates } from 'helper/datetime/timeFrame'
import { formatLocalShortDate } from './toLocalDate';

export type TimeFrameProps = {
  timeFrame: TimeFrame;
  setTimeFrame: React.Dispatch<React.SetStateAction<TimeFrame>>;
  fromDate: Date;
  setFromDate: React.Dispatch<React.SetStateAction<Date>>;
  toDate: Date;
  setToDate: React.Dispatch<React.SetStateAction<Date>>;
  previousFromDate: Date;
  setPreviousFromDate: React.Dispatch<React.SetStateAction<Date>>;
  previousToDate: Date;
  setPreviousToDate: React.Dispatch<React.SetStateAction<Date>>;
  setDateRange: (range: Date[]) => void;
  isCustomTimeFrame: boolean;
  getDateRangeDescription: (isPrevious?: boolean, options?: TimeFrameDescriptionProps) => string;
  dateToQuerySecs: (date: Date) => number;
  dateToQueryMs: (date: Date) => number;
}

export type TimeFrameDescriptionProps = {
  relativePrefix?: boolean;
  includeCustom?: boolean;
}

const useTimeFrame = (initial: TimeFrame = DEFAULT_TIME_FRAME): TimeFrameProps => {

  const [timeFrame, setTimeFrame] = useState<TimeFrame>(initial);
  const isCustomTimeFrame = timeFrame === "CUSTOM";

  const [fromDate, setFromDate] = useState<Date>(getTimeFrameDates(timeFrame)[0]);
  const [toDate, setToDate] = useState<Date>(getTimeFrameDates(timeFrame)[1]);

  const [previousFromDate, setPreviousFromDate] = useState<Date>(getPreviousTimeFrameDates(timeFrame)[0]);
  const [previousToDate, setPreviousToDate] = useState<Date>(getPreviousTimeFrameDates(timeFrame)[1]);

  const getDateRangeDescription = (isPrevious: boolean, {
    relativePrefix = false,
    includeCustom = true,
  }: TimeFrameDescriptionProps = {}): string => {
    if (relativePrefix && !isCustomTimeFrame) {
      return getTimeFrameDescription(timeFrame);
    } else if (!isCustomTimeFrame || includeCustom) {
      let from = isPrevious ? previousFromDate : fromDate;
      let to = isPrevious ? previousToDate : toDate;
      let fromDateStr = formatLocalShortDate(from);
      let toDateStr = formatLocalShortDate(to);
      if (fromDateStr == toDateStr) return fromDateStr;
      return `${fromDateStr} to ${toDateStr}`;
    }
    return "";
  }

  const setDateRange = (range: Date[]) => {
    let prev = getPreviousTimeFrameDates(timeFrame, range[0], range[1]);
    setFromDate(range[0]);
    setToDate(range[1]);
    setPreviousFromDate(prev[0]);
    setPreviousToDate(prev[1]);
  }

  const dateToQuerySecs = (date: Date): number => Math.round(date.getTime()/1000);
  const dateToQueryMs = (date: Date): number => date.getTime();

  useEffect(() => {
    let dates = getTimeFrameDates(timeFrame);
    setFromDate(dates[0]);
    setToDate(dates[1]);
    let previous = getPreviousTimeFrameDates(timeFrame);
    setPreviousFromDate(previous[0]);
    setPreviousToDate(previous[1]);
  }, [timeFrame]);

  return {
    timeFrame, setTimeFrame,
    fromDate, setFromDate,
    toDate, setToDate,
    previousFromDate, setPreviousFromDate,
    previousToDate, setPreviousToDate,
    setDateRange,
    isCustomTimeFrame,
    getDateRangeDescription,
    dateToQuerySecs, dateToQueryMs
  };
}

export default useTimeFrame;

export type FromToDateTimesProps = {
  fromDateTime: number;
  toDateTime: number;
}

export const useFromToDateTimes = (fromDate: Date, toDate: Date): FromToDateTimesProps => {

  const [fromDateTime, setFromDateTime] = useState<number>(null);

  useEffect(() => {
    setFromDateTime((fromDate || addDaysToDate(new Date(), -365)).getTime())
  }, [fromDate]);

  const [toDateTime, setToDateTime] = useState<number>(null);

  useEffect(() => {
    setToDateTime((toDate || new Date()).getTime())
  }, [toDate]);

  return { fromDateTime, toDateTime };
}
