import useCategoriesContext from 'contexts/categories'
import CategoryTreeNode from 'models/CategoryTreeNode'
import React, { useEffect, useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import Drawer from './FilterDrawer.style';
import { faCheck, faTimes, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CategoryList from 'components/FilterDrawer/CategoryList/CategoryList';
import { Field } from 'styles/Form';
import Radio from 'components/Radio/Radio';
import FilterProps, { copyFilter } from 'models/FilterProps';
import Check from 'components/Check/Check';
import { AvailabilityEnum } from 'models/Product';
import { availabilityDescription, AVAILABILITY_ENUM_ALL_CASES, formatPrice, formatPriceInput, formatSizeInput, quantityMeasureDescription } from 'helper/product';
import { ProductsViewMode } from 'containers/Products/Products';
import Mixpanel from 'helper/Mixpanel';
import Loader from 'components/Loader/Loader';
import { Hover } from 'containers/UntitledProducts/UntitledProducts.style';
import { Button } from 'styles/Form'
import { useWindowSizeDeviceType } from 'helper/useWindowSize';

type FilterDrawerProps = {
  isOpen: boolean;
  closeModal: any;
  filter: FilterProps;
  setFilter: (FilterProps) => void;
  mode: ProductsViewMode;
};

const FilterDrawer: React.FC<FilterDrawerProps> = ({ 
  isOpen, 
  closeModal,
  filter: _filter,
  setFilter: _setFilter,
  mode,
}) => {

  const [hasMadeChanges, setHasMadeChanges] = useState(false);

  const [filter, setFilter] = useState<FilterProps>(copyFilter(_filter));

  const { smallTablet: mobile } = useWindowSizeDeviceType();

  useEffect(() => {
    setFilter(copyFilter(_filter));
    setHasMadeChanges(false);
  }, [_filter]);

  const [_renderIndex, _setRenderIndex] = useState(0);
  const triggerRender = () => {
    _setRenderIndex(_renderIndex+1);
    setFromPriceText(formatPrice(filter.fromShopPrice, ""));
    setToPriceText(formatPrice(filter.toShopPrice, ""));
  };

  useEffect(() => {
    if (!isOpen) return;
    triggerRender();
    setTimeout(() => {
      fromPriceFieldRef.current?.blur();
      setHasMadeChanges(false);
    }, 10);
  }, [isOpen]);

  // --------------------------------------------
  // Pricing
  // --------------------------------------------

  const fromPriceFieldRef = useRef(null);
  const [fromPriceText, setFromPriceText] = useState("");
  const [toPriceText, setToPriceText] = useState("");

  const handleFromPriceBlur = (e: any) => {
    const price = formatPriceInput(e.target.value, null);
    filter.fromShopPrice = price;
    setFromPriceText(formatPrice(price, ""));
    setHasMadeChanges(true);
    if (price) Mixpanel.track('products_filter_set_from_price', { from_price: price });
  };

  const handleToPriceBlur = (e: any) => {
    const price = formatPriceInput(e.target.value, null);
    filter.toShopPrice = price;
    setToPriceText(formatPrice(price, ""));
    setHasMadeChanges(true);
    if (price) Mixpanel.track('products_filter_set_to_price', { to_price: price });
  };

  const setExcludePriceMark = (exclude: boolean) => {
    filter.isPriceMarked = exclude;
    triggerRender();
    setHasMadeChanges(true);
    if (exclude) Mixpanel.track('products_filter_toggle_price_mark', { to: exclude });
  };

  const handlePriceClear = (e: any) => {
    filter.fromShopPrice = null;
    filter.toShopPrice = null;
    setFromPriceText("");
    setToPriceText("");
    setExcludePriceMark(null);
    setHasMadeChanges(true);
    Mixpanel.track('products_filter_clear_price');
  };
  const shouldShowPriceClear = filter.fromShopPrice || filter.toShopPrice || filter.isPriceMarked != null;

  // --------------------------------------------
  // Sizes
  // --------------------------------------------

  const [fromSizeText, setFromSizeText] = useState("");
  const [toSizeText, setToSizeText] = useState("");

  const formatSizeText = (size: number): string => {
    if (!size) return "";
    return `${size}${quantityMeasureDescription(filter.quantityMeasure)}`;
  };

  const onSizeSelect = (e: any) => {
    const measure = e?.target?.id
    filter.quantityMeasure = measure;
    setFromSizeText(formatSizeText(filter.fromQuantity));
    setToSizeText(formatSizeText(filter.toQuantity));
    triggerRender();
    setHasMadeChanges(true);
    if (measure) Mixpanel.track('products_filter_quantity_measure_select', { quantity_measure: measure });
  };

  const handleFromSizeBlur = (e: any) => {
    const size = formatSizeInput(e.target.value, null);
    filter.fromQuantity = size;
    setFromSizeText(formatSizeText(size));
    setHasMadeChanges(true);
    if (size) Mixpanel.track('products_filter_set_from_size', { from_size: size });
  };

  const handleToSizeBlur = (e: any) => {
    const size = formatSizeInput(e.target.value, null);
    filter.toQuantity = size;
    setToSizeText(formatSizeText(size));
    setHasMadeChanges(true);
    if (size) Mixpanel.track('products_filter_set_to_size', { to_size: size });
  };

  const handleSizeClear = (e: any) => {
    filter.quantityMeasure = null;
    filter.fromQuantity = null;
    filter.toQuantity = null;
    setFromSizeText("");
    setToSizeText("");
    triggerRender();
    setHasMadeChanges(true);
    Mixpanel.track('products_filter_clear_size');
  };
  const shouldShowSizeClear = filter.fromQuantity || filter.toQuantity || filter.quantityMeasure;

  // --------------------------------------------
  // Categories
  // --------------------------------------------

  const { rootCategory, loading: categoryLoading } = useCategoriesContext();

  const [expandedCategories, setExpandedCategories] = useState([]);
  const allCategoriesSelected = filter.categoryIds.length === 1 && filter.categoryIds[0] === 1;

  const onToggleCategoryExpand = (c: CategoryTreeNode) => {
    setExpandedCategories(_prev => {
      const prev = _prev.slice();
      if (prev.includes(c.data.id)) {
        c.getAllDescendants().concat(c).forEach(x => {
          const i = prev.indexOf(x.data.id);
          if (i > -1) prev.splice(prev.indexOf(i), 1);
        });
      } else if (c.children.length > 0) {
        prev.push(c.data.id);
      }
      return prev;
    });
  };

  const onCategorySelect = (c: CategoryTreeNode) => {
    if (c.data.id === 1) {
      Mixpanel.track('products_filter_clear_category');
      filter.categoryIds = [1];
      filter.includeSubcats = true;
      setHasMadeChanges(true);
      return triggerRender();
    }

    const prev = filter.categoryIds.slice();
    const ancestors = c.getAllAncestors();
    const descendants = c.getAllDescendants();
    const shouldRemove = prev.includes(c.data.id);

    const rootI = prev.indexOf(1);
    if (rootI > -1) prev.splice(rootI, 1);

    ancestors.forEach(x => {
      const i = prev.indexOf(x.data.id);
      if (i > -1) prev.splice(i, 1);
    });

    descendants.forEach(x => {
      const i = prev.indexOf(x.data.id);
      if (i > -1) prev.splice(i, 1);
    });

    if (!shouldRemove) {
      prev.push(c.data.id);
    }

    filter.categoryIds = prev.length > 0 ? prev : [1];
    filter.includeSubcats = true;
    triggerRender();
    setHasMadeChanges(true);  
    Mixpanel.track('products_filter_toggle_category', { id: c.data.id, name: c.data.name, to: !shouldRemove });
  };

  // --------------------------------------------
  // Availability
  // --------------------------------------------

  const onAvailabilitySelect = (option: AvailabilityEnum) => {
    const index = filter.availabilityList.indexOf(option);
    const shouldRemove = index > -1;
    if (shouldRemove) {
      filter.availabilityList.splice(index, 1);
    } else {
      filter.availabilityList.push(option);
    }
    triggerRender();
    setHasMadeChanges(true);
    Mixpanel.track('products_filter_toggle_availability', { availability: option, to: !shouldRemove });
  };

  const handleAvailabilityReset = (e: any) => {
    filter.availabilityList = ['AVAILABLE', 'NOT_AVAILABLE', 'REMOVED'];
    triggerRender();
    setHasMadeChanges(true);
    Mixpanel.track('products_filter_clear_availability');
  }

  const shouldShowAvailabilityReset = filter.availabilityList.length < 3;

  // --------------------------------------------
  // Helper
  // --------------------------------------------

  const handleFieldKeyDown = (e: any) => {
    if (e.key === 'Enter') e.target.blur();
  };

  const dismiss = () => {
    onReset();
    closeModal();
    setExpandedCategories([]);
  };

  const onApply = e => {
    _setFilter(filter);
    dismiss();
  };

  const onReset = () => {
    setHasMadeChanges(false);
    setFilter(_filter);
  };

  // --------------------------------------------
  // Render
  // --------------------------------------------

  type PriceMarkItem = { name: string; value: null | true | false; };
  const PRICE_MARK_ITEMS: PriceMarkItem[] = [
    { name: 'All items', value: null },
    { name: 'Only price marked', value: true },
    { name: 'Exclude price marked', value: false },
  ];

  return (
    <Popup 
      open={isOpen === true}
      onClose={dismiss} 
      closeOnDocumentClick 
      modal 
      lockScroll
      position='right center'
      className="category-popup">
      <Drawer.Wrapper>
        <Drawer.Header key={0}>
          <h2>Filter</h2>
          <a onClick={dismiss}>
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </a>
        </Drawer.Header>

        <Drawer.Content key={1}>
          <Drawer.Section key={0}>
            <h4>Shop Price</h4>
            {shouldShowPriceClear &&
              <button onClick={handlePriceClear}>Clear</button>
            }
          </Drawer.Section>

          <div className="fromToFields">
            <Field
              type="text"
              placeholder="From"
              value={fromPriceText}
              onChange={e => setFromPriceText(e.target.value)}
              onBlur={handleFromPriceBlur}
              onKeyDown={handleFieldKeyDown}
              ref={fromPriceFieldRef}
            />
            <Field
              type="text"
              placeholder="To"
              value={toPriceText}
              onChange={e => setToPriceText(e.target.value)}
              onBlur={handleToPriceBlur}
              onKeyDown={handleFieldKeyDown}
            />
          </div>

          {PRICE_MARK_ITEMS.map((option, index) => (
            <Drawer.Radio key={index*10}>
              <Radio isSelected={filter.isPriceMarked === option.value} onClick={() => setExcludePriceMark(option.value)} />
              <a onClick={() => setExcludePriceMark(option.value)}>{option.name}</a>
            </Drawer.Radio>
          ))}

          {mode === 'LIVE' &&
            <>
              <Drawer.Section key={1}>
                <h4>Availability</h4>
                {shouldShowAvailabilityReset &&
                  <button onClick={handleAvailabilityReset}>Reset</button>
                }
              </Drawer.Section>
              
              {AVAILABILITY_ENUM_ALL_CASES.map((option, index) => (
                <Drawer.Radio key={index*100}>
                  <Check isSelected={filter.availabilityList.includes(option)} onClick={() => onAvailabilitySelect(option)} />
                  <a onClick={() => onAvailabilitySelect(option)}>{availabilityDescription(option)}</a>
                </Drawer.Radio>
              ))}
            </>
          }

          <Drawer.Section key={2}>
            <h4>Size</h4>
            {shouldShowSizeClear &&
              <button onClick={handleSizeClear}>Clear</button>
            }
          </Drawer.Section>
          <Drawer.Sizes selected={filter.quantityMeasure}>
            <Drawer.SizeButton id="MILLIGRAMS" onClick={onSizeSelect}>mg</Drawer.SizeButton>
            <Drawer.SizeButton id="GRAMS" onClick={onSizeSelect}>g</Drawer.SizeButton>
            <Drawer.SizeButton id="KILOGRAMS" onClick={onSizeSelect}>kg</Drawer.SizeButton>
            <Drawer.SizeButton id="CENTILITRES" onClick={onSizeSelect}>cl</Drawer.SizeButton>
            <Drawer.SizeButton id="MILLILITRES" onClick={onSizeSelect}>ml</Drawer.SizeButton>
            <Drawer.SizeButton id="LITRES" onClick={onSizeSelect}>l</Drawer.SizeButton>
          </Drawer.Sizes>
          
          {filter.quantityMeasure &&
            <div className="fromToFields">
              <Field
                type="text"
                placeholder="From"
                value={fromSizeText}
                onChange={e => setFromSizeText(e.target.value)}
                onBlur={handleFromSizeBlur}
                onKeyDown={handleFieldKeyDown}
              />
              <Field
                type="text"
                placeholder="To"
                value={toSizeText}
                onChange={e => setToSizeText(e.target.value)}
                onBlur={handleToSizeBlur}
                onKeyDown={handleFieldKeyDown}
              />
            </div>
          }

          {categoryLoading && 
            <>
              <Drawer.Section key={3}>
                <h4>Categories</h4>
              </Drawer.Section>
              <Loader />
            </>
          }

          {rootCategory &&
            <>
              <Drawer.Section key={3}>
                <h4>Categories</h4>
                {!allCategoriesSelected &&
                  <button onClick={() => onCategorySelect(rootCategory)}>Clear</button>
                }
              </Drawer.Section>
              <CategoryList
                node={rootCategory}
                expandedCategories={expandedCategories}
                onToggleCategoryExpand={onToggleCategoryExpand}
                selectedCategories={filter.categoryIds}
                onCategorySelect={onCategorySelect}
              />
            </>
          }

          {!mobile ? ( hasMadeChanges && 
            <Hover style={{ marginLeft: -30 }}>
              <Button className="green" onClick={onApply}>
                <FontAwesomeIcon icon={faCheck} />
                &nbsp; Apply
              </Button>
              <Button className="red" onClick={onReset}>
                <FontAwesomeIcon icon={faUndo} />
                &nbsp; Reset
              </Button>
            </Hover>
          ) : ( hasMadeChanges && 
            <Hover
              style={{
                margin: '0 -30px',
                position: 'static',
                boxShadow: 'none',
              }}
            >
              <Button className="green" onClick={onApply}>
                <FontAwesomeIcon icon={faCheck} />
                &nbsp; Apply
              </Button>
              <Button className="red" onClick={onReset}>
                <FontAwesomeIcon icon={faUndo} />
                &nbsp; Reset
              </Button>
            </Hover>
          )}
        </Drawer.Content>
      </Drawer.Wrapper>
    </Popup>
  );
};

export default FilterDrawer;